import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { getPageForAmplitudeEvent } from "../utils";


export const useAmpliPage = () => {
    const location = useLocation();

    return useMemo(() => {
        const pathname = location.pathname;
        return getPageForAmplitudeEvent(pathname);
    }, [location.pathname]);
};
