import { Fragment } from "react";
import { TooltipSlotProps } from "@7pace/design";
import { stringUtils } from "@7pace/utilities";
import { Tooltip } from "monday-ui-react-core";

import { mapTooltipSlotDelay, mapTooltipSlotDirectionalHint } from "./utils";


export const MondayTooltipSlot: React.FC<TooltipSlotProps> = ({
    id,
    className,
    content,
    children,
    hidden,
    delay,
    hideBeak,
    closeDelay,
    directionalHint,
    openByDefault,
    offset,
    "data-testid": dataTestId
}) => {
    const tooltipProps: React.ComponentProps<typeof Tooltip> = {
        id,
        className,
        referenceWrapperClassName: stringUtils.constructClasses(className, "mdy-tooltip"),
        content: <Fragment>{content}</Fragment>,
        showDelay: mapTooltipSlotDelay(delay),
        hideDelay: mapTooltipSlotDelay(closeDelay),
        tip: hideBeak,
        position: mapTooltipSlotDirectionalHint(directionalHint),
        hideTrigger: [Tooltip.hideShowTriggers.MOUSE_LEAVE, Tooltip.hideShowTriggers.CLICK_OUTSIDE],
        shouldShowOnMount: openByDefault,
        moveBy: {
            main: offset || 0
        },
        "data-testid": dataTestId,
        children: <Fragment>{children}</Fragment>
    };

    // As long as hidden is true, the value of shouldShowOnMount should be ignored.
    // This behavior is the same as that of the default Tooltip slot component.
    // In order to achieve this with the Monday Vibe Tooltip component, we need to
    // use two separate Tooltip components, as there is no other way to ignore shouldShowOnMount,
    // without having it be ignored, once it is set to true.
    // Setting a key is also required, as React will otherwise try to optimize the return value, 
    // as it thinks that the two Tooltip components are the same.
    return hidden ? (
        <Tooltip
            key="hidden-tooltip"
            {...tooltipProps}
            showTrigger={[]}
            shouldShowOnMount={false}
        />
    ) : (
        <Tooltip
            key="tooltip"
            {...tooltipProps}
        />
    );
};
