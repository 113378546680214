import { FC } from "react";
import { MenuButtonSlotProps, SlotSize, useSlot } from "@7pace/design";
import { Menu, MenuButton, MenuItem } from "monday-ui-react-core";
import styled from "styled-components";

import { mondayIcons } from "../MondayIconSlot/mondayIcons";


export const MondayMenuButtonSlot: FC<MenuButtonSlotProps> = ({
    options,
    selected,
    placeholder,
    itemClassName,
    calloutClassName,
    onChange,
    ...rest
}) => {
    const IconSlot = useSlot("icon");

    return (
        <MenuButtonWrapper
            text={selected?.text || placeholder}
            component={() => <IconWrapper><IconSlot size={SlotSize.Medium} type={selected?.icon} /></IconWrapper>}
            componentPosition={MenuButton.componentPositions.START}
            closeDialogOnContentClick
            dialogClassName={calloutClassName}
            {...rest}
        >
            <Menu size={Menu.sizes.SMALL}>
                {options.map((option) =>
                    <MenuItem
                        key={option.value}
                        title={option.text}
                        icon={option.icon ? mondayIcons[option.icon] : null}
                        className={itemClassName}
                        onClick={() => onChange?.(option)}
                    />
                )}
            </Menu>
        </MenuButtonWrapper>
    );
};

const MenuButtonWrapper = styled(MenuButton)`
    width: auto;
    padding: ${p => p.theme.spacing.xs} ${p => p.theme.spacing.s};
    border: 1px solid ${p => p.theme.color.border.layout.subtle};
    border-radius: ${p => p.theme.radii.s};
`;

const IconWrapper = styled.span`
    display: flex;
`;
