import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WorkLogsKeyProps } from "./types/WorkLogsKeyProps";
import { RootState } from "../store";


type KeyPropsState = {
    workLogsKeyProps: WorkLogsKeyProps;
};

const initialState = {
    workLogsKeyProps: {}
} as KeyPropsState;


export const keyPropsSlice = createSlice({
    name: "keyProps",
    initialState,
    reducers: {
        setWorkLogsKeyProps: (state, action: PayloadAction<WorkLogsKeyProps>) => {
            state.workLogsKeyProps = action.payload;
        }
    }
});

export const {
    setWorkLogsKeyProps
} = keyPropsSlice.actions;


export const getWorkLogsKeyProps = (store: RootState): WorkLogsKeyProps => store.keyProps.workLogsKeyProps;

export default keyPropsSlice.reducer;
