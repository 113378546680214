import { FC } from "react";
import { TextWithOverflow } from "@7pace/components";
import { TextInputSlotProps } from "@7pace/design";
import { Icon, TextField, Tooltip } from "monday-ui-react-core";
import CloseSmall from "monday-ui-react-core/dist/icons/CloseSmall";
import Info from "monday-ui-react-core/dist/icons/Info";
import styled from "styled-components";

import { TextWithLineBreaks } from "./TextWithLineBreaks";
import { mapTextInputSlotSize } from "./utils";


export const MondayTextInputSlot: FC<TextInputSlotProps> = ({
    errorProps,
    value,
    label,
    slotRef,
    size,
    className,
    clearable,
    tooltip,
    onChange,
    onClear,
    ...commonProps
}) => {
    return (
        <TextInputWrapper>
            {
                label && <TitleWrapper>
                    <Title tooltipContent={label}>{label}</Title>
                    {tooltip && (
                        <Tooltip content={<TextWithLineBreaks tooltip={tooltip} />}>
                            <IconWrapper icon={Info} iconSize={16} clickable={false} />
                        </Tooltip>
                    )}
                </TitleWrapper>
            }
            <TextFieldWrapper
                ref={slotRef}
                value={value}
                onChange={onChange}
                size={mapTextInputSlotSize(size)}
                validation={errorProps?.error && {
                    status: "error",
                    text: errorProps.message
                }}
                wrapperClassName={className}
                iconName={Boolean(value) && clearable ? CloseSmall : undefined}
                onIconClick={Boolean(value) && clearable ? onClear : undefined}
                {...commonProps}
            />
        </TextInputWrapper>

    );
};

const TextInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing.xs};
`;

const TextFieldWrapper = styled(TextField)`
    &&& {
        background-color: ${p => p.theme.color.background.surface.primary};
    }
`;

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${p => p.theme.spacing.xs};

    .mdy-tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        
        > span {
            width: unset;
        }
    }
`;

const Title = styled(TextWithOverflow)`
    color: ${p => p.theme.color.foreground.text.primary};
    font: ${p => p.theme.typography.body.regular.m};
`;

const IconWrapper = styled(Icon)`
    display: flex;
    color: ${p => p.theme.color.foreground.text.secondary};
    cursor: pointer;
`;
