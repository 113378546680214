const noValuePattern = /^%%\w+%%$/;


type Env =
    "ENV_NAME" |
    "APP_VERSION" |
    "AMPLITUDE_API_KEY" |
    "AMPLITUDE_API_ENDPOINT" |
    "FINGERPRINT_API_KEY" |
    "WHATS_NEW_API_BASE_URL" |
    "WHATS_NEW_API_PRODUCT_ID" |
    "MY_TIME_WORKSPACE_FEATURE_ID" |
    "ALL_TIMES_WORKSPACE_FEATURE_ID" |
    "LD_CLIENT_ID" |
    "LOGROCKET_APP_ID";

export const getEnvValue = (name: Env) => {
    const value = (document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement)?.content;

    if (!value || noValuePattern.test(value)) {
        console.error(`No env value found for ${name}`);
        return "";
    }

    return value;
};
