import { FC } from "react";
import styled from "styled-components";

import { NO_RESULTS_LABEL } from "../../../../utils/constants";
import { VirtualizedOptionProps } from "../../types";


export const VirtualizedPolyNoResultsOption: FC<VirtualizedOptionProps> = ({ virtualizedStyle }) => {
    return (
        <NoResultsOptionWrapper style={virtualizedStyle}>
            <NoResultsLabel>{NO_RESULTS_LABEL}</NoResultsLabel>
        </NoResultsOptionWrapper>
    );
};

const NoResultsOptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoResultsLabel = styled.span`
    color: ${p => p.theme.color.foreground.text.secondary};
    font: ${p => p.theme.typography.body.bolder.s};
`;
