import { ImageSlotType } from "@7pace/design";


export const mondayImages: Partial<Record<ImageSlotType, string>> = {
    [ImageSlotType.NoResults]: "/img/no-results.svg",
    [ImageSlotType.TimesheetEmpty]: "/img/timesheet-empty.svg",
    [ImageSlotType.EmptyDay]: "/img/no-worklogs.svg",
    [ImageSlotType.NewsEmpty]: "/img/app-uninstalled.svg",
    // [ImageSlotType.TimetrackerLogo]: "", - the same as in default
};
