import { FC } from "react";
import styled from "styled-components";

import { VirtualizedOptionProps } from "../../types";
import { VirtualizedListLoader } from "../VirtualizedListLoader";


type LoaderOptionProps = {
    text: string;
};

export const VirtualizedPolyLoaderOption: FC<LoaderOptionProps & VirtualizedOptionProps> = ({ virtualizedStyle, text }) => {
    return (
        <LoaderOptionWrapper style={virtualizedStyle}>
            <LoaderOptionText>{text}</LoaderOptionText>
            <VirtualizedListLoader />
        </LoaderOptionWrapper>
    );
};

const LoaderOptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${p => p.theme.spacing.s} calc(${p => p.theme.spacing.m} + ${p => p.theme.spacing.s});
`;

const LoaderOptionText = styled.span`
    color: ${p => p.theme.color.foreground.text.secondary};
    font: ${p => p.theme.typography.body.bolder.s};
`;
