import { FC, forwardRef, useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, VariableSizeList as List } from "react-window";
import styled, { useTheme } from "styled-components";

import { GetNextIdFunc, OnEnterSubmitFunc, useVirtualizedOptionHighlight } from "./hooks/useVirtualizedOptionHighlight";
import { useVirtualizedScrollToSelectedIndex } from "./hooks/useVirtualizedScrollToSelectedIndex";
import { BaseVirtualizedPolyOption } from "./types";


type VirtualizedListProps = {
    polyOptions: BaseVirtualizedPolyOption[];
    menuHeight: number;
    selectedIndex?: number;
    virtualizedItem: FC<ListChildComponentProps<unknown>>;
    "data-testId"?: string;
    getNextHighlightedId: GetNextIdFunc;
    onEnterSubmit?: OnEnterSubmitFunc;
};

export const VirtualizedList: FC<VirtualizedListProps> = ({
    polyOptions,
    selectedIndex,
    virtualizedItem,
    menuHeight,
    "data-testId": dataTestId,
    getNextHighlightedId,
    onEnterSubmit
}) => {
    const { listRef, onSetListRef } = useVirtualizedScrollToSelectedIndex(selectedIndex);

    const [inner, setInner] = useState<HTMLDivElement>();

    useVirtualizedOptionHighlight(inner, listRef.current, getNextHighlightedId, onEnterSubmit);

    // AZ: push VariableSizeList to call itemSize callback to recalculate items height whenever options changed
    useEffect(() => {
        if (!polyOptions) {
            return;
        }

        listRef.current?.resetAfterIndex(0);
    }, [listRef, polyOptions]);

    return (
        <DropdownMenu menuHeight={menuHeight} data-testid={dataTestId}>
            {polyOptions?.length > 0 && (
                // AZ: we don't want to rely on AutoSizer's height because it updates with delay (~10ms) 
                // which leads to scrollbar blinking
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <List
                            height={menuHeight}
                            width={width}
                            itemCount={polyOptions.length}
                            itemData={polyOptions}
                            itemSize={(index) => polyOptions[index].height}
                            innerElementType={InnerElementType}
                            ref={onSetListRef}
                            innerRef={setInner}
                        >
                            {virtualizedItem}
                        </List>
                    )}
                </AutoSizer>
            )}
        </DropdownMenu >
    );
};

// AZ: add vertical paddings to list's height
const InnerElementType = forwardRef<HTMLDivElement, ListChildComponentProps>(({ style, ...rest }, ref) => {
    const theme = useTheme();

    return (
        <div
            ref={ref}
            style={{
                ...style,
                height: `calc(${style.height}px + ${theme.spacing.s} * 2)`
            }}
            {...rest}
        />
    );
});

const DropdownMenu = styled.div<{ menuHeight: number; }>`
    height: ${p => p.menuHeight}px;
    overflow: hidden;
`;
