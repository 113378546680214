import styled from "styled-components";

import { DATA_LIST_OPTION_HOVERED, DATA_LIST_OPTION_SELECTED } from "./hooks/useVirtualizedOptionHighlight";


export const VirtualizedOptionWrapper = styled.div`
    // AZ: we're doing it this way to stick list's scrollbar to the right side
    width: calc(100% - 2 * ${p => p.theme.spacing.s}) !important;
    height: 100%;
    padding: 0 ${p => p.theme.spacing.s};
    margin: 0 ${p => p.theme.spacing.s};
    border-radius: ${p => p.theme.radii.s};
    cursor: pointer;

    &[${DATA_LIST_OPTION_SELECTED}] {
        background-color: ${p => p.theme.color.background.interaction.active};
        border-color: ${p => p.theme.color.border.layout.subtle}; 
    }

    &:not(&[${DATA_LIST_OPTION_SELECTED}]) {
        &[${DATA_LIST_OPTION_HOVERED}] {
            background-color: ${p => p.theme.color.background.interaction.hover};
            border-color: ${p => p.theme.color.border.layout.subtle};
        }
    }
`;
