import { FC } from "react";
import { Loader } from "monday-ui-react-core";
import styled from "styled-components";


export const VirtualizedListLoader: FC = () => {
    return (
        <LoaderWrapper>
            <Loader size={16} color={Loader.colors.SECONDARY} />
        </LoaderWrapper>
    );
};

const LoaderWrapper = styled.div`
    width: auto;
    height: auto;
    margin-right: 2px;
`;
