import { FC } from "react";

import { VirtualizedPolyOptionProps } from "../../../components/VirtualizedList/hooks/useVirtualizedPolyOptions";
import { VirtualizedOptionWrapper } from "../../../components/VirtualizedList/styles";
import { VirtualizedOptionProps } from "../../../components/VirtualizedList/types";
import { UserDropdownOption } from "../types";
import { UserOptionValue } from "./UserOptionValue";


export type PolyUserOption = VirtualizedPolyOptionProps<UserDropdownOption>;

export const UserOption: FC<PolyUserOption & VirtualizedOptionProps> = ({ virtualizedStyle, index, ...props }) => {
    const { value, label, selected, onClick, ...rest } = props.option;

    const onClickInternal = () => {
        onClick?.(props);
    };

    return (
        <VirtualizedOptionWrapper
            data-list-option={index}
            data-list-option-selected={selected || undefined}
            style={virtualizedStyle}
            onClick={onClickInternal}
        >
            <UserOptionValue id={`${value}`} name={label} {...rest} />
        </VirtualizedOptionWrapper>
    );
};
