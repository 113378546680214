import { useLayoutEffect, useState } from "react";

import { useVirtualizedListBase, UseVirtualizedListBaseProps } from "../../VirtualizedList/hooks/useVirtualizedListBase";
import { ADVANCED_DROPDOWN_MENU_CLASS } from "../styles";
import { AdvancedDropdownMenuProps, MenuPosition } from "../types";


// AZ: same as in react-select
const MIN_HEIGHT_TO_SWAP_PLACEMENT = 140;

type UseAdvancedDropdownMenuBaseProps = UseVirtualizedListBaseProps & Pick<AdvancedDropdownMenuProps, "dropdownId" | "menuPosition">;

export const useAdvancedDropdownMenuBase = ({ dropdownId, menuPosition, ...rest }: UseAdvancedDropdownMenuBaseProps) => {
    const virtualizedProps = useVirtualizedListBase(rest);

    // AZ: restrict menu heigh if menu fixed positioned 
    // and its calculated height will overflow the screen
    const [restrictedMenuHeight, setRestrictedMenuHeight] = useState(virtualizedProps.menuHeight);

    useLayoutEffect(() => {
        setRestrictedMenuHeight(virtualizedProps.menuHeight);

        // AZ: this case will be handled later (probably in part 2)
        if (menuPosition === MenuPosition.Absolute) {
            return;
        }

        // AZ: original react-select implementation of it 
        // https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/Menu.tsx#L102
        const viewHeight = window.innerHeight;
        const menuElem = document.querySelector(`.${ADVANCED_DROPDOWN_MENU_CLASS}`);
        const menuRoot = menuElem?.parentElement;

        const dropdownElem = document.getElementById(dropdownId);
        const { top: dropdownTop, bottom: dropdownBottom } = dropdownElem.getBoundingClientRect();

        const menuMarginBottom = parseInt(getComputedStyle(menuElem).marginBottom, 10);

        const spaceBelow = viewHeight - dropdownBottom - menuMarginBottom;
        const spaceAbove = dropdownTop - menuMarginBottom;

        // AZ: menu is fit in the bottom
        if (spaceBelow > virtualizedProps.menuHeight) {
            return;
        }

        // AZ: menu will fit in the bottom if we limit it 
        if (spaceBelow > MIN_HEIGHT_TO_SWAP_PLACEMENT) {
            const constrainedHeight = spaceBelow - menuMarginBottom;
            setRestrictedMenuHeight(Math.max(constrainedHeight, 0));
            return;
        }

        // AZ: no space for menu in the bottom, flip it to the top
        let constrainedHeight = virtualizedProps.menuHeight;

        // limit menu height again if needed
        if (spaceAbove < virtualizedProps.menuHeight) {
            constrainedHeight = spaceAbove - menuMarginBottom;
            setRestrictedMenuHeight(Math.max(constrainedHeight, 0));
        }

        menuRoot.style.top = `${dropdownTop - menuMarginBottom * 2 - constrainedHeight}px`;
    }, [dropdownId, menuPosition, virtualizedProps.menuHeight]);

    return {
        ...virtualizedProps,
        menuHeight: restrictedMenuHeight
    };
};
