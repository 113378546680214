import { QueryFunctionContext } from "@tanstack/react-query";

import useAuthToken from "../../hooks/useAuthToken";
import { useStorageQuery } from "../../hooks/useStorageQuery";
import { clientConfigMock } from "./clientConfigMock";
import { ClientConfig } from "./types";


const getAccountConfig = async ({ queryKey }: QueryFunctionContext) => {
    const token = queryKey[1] as string;
    if (!token) {
        return clientConfigMock.account;
    }

    const accountConfigResponse = await fetch("/configuration/account", { headers: { Authorization: token } });
    const accountConfig = await accountConfigResponse.json() as ClientConfig["account"];
    return accountConfigResponse.status === 200 ? accountConfig : clientConfigMock.account;
};

export const useAccountConfig = () => {
    const { token } = useAuthToken();

    const { data: clientAccountConfig } = useStorageQuery({
        queryKey: ["client_account_config", token],
        queryFn: getAccountConfig,
        enabled: !!token
    });

    return clientAccountConfig ?? clientConfigMock.account;
};
