import { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useFeatureFlags } from "../../../common/hooks/useFeatureFlags";
import RoutePath from "../../../routing/RoutePath";
import { PageTabProps } from "../common/PageTab";
import { ObjectView } from "../ObjectView";


const Settings: FC = () => {
    const { mondayColumnsConnectorEnabled, rolesAndPermissionsEnabled } = useFeatureFlags();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.endsWith(RoutePath.Settings)) {
            mondayColumnsConnectorEnabled ? navigate(RoutePath.ColumnsConnectorSettings) : navigate(RoutePath.FieldsSettings);
        }
    }, [mondayColumnsConnectorEnabled, navigate]);

    const pages: PageTabProps[] = useMemo(() => {
        const resultingPages: PageTabProps[] = [];

        if (mondayColumnsConnectorEnabled) {
            resultingPages.push({
                path: RoutePath.ColumnsConnectorSettings,
                name: "Columns Connector",
                "data-testid": "settings-columns-connector-tab",
                beta: true
            });
        }

        resultingPages.push({
            path: RoutePath.FieldsSettings,
            name: "Fields",
            "data-testid": "settings-fields-tab"
        });

        if (rolesAndPermissionsEnabled) {
            resultingPages.push({
                path: RoutePath.RolesAndPermissionsSettings,
                name: "Roles & Permissions",
                "data-testid": "settings-roles-and-permissions-tab"
            });

            resultingPages.push({
                path: RoutePath.UsersSettings,
                name: "Users",
                "data-testid": "settings-users-tab"
            });
        }

        return resultingPages;
    }, [mondayColumnsConnectorEnabled, rolesAndPermissionsEnabled]);

    return <ObjectView
        pages={pages}
        showWhatsNew={false}
        showTabsLine={false}
    />;
};

export default Settings;
