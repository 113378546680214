import { MutableRefObject, useCallback } from "react";
import { LayoutCreated, LayoutUpdated } from "@7pace/amplitude";
import { AdvancedFilter, TimesExplorerLayout } from "@7pace/times-explorer";

import { useAmpliLogRocket } from "../../../common/components/Amplitude/hooks/useAmpliLogRocket";


enum TypeOfLayoutColumn {
    "Item Name" = "Item name",
    "Item ID" = "Item ID",
    Board = "Board",
    Group = "Group",
    Workspace = "Workspace",
    "Parent Item" = "Parent Item",
    "Date Logged" = "Date logged",
    Month = "Month",
    Week = "Week",
    Person = "Person",
    "Custom Field" = "Custom field",
    "Connected Column" = "Connected column",
    Start = "Start",
    End = "End",
    Other = "Other"
}

enum TypeOfLayoutFilter {
    "Item Name" = "Item name",
    "Item ID" = "Item ID",
    Board = "Board",
    Group = "Group",
    Workspace = "Workspace",
    "Parent Item" = "Parent Item",
    "Date Logged" = "Date logged",
    Month = "Month",
    Week = "Week",
    Person = "Person",
    Start = "Start",
    End = "End",
    Comment = "Comment",
    "Logged Time" = "Logged Time",
    "Custom Field" = "Custom field",
    "Connected Column" = "Connected column",
    Other = "Other"
}

enum TypeOfLayoutGroup {
    "Item Name" = "Item name",
    "Item ID" = "Item ID",
    Board = "Board",
    Group = "Group",
    Workspace = "Workspace",
    "Parent Item" = "Parent Item",
    "Date Logged" = "Date logged",
    Month = "Month",
    Week = "Week",
    Person = "Person",
    "Custom Field" = "Custom field",
    "Connected Column" = "Connected column",
    Other = "Other"
}

enum PredefinedTimeFilterType {
    Today = "Today",
    Yesterday = "Yesterday",
    ThisWeek = "This week",
    LastWeek = "Last week",
    LastTwoWeeks = "Last 2 weeks",
    ThisMonth = "This month",
    LastMonth = "Last month",
    Other = "Other"
}

type LayoutUpsertEventProps = LayoutCreated["event_properties"] & LayoutUpdated["event_properties"];

export const useAmpliLayoutEvents = (currentLayoutSettingsRef: MutableRefObject<TimesExplorerLayout>) => {
    const ampliLogRocket = useAmpliLogRocket();

    const getUpsertLayoutAmpliEventProps = useCallback(<T extends LayoutUpsertEventProps>(): T => {
        const getEnumValue = <T>(value: string, enumType: T, fallback: T[keyof T]): T[keyof T] => {
            return enumType[value as keyof T] || fallback;
        };

        const getFilterValues = (filter: AdvancedFilter["items"][number]): string[] => {
            if (filter.itemType === "Group") {
                return filter.items.map(getFilterValues).flat();
            }

            return [filter.column.value];
        };

        const typeOfFilters = currentLayoutSettingsRef.current.filters.advancedFilter?.items
            ?.map(getFilterValues)
            ?.flat()
            ?.map(typeOfFilter => getEnumValue(typeOfFilter, TypeOfLayoutFilter, TypeOfLayoutFilter.Other)) || [];

        const eventProperties = {
            number_of_columns: currentLayoutSettingsRef.current.columns.length,
            number_of_filters: typeOfFilters.length,
            number_of_grouping: currentLayoutSettingsRef.current.groups.length,
            type_of_columns: currentLayoutSettingsRef.current.columns?.map(column => getEnumValue(column.columnId, TypeOfLayoutColumn, TypeOfLayoutColumn.Other)),
            type_of_filters: typeOfFilters,
            type_of_grouping: currentLayoutSettingsRef.current.groups?.map(group => getEnumValue(group.columnId, TypeOfLayoutGroup, TypeOfLayoutGroup.Other)),
            type_of_timeframe: currentLayoutSettingsRef.current.filters?.predefinedTimeFrame
                ? getEnumValue(currentLayoutSettingsRef.current.filters?.predefinedTimeFrame, PredefinedTimeFilterType, PredefinedTimeFilterType.Other)
                : undefined
        } as T;

        return eventProperties;
    }, [currentLayoutSettingsRef]);

    const trackLayoutCreated = useCallback(() => {
        ampliLogRocket("layoutCreated", getUpsertLayoutAmpliEventProps<LayoutCreated["event_properties"]>());
    }, [ampliLogRocket, getUpsertLayoutAmpliEventProps]);

    const trackLayoutUpdated = useCallback(() => {
        ampliLogRocket("layoutUpdated", getUpsertLayoutAmpliEventProps<LayoutUpdated["event_properties"]>());
    }, [ampliLogRocket, getUpsertLayoutAmpliEventProps]);

    const trackLayoutDeleted = useCallback(() => {
        ampliLogRocket("layoutDeleted");
    }, [ampliLogRocket]);

    return {
        trackLayoutCreated,
        trackLayoutUpdated,
        trackLayoutDeleted
    };
};
