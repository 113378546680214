import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { TimesExplorerLayout, TimesExplorerProps } from "@7pace/times-explorer";

import { ActionToastRef } from "../../../common/components/ActionToast/ActionToast";
import { useCacheStorage } from "../../../common/hooks/useCacheStorage";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTimesExplorerSelectedLayoutId } from "../../ObjectViews/hooks/useTimesExplorerSelectedLayoutId";
import { getTimesExplorerLayouts, setTimesExplorerLayoutHasUnsavedChanges, setTimesExplorerLayouts, setTimesExplorerSelectedLayoutId } from "../state/timesExplorerLayoutsReducer";
import { MondayTimesExplorerLayout, MondayTimesExplorerLayouts, TimesExplorerLayoutChangeModes } from "../types/TimesExplorerLayout";
import { checkIfSettingsChanged, getMaxOrder } from "../utils/layoutUtils";
import { useAmpliLayoutEvents } from "./useAmpliLayoutEvents";
import { useTimesExplorerAdvancedFilter } from "./useTimesExplorerAdvancedFilter";
import { useTimesExplorerLayoutsCustomEventListener } from "./useTimesExplorerLayoutsCustomEvents";


export const DEFAULT_LAYOUT_ID = "te_default_layout";
export const DEFAULT_LAYOUT_ORDER = 0;
const DEFAULT_LAYOUT_NAME = "Times Explorer";
const LAYOUTS_KEY = "7p_te_layouts";
const EMPTY_DEFAULT_SETTINGS: TimesExplorerLayout = {
    columns: [],
    filters: {},
    groups: []
};
const EMPTY_DEFAULT_LAYOUT: MondayTimesExplorerLayout = {
    ...EMPTY_DEFAULT_SETTINGS,
    layoutId: DEFAULT_LAYOUT_ID,
    selected: true,
    name: DEFAULT_LAYOUT_NAME,
    order: DEFAULT_LAYOUT_ORDER
};

const AUTO_HIDE_TOAST = 10000;
const MAX_WIDTH_TOAST = 450;

export type TimesExplorerLayoutResult =
    Pick<TimesExplorerProps, "layout"> &
    Pick<TimesExplorerProps["events"], "onLayoutChange" | "onAdvancedFilterClick"> & {
        layoutId: string;
    };

type LayoutSwitchModes = TimesExplorerLayoutChangeModes.SWITCH | TimesExplorerLayoutChangeModes.CREATE;

export const useTimesExplorerLayout = (actionToastRef: ActionToastRef): TimesExplorerLayoutResult => {
    const dispatch = useAppDispatch();
    const selectedId = useTimesExplorerSelectedLayoutId();
    const layouts = useAppSelector(getTimesExplorerLayouts);
    const currentLayoutSettingsRef = useRef<TimesExplorerLayout>();
    const { trackLayoutCreated, trackLayoutUpdated, trackLayoutDeleted } = useAmpliLayoutEvents(currentLayoutSettingsRef);

    const { onAdvancedFilterClick, logAdvancedFilterChange } = useTimesExplorerAdvancedFilter();
    const [storedLayouts, storeLayouts, haveLayoutsBeenLoaded] = useCacheStorage<MondayTimesExplorerLayouts>(LAYOUTS_KEY, true);
    const currentLayouts = useRef<MondayTimesExplorerLayouts>({});
    const maxOrder = useRef(DEFAULT_LAYOUT_ORDER);
    const [layoutSeed, setLayoutSeed] = useState(0);

    useLayoutEffect(() => {
        if (!haveLayoutsBeenLoaded) {
            return;
        }

        const toBeStoredLayouts = storedLayouts || { [DEFAULT_LAYOUT_ID]: EMPTY_DEFAULT_LAYOUT };
        const selectedLayout = Object.values(toBeStoredLayouts).find((l) => l.selected);
        const id = selectedLayout?.layoutId || DEFAULT_LAYOUT_ID;
        const maxCachedOrder = getMaxOrder(toBeStoredLayouts);

        dispatch(setTimesExplorerLayouts(toBeStoredLayouts));
        dispatch(setTimesExplorerSelectedLayoutId(id));

        currentLayouts.current = toBeStoredLayouts;
        maxOrder.current = maxCachedOrder;
    }, [dispatch, haveLayoutsBeenLoaded, storedLayouts]);

    useEffect(() => {
        // Backdoor to cleanup local layout
        // Press Alt+Shift+Ctrl+L from Times Explorer
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.altKey && event.shiftKey && event.ctrlKey && event.code === "KeyL") {
                storeLayouts(null);
                console.log("layout cleared");
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [dispatch, storeLayouts]);

    const onLayoutChange = useCallback(async (newLayoutSettings: TimesExplorerLayout) => {
        const didSettingsChange = checkIfSettingsChanged(layouts[selectedId], newLayoutSettings);

        logAdvancedFilterChange(newLayoutSettings.filters?.advancedFilter);

        currentLayoutSettingsRef.current = newLayoutSettings;
        dispatch(setTimesExplorerLayoutHasUnsavedChanges(didSettingsChange));
    }, [selectedId, layouts, logAdvancedFilterChange, dispatch]);

    const switchLayout = useCallback((mode: LayoutSwitchModes, newLayouts: MondayTimesExplorerLayouts, id: string, name?: string) => {
        const currentId = selectedId;
        const currentSettings = currentLayoutSettingsRef.current;
        const newSelectedLayout = mode === TimesExplorerLayoutChangeModes.SWITCH ? {
            ...newLayouts[id],
            selected: true,
        } : {
            ...currentSettings,
            layoutId: id,
            name,
            selected: true,
            order: ++maxOrder.current
        };
        const currentSelectedLayout = {
            ...newLayouts[currentId],
            selected: false
        };
        newLayouts[id] = newSelectedLayout;
        newLayouts[currentId] = currentSelectedLayout;

        dispatch(setTimesExplorerSelectedLayoutId(id));
        const { filters, groups, columns } = newSelectedLayout;
        currentLayoutSettingsRef.current = { columns, groups, filters };
        dispatch(setTimesExplorerLayoutHasUnsavedChanges(false));
    }, [dispatch, selectedId]);

    const revertDeletedLayout = useCallback((deletedLayout: MondayTimesExplorerLayout) => {
        const layoutsCopy = { ...currentLayouts.current };
        layoutsCopy[deletedLayout.layoutId] = deletedLayout;
        dispatch(setTimesExplorerLayouts(layoutsCopy));
        storeLayouts(layoutsCopy);
        currentLayouts.current = layoutsCopy;
    }, [dispatch, storeLayouts]);

    const deleteLayout = useCallback((id: string, newLayouts: MondayTimesExplorerLayouts) => {
        const toBeDeletedLayout = newLayouts[id];
        if (toBeDeletedLayout?.selected) {
            // PJ: find max order lower than selected order
            const { id: newSelectedLayoutId } = Object.keys(newLayouts).reduce((acc, key) => {
                const layout = newLayouts[key];
                return layout.order < toBeDeletedLayout.order && layout.order > acc.order ? { id: key, order: layout.order } : acc;
            }, { id: DEFAULT_LAYOUT_ID, order: 0 });
            switchLayout(TimesExplorerLayoutChangeModes.SWITCH, newLayouts, newSelectedLayoutId);
        }

        delete newLayouts[id];

        actionToastRef.showToast(
            "View deleted successfully",
            {
                text: "Undo",
                callback: () => revertDeletedLayout(toBeDeletedLayout),
            },
            {
                maxWidth: MAX_WIDTH_TOAST,
                autoHideDuration: AUTO_HIDE_TOAST
            }
        );
    }, [actionToastRef, revertDeletedLayout, switchLayout]);

    useTimesExplorerLayoutsCustomEventListener(useCallback((detail) => {
        const { changeMode: eventType, layout: { id, name } } = detail;
        const newLayouts: MondayTimesExplorerLayouts = layouts && Object.keys(layouts).length ? { ...layouts } : { [DEFAULT_LAYOUT_ID]: { ...EMPTY_DEFAULT_LAYOUT } };

        switch (eventType) {
            case TimesExplorerLayoutChangeModes.SWITCH:
                switchLayout(eventType, newLayouts, id);
                break;
            case TimesExplorerLayoutChangeModes.CREATE:
                if (!currentLayoutSettingsRef.current) {
                    currentLayoutSettingsRef.current = { ...EMPTY_DEFAULT_SETTINGS };
                }
                switchLayout(eventType, newLayouts, id, name);
                trackLayoutCreated();
                break;
            case TimesExplorerLayoutChangeModes.DELETE:
                deleteLayout(id, newLayouts);

                trackLayoutDeleted();
                break;
            case TimesExplorerLayoutChangeModes.RENAME:
                newLayouts[id] = {
                    ...newLayouts[id],
                    name
                };
                break;
            case TimesExplorerLayoutChangeModes.SAVE:
                newLayouts[id] = {
                    ...newLayouts[id],
                    ...currentLayoutSettingsRef.current
                };
                dispatch(setTimesExplorerLayoutHasUnsavedChanges(false));
                setLayoutSeed(prev => ++prev);

                trackLayoutUpdated();
                actionToastRef.showToast("View updated successfully", null, { maxWidth: MAX_WIDTH_TOAST, autoHideDuration: AUTO_HIDE_TOAST });
                break;
        }

        storeLayouts(newLayouts);
        dispatch(setTimesExplorerLayouts(newLayouts));
        currentLayouts.current = newLayouts;
    }, [actionToastRef, deleteLayout, dispatch, layouts, storeLayouts, switchLayout, trackLayoutCreated, trackLayoutDeleted, trackLayoutUpdated]));

    return useMemo(() => ({
        layout: layouts?.[selectedId],
        layoutId: selectedId + layoutSeed,
        onLayoutChange,
        onAdvancedFilterClick
    }), [layouts, selectedId, layoutSeed, onLayoutChange, onAdvancedFilterClick]);
};
