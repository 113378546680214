import { useCallback, useMemo } from "react";

import { VirtualizedOption } from "../../VirtualizedList/types";
import { AdvancedDropdownMenuAdapterProps } from "../types";


type AdvancedDropdownMenuSelectionProps = Pick<AdvancedDropdownMenuAdapterProps, "getValue" | "setValue" | "selectOption">;

export const useAdvancedDropdownMenuSelection = ({ getValue, setValue, selectOption }: AdvancedDropdownMenuSelectionProps) => {
    const selectedOption = useMemo(() => {
        return getValue()[0];
    }, [getValue]);

    const onSelectOption = useCallback((item: VirtualizedOption) => {
        setValue(item);
        selectOption(item);
    }, [setValue, selectOption]);

    return {
        selectedOption,
        onSelectOption
    };
};
