import { FC } from "react";
import { ButtonSlotProps } from "@7pace/design";
import { Button, IconButton } from "monday-ui-react-core";
import { ButtonProps } from "monday-ui-react-core/dist/types/components/Button/Button";
import styled from "styled-components";

import { mondayIcons } from "../MondayIconSlot/mondayIcons";
import { mapButtonSlotKind, mapButtonSlotSize } from "./utils";


type MondayButtonSlotBaseProps = ButtonSlotProps & Omit<ButtonProps, "kind" | "size">;

export const MondayButtonSlotBase: FC<MondayButtonSlotBaseProps> = ({ text, icon, kind, size, ...defaults }) => {
    // AZ: for some reason data-testid is ignored for IconButton trough prop
    // (Button is fine)
    const onSetRef = (elem: HTMLButtonElement) => {
        if (!elem || !defaults["data-testid"]) {
            return;
        }

        elem.setAttribute("data-testid", defaults["data-testid"]);
    };

    return (
        <>
            {text ?
                <MondayButtonWrapper
                    kind={mapButtonSlotKind(kind)}
                    size={mapButtonSlotSize(size)}
                    leftIcon={mondayIcons[icon]}
                    {...defaults}
                >
                    {text}
                </MondayButtonWrapper> :
                <IconButton
                    ref={onSetRef}
                    kind={mapButtonSlotKind(kind)}
                    size={mapButtonSlotSize(size)}
                    icon={mondayIcons[icon]}
                    {...defaults}
                />
            }
        </>
    );
};

const MondayButtonWrapper = styled(Button)`
    // GM: Otherwise loader causes scroll in the modal
    [class*=textPlaceholder] {
        display: none;
    }
`;
