import { AdvancedFilter } from "@7pace/times-explorer";
import { isDeepEqual } from "@7pace/utilities";


export const getIsAdvancedPickerChanged = (curAdvancedFilter: AdvancedFilter, newAdvancedFilter: AdvancedFilter) => {
    // AZ: lastItemId might change even though the filters are the same
    const { lastItemId: _lastItemIdOld, ...advancedFilter } = curAdvancedFilter ?? {};
    const { lastItemId: _lastItemIdNew, ...updatedAdvancedFilter } = newAdvancedFilter ?? {};

    return !isDeepEqual(advancedFilter, updatedAdvancedFilter);
};
