import { FocusEvent, forwardRef, useCallback, useEffect, useMemo } from "react";
import { SlotSize } from "@7pace/design";
import { createUUIDPart, stringUtils } from "@7pace/utilities";
import { Dropdown } from "monday-ui-react-core";
import styled from "styled-components";

import { AdvancedDropdownInputLoader } from "./components/AdvancedDropdownInputLoader";
import { useAdvancedDisableDropdownKeyboard } from "./hooks/useAdvancedDisableDropdownKeyboard";
import { AdvancedSearchFn, useAdvancedSearchableOptions } from "./hooks/useAdvancedSearchableOptions";
import { mapAdvancedDropdownSlotSize } from "./utils/mapAdvancedDropdownSlotSize";
import { FIXED_DROPDOWN_CONTAINER_ID } from "../../../styles/FixedDropdownContainer";
import { MondayInputError } from "../MondayPrimitives/MondayInputError";
import { ADVANCED_DROPDOWN_CONTAINER_CLASS, ADVANCED_DROPDOWN_MENU_CLASS, ADVANCED_DROPDOWN_SINGLE_VALUE_CLASS, baseAdvancedDropdownContainerStyles, baseAdvancedDropdownStyles } from "./styles";
import { AdvancedDropdownMenuAdapterProps, AdvancedDropdownOption, MenuPosition } from "./types";


type AdvancedDropdownStyles = {
    error?: string;
    size?: SlotSize;
};

// AZ: it's any in vibe
export type AdvancedDropdownRef = {
    select: {
        focus: () => void;
    };
};

type AdvancedDropdownProps = {
    options: AdvancedDropdownOption[];
    id?: string;
    menuIsOpen?: boolean;
    value?: AdvancedDropdownOption | AdvancedDropdownOption[];
    showSpinner?: boolean;
    showError?: boolean;
    placeholder?: string;
    tabIndex?: number;
    disabled?: boolean;
    className?: string;
    label?: string;
    clearable?: boolean;
    searchable?: boolean;
    maxMenuHeight?: number;
    openMenuOnClick?: boolean;
    openMenuOnFocus?: boolean;
    closeMenuOnSelect?: boolean;
    autoFocus?: boolean;
    "data-testid"?: string;
    fixedDropdownContainer?: boolean;
    onOptionSelect?: (data: AdvancedDropdownOption) => void;
    valueRenderer?: (data: AdvancedDropdownOption | AdvancedDropdownOption[]) => string | JSX.Element;
    onClear?: () => void;
    menuRenderer?: (data: AdvancedDropdownMenuAdapterProps) => JSX.Element;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    onBlur?: (event: FocusEvent) => void;
    onSearch?: AdvancedSearchFn<AdvancedDropdownOption>;
} & AdvancedDropdownStyles;

export const AdvancedDropdown = forwardRef<AdvancedDropdownRef, AdvancedDropdownProps>(({
    options,
    error,
    className,
    showSpinner = false,
    showError = true,
    openMenuOnFocus = true,
    label,
    id,
    size: slotSize,
    tabIndex = 0,
    "data-testid": dataTestId,
    fixedDropdownContainer,
    onSearch,
    ...rest
}, ref) => {
    const dropdownId = useMemo<string>(() => id || `advanced-dropdown-${createUUIDPart()}`, [id]);
    const divElement = useMemo(() => document.createElement("div"), []);

    const size = useMemo(() => mapAdvancedDropdownSlotSize(slotSize), [slotSize]);

    const disableKeyDown = useAdvancedDisableDropdownKeyboard();

    const onSearchInternal: AdvancedSearchFn<AdvancedDropdownOption> = useCallback((term, searchableOptions) => {
        return searchableOptions.filter(x => x.label.toLocaleLowerCase().includes(term));
    }, []);

    const { searchedOptions, onInputChange } = useAdvancedSearchableOptions(
        options,
        onSearch ?? onSearchInternal
    );

    useEffect(() => {
        const dropdown = document.getElementById(dropdownId);
        const dropdownInput = dropdown.firstChild;

        divElement.id = `spinner-container-${dropdownId}`;

        dropdownInput.firstChild.insertBefore(divElement, dropdownInput.firstChild.lastChild);
    }, [divElement, dropdownId]);

    const internalClassName = stringUtils.constructClasses(
        ADVANCED_DROPDOWN_CONTAINER_CLASS,
        error && "invalid-input"
    );

    return (
        <DropdownContainer className={className} size={slotSize} data-testid={dataTestId}>
            {label && <Label>{label}</Label>}
            <DropdownStyled
                {...rest}
                ref={ref}
                id={dropdownId}
                size={size}
                options={searchedOptions}
                error={error}
                className={internalClassName}
                openMenuOnFocus={openMenuOnFocus}
                tabSelectsValue={false}
                tabIndex={tabIndex}
                popupsContainerSelector={fixedDropdownContainer ? `#${FIXED_DROPDOWN_CONTAINER_ID}` : undefined}
                // AZ: menuPlacement="auto" doesn't work as menu height is calculated inside the menu renderer
                // also menu is not automatically shrinks if it's going to overflow viewport
                // https://github.com/JedWatson/react-select/issues/5642
                // instead we do this manually in useAdvancedDropdownMenuBase
                menuPlacement="bottom"
                menuPosition={fixedDropdownContainer ? MenuPosition.Fixed : MenuPosition.Absolute}
                dropdownMenuWrapperClassName={ADVANCED_DROPDOWN_MENU_CLASS}
                singleValueWrapperClassName={ADVANCED_DROPDOWN_SINGLE_VALUE_CLASS}
                onKeyDown={disableKeyDown}
                onInputChange={onInputChange}
            />
            {showSpinner && <AdvancedDropdownInputLoader parent={divElement} />}
            {showError && error && <MondayInputError>{error}</MondayInputError>}
        </DropdownContainer>
    );
});

const DropdownStyled = styled(Dropdown) <AdvancedDropdownStyles>`
    ${baseAdvancedDropdownStyles};

    width: 100%;
    ${props => props.error ? "border: 1px solid var(--negative-color) !important;" : ""};
`;

const DropdownContainer = styled.div`
    ${baseAdvancedDropdownContainerStyles};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${p => p.theme.spacing.xs};
`;

const Label = styled.div`
    color: ${p => p.theme.color.foreground.text.primary};
    font: ${p => p.theme.typography.body.regular.m};
`;
