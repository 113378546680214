import { useMemo } from "react";

import { TimesExplorerLayoutInfo } from "../../TimesExplorer/types/TimesExplorerLayout";
import { PageTabProps } from "../common/PageTab";
import { PreparedPageTabWithMenuProps } from "../common/PageTabWithMenu";


export const FALLBACK_TAB_NAME = "View";

export const useCustomTabHandling = (tabs: TimesExplorerLayoutInfo[], basePath: string, defaultPage: PageTabProps) => {
    const customTabPageProps = useMemo((): PreparedPageTabWithMenuProps[] => {
        return tabs
            .map<PreparedPageTabWithMenuProps>(tab => ({
                name: tab.name,
                path: `${basePath}/${tab.id?.replaceAll(" ", "-")}`,
                id: tab.id,
                icon: defaultPage.id === tab.id ? defaultPage.icon : undefined
            }));
    }, [basePath, defaultPage.icon, defaultPage.id, tabs]);

    const getNewTabName = (name: string) => name || FALLBACK_TAB_NAME;

    return {
        customTabPageProps,
        getNewTabName
    };
};
