import { useMemo } from "react";
import { isDeepEqual } from "@7pace/utilities";
import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";


type UseStorageQueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey> =
    Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "refetchOnWindowFocus" | "staleTime" | "placeholderData" | "onSuccess">;

/**
 * This hook is used to store query data in localStorage.
 * Use it when you need to store data that is not sensitive, changes rarely and can be restored from the server
 * and only when you need to re-fetch data once on page reload or when queryKey changes.
 * Do not use queryClient.invalidateQueries with this hook, it will not work as expected.
 * @param options - useQuery options
 * @returns useQuery result
 */
export const useStorageQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>
    (options: UseStorageQueryOptions<TQueryFnData, TError, TData, TQueryKey>) => {
    const queryClient = useQueryClient();

    const mainKey = options.queryKey[0];
    const key = useMemo(() => `7p_${mainKey}`, [mainKey]);

    const { data: globalData } = useQuery<TQueryFnData, TError, TData, TQueryKey>({
        ...options,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
        keepPreviousData: true,
        structuralSharing: (oldData, newData) => oldData === newData ? oldData : newData,
        onSuccess: (newData) => localStorage.setItem(key, JSON.stringify(newData))
    });

    let currentData = queryClient.getQueryData<TData>([key]);

    if (currentData === undefined) {
        const localStorageData = localStorage.getItem(key);
        const data = localStorageData && localStorageData !== "undefined" ? JSON.parse(localStorageData) : null;
        queryClient.setQueryData([key], data);
        currentData = data;
    }

    if (globalData && currentData !== globalData) {
        if (!currentData || !isDeepEqual(currentData as object, globalData as object)) {
            queryClient.setQueryData([key], globalData);
        } else {
            // AZ: for some reason useQuery returns new reference object after queryFn
            // set it back to stored object, to omit unnecessary isDeepEqual check
            queryClient.setQueryData(options.queryKey, currentData);
        }
    }

    return queryClient.getQueryState<TQueryFnData>([key]);
};
