import { useCallback } from "react";

import { convertValue } from "../../utils/convertValue";
import { getEnvValue } from "../../utils/getEnvValue";
import { sdk } from "../mondaySdk";
import { useMondayContext } from "./useMondayContext";


export const useMondayStorage = () => {
    const { context } = useMondayContext();

    const getKey = useCallback((prefix: string, key: string) => `${getEnvValue("ENV_NAME")}-${prefix}-${key}`, []);
    const getUserKey = useCallback((key: string) => getKey(context.user.id, key), [context.user.id, getKey]);
    const getAccountKey = useCallback((key: string) => getKey(context.account.id, key), [context.account.id, getKey]);

    const getItem = useCallback(async <T>(key: string): Promise<T> => {
        const { value } = (await sdk.storage.getItem(key)).data;
        return convertValue<T>(value);
    }, []);

    const setItem = useCallback(async <T>(key: string, value: T) => {
        // AZ: we don't want to stringify strings as it will create double quotes
        // e.g. JSON.stringify("test") => '"test"'
        const stringifiedValue = typeof value === "string" ? value : JSON.stringify(value);
        const { data } = (await sdk.storage.setItem(key, stringifiedValue));
        return data.success ? value : null;
    }, []);

    const deleteItem = useCallback(async (key: string) => {
        const { data } = (await sdk.storage.deleteItem(key));
        return data.success;
    }, []);

    const getUserItem = useCallback(async <T>(key: string): Promise<T> => getItem(getUserKey(key)), [getUserKey, getItem]);
    const setUserItem = useCallback(async <T>(key: string, value: T) => setItem(getUserKey(key), value), [getUserKey, setItem]);
    const deleteUserItem = useCallback(async (key: string) => deleteItem(getUserKey(key)), [getUserKey, deleteItem]);
    const getAccountItem = useCallback(async <T>(key: string): Promise<T> => getItem(getAccountKey(key)), [getAccountKey, getItem]);
    const setAccountItem = useCallback(async <T>(key: string, value: T) => setItem(getAccountKey(key), value), [getAccountKey, setItem]);
    const deleteAccountItem = useCallback(async (key: string) => deleteItem(getAccountKey(key)), [getAccountKey, deleteItem]);

    return {
        getUserItem,
        setUserItem,
        deleteUserItem,
        getAccountItem,
        setAccountItem,
        deleteAccountItem
    };
};
