import { SelectOptionItem, SlotSize } from "@7pace/design";

import { SlotDropdownOption } from "./types";


export const mapSelectOptionsToDropdownOption = (option: SelectOptionItem, size: SlotSize): SlotDropdownOption => option && ({
    value: option.value,
    label: option.text,
    size
});

export const mapDropdownOptionToSelectOption = (option: SlotDropdownOption): SelectOptionItem => option && ({
    value: option.value,
    text: option.label
});
