import { useCallback } from "react";

import { getLogRocketSessionUrl } from "../../../../store/app/appReducer";
import { useAppSelector } from "../../../../store/hooks";
import { EventOptions } from "../ampli-autogen";
import { mondayAmpli } from "../mondayAmpli";


type NonEventFunctions = "load" | "identify" | "flush" | "track" | "isLoaded" | "client";

type AmpliEvents = Omit<typeof mondayAmpli, NonEventFunctions>;

type ExtractLogRocketEvents<T> = {
    [K in keyof T as T[K] extends (arg: { log_rocket_session?: string; }) => unknown ? K : never]: T[K]
};

type EventsWithArgs = {
    [K in keyof ExtractLogRocketEvents<AmpliEvents>]: typeof mondayAmpli[K] extends (arg: infer A) => unknown ? (A extends EventOptions ? never : Omit<A, "log_rocket_session">) : never;
};

export const useAmpliLogRocket = () => {
    const logRocketSessionUrl = useAppSelector(getLogRocketSessionUrl);

    return useCallback(function <E extends keyof EventsWithArgs>(event: E, eventProps?: EventsWithArgs[E]) {
        const finalEventProps = { ...eventProps as object, log_rocket_session: logRocketSessionUrl };
        mondayAmpli[event](finalEventProps as EventsWithArgs[E]);
    }, [logRocketSessionUrl]);
};
