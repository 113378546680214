import { SlotSize } from "@7pace/design";

import { VirtualizedListMenuProps, VirtualizedOption } from "../VirtualizedList/types";


export enum MenuPosition {
    Absolute = "absolute",
    Fixed = "fixed"
}

export type AdvancedDropdownOption<TValue extends number | string = number | string> = VirtualizedOption<TValue> & {
    size: SlotSize;
};

export type AdvancedDropdownMenuProps<TOption extends VirtualizedOption = VirtualizedOption> = VirtualizedListMenuProps<TOption> & {
    dropdownId: string;
    menuPosition: MenuPosition;
};

export type AdvancedDropdownMenuAdapterProps<TOption extends VirtualizedOption = VirtualizedOption> =
    Omit<AdvancedDropdownMenuProps<TOption>, "dropdownId"> & {
        size?: SlotSize;
        selectProps: {
            id: string;
        };
        getValue: () => TOption[];
        setValue: (value: TOption) => void;
        menuRenderer: (props: AdvancedDropdownMenuProps<TOption>) => JSX.Element;
    };
