import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store/store";
import { MondayTimesExplorerLayouts } from "../types/TimesExplorerLayout";


type LayoutsState = {
    timesExplorerSelectedLayoutId: string;
    timesExplorerLayouts: MondayTimesExplorerLayouts;
    timesExplorerLayoutHasUnsavedChanges: boolean;
};

const initialState: LayoutsState = {
    timesExplorerSelectedLayoutId: null,
    timesExplorerLayouts: null,
    timesExplorerLayoutHasUnsavedChanges: false
};

const timesExplorerLayoutsReducer = createSlice({
    name: "timesExplorerLayouts",
    initialState: initialState,
    reducers: {
        setTimesExplorerSelectedLayoutId: (state, action: PayloadAction<string>) => {
            state.timesExplorerSelectedLayoutId = action.payload;
        },
        setTimesExplorerLayouts: (state, action: PayloadAction<MondayTimesExplorerLayouts>) => {
            state.timesExplorerLayouts = action.payload;
        },
        setTimesExplorerLayoutHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.timesExplorerLayoutHasUnsavedChanges = action.payload;
        }
    }
});

export const {
    setTimesExplorerSelectedLayoutId,
    setTimesExplorerLayouts,
    setTimesExplorerLayoutHasUnsavedChanges
} = timesExplorerLayoutsReducer.actions;

//selectors
export const getTimesExplorerSelectedLayoutId = (state: RootState) => state.timesExplorerLayouts.timesExplorerSelectedLayoutId;
export const getTimesExplorerLayouts = (state: RootState) => state.timesExplorerLayouts.timesExplorerLayouts;
export const getTimesExplorerLayoutHasUnsavedChanges = (state: RootState) => state.timesExplorerLayouts.timesExplorerLayoutHasUnsavedChanges;

export default timesExplorerLayoutsReducer.reducer;
