import { forwardRef } from "react";
import { TextField } from "monday-ui-react-core";
import styled, { css } from "styled-components";

import { Status } from "./hooks/useCustomTabsManagement";


const MAX_NAME_LENGTH = 100;

export type TabsInputProps = {
    newTabName: string;
    setNewTabName: (value: string) => void;
    handleTabSave: () => void;
    createNewTabId: string;
    status: Status;
    isActiveTabBeingRenamed: boolean;
};

export const TabsInput = forwardRef<HTMLInputElement, TabsInputProps>(({
    handleTabSave,
    newTabName,
    setNewTabName,
    createNewTabId,
    status,
    isActiveTabBeingRenamed
}, ref) => {
    return (
        <TextFieldContainer shouldAddUnderline={status === "creating" || isActiveTabBeingRenamed}>
            <TextField
                ref={ref}
                size={TextField.sizes.SMALL}
                placeholder={"Add a name"}
                value={newTabName}
                onChange={setNewTabName}
                maxLength={MAX_NAME_LENGTH}
                onBlur={(event: React.FocusEvent<HTMLElement>) => {
                    const relatedTarget = event.relatedTarget;

                    if (relatedTarget && relatedTarget.id === createNewTabId) {
                        // return early and let the onClick event handle the creation and saving of the previous tab
                        return;
                    }

                    handleTabSave();
                }}
                onKeyDown={e => e.key === "Enter" && handleTabSave()}
                autoFocus
            />
        </TextFieldContainer>);
});


const TextFieldContainer = styled.div<{ shouldAddUnderline: boolean; }>`
    position: relative;
    ${p => p.shouldAddUnderline && css`
        ::after {
            border-bottom: 2px solid ${p => p.theme.color.background.accent.primary.rest};  
            content: "";
            width: 100%;
            position: absolute;
            bottom: -4px;
        }
    `}
`;
