export type AccountAnalyticsContext = {
    groups: {
        /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013647917?atlOrigin=eyJpIjoiNGNhNmRmZDBjY2RjNDJlZThhMDAzMzZmZDI5NWYxZDIiLCJwIjoiYyJ9 */
        license: {
            group_type: string;
            group_identifier: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013647989/status+Group+Property */
            status: "cancelled" | "inactive" | "active";
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050381424/tier */
            tier: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013320298/approx+user+count */
            approx_user_count: number;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050381449/last+updated */
            last_updated: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013320311/app+key */
            app_key: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050446785/company */
            company: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013123657/hosting */
            hosting: "cloud";
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050154268/maintenance+start+date */
            maintenance_start_date: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050446799/maintenance+end+date */
            maintenance_end_date: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050154324/billing+cycle */
            billing_cycle: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013582438/license+type */
            license_type: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050577653/is+eval */
            is_eval: boolean;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96050316079/country */
            country: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96245844115/pricing+version */
            pricing_version: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96078659785/contact+user+name */
            contact_user_name: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96078659803/contact+user+email */
            contact_user_email: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96245875959/contact+user+department */
            contact_user_department: string;
        };
        /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013647967/host */
        host: {
            group_type: "host";
            group_identifier: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013484162/ecosystem */
            ecosystem: "monday";
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013582477/parent+product */
            parent_product: "monday";
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96013385881/url
             * @deprecated Will be removed in CDT 2.6 or equivalent. Use display_url instead.
             */
            url: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96245844093/account+max+users */
            account_max_users: number;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96245875911/account+tier */
            account_tier: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96015024496/test+instance */
            test_instance: boolean;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96405291134/display+url */
            display_url: string;
            /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96405192892/base+url */
            base_url: string;
        };
    };
    /** https://appfireteam.atlassian.net/wiki/spaces/PM/pages/96014106753/version */
    app_version: string;
};

export type UninstallationState = {
    purgeDueDate?: string;
};

export type LicensePlanId = "free" | `plan_ver_${string}_${number}_users` | `plan_ver_${string}_Unlimited_users`;

export enum LicenseStatus {
    ACTIVE = "active",
    DUE_TO_EXPIRY = "due_to_expiry",
    EXPIRED = "expired"
}

export enum MondayStatusReason {
    NO_PAYMENT = "no_payment",
}

export enum InternalStatusReason {
    NO_SEATS = "no_seats"
}

export type License = {
    planId: LicensePlanId | null;
    isTrial: boolean;
    accountSeats: number | null;
    planSeats: number;
    mondayExpirationDate: string | null;
    mondayStatus: LicenseStatus;
    mondayStatusReason: MondayStatusReason | null;
    internalExpirationDate: string | null;
    internalStatus: LicenseStatus;
    internalStatusReason: InternalStatusReason | null;
};

export type ClientConfig = {
    account: {
        analytics: AccountAnalyticsContext;
        "uninstallation-state": UninstallationState;
        license: License;
    };
};

