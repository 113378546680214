import { FC, memo } from "react";

import { VirtualizedPolyWrapper } from "../../VirtualizedList/components/VirtualizedPolyWrapper";
import { useVirtualizedListNavigation } from "../../VirtualizedList/hooks/useVirtualizedListNavigation";
import { useVirtualizedPolyOptions } from "../../VirtualizedList/hooks/useVirtualizedPolyOptions";
import { VirtualizedOption } from "../../VirtualizedList/types";
import { VirtualizedList } from "../../VirtualizedList/VirtualizedList";
import { useAdvancedDropdownMenuBase } from "../hooks/useAdvancedDropdownMenuBase";
import { AdvancedDropdownMenuProps } from "../types";


export const AdvancedDropdownMenu: FC<AdvancedDropdownMenuProps<VirtualizedOption>> = memo(({
    dropdownId,
    options,
    maxMenuHeight,
    optionHeight,
    selectedOption,
    virtualizedItem,
    menuPosition,
    "data-testid": dataTestId,
    selectOption
}) => {
    const polyOptions = useVirtualizedPolyOptions({
        options,
        selectedOption,
        loading: false,
        noOptions: options.length === 0,
        optionHeight,
        onChange: selectOption
    });

    const baseMenuProps = useAdvancedDropdownMenuBase({
        dropdownId,
        menuPosition,
        polyOptions,
        selectedOption,
        optionHeight,
        maxMenuHeight
    });

    const navigationMenuProps = useVirtualizedListNavigation({
        polyOptions,
        selectOption
    });

    return (
        <VirtualizedList
            {...baseMenuProps}
            {...navigationMenuProps}
            polyOptions={polyOptions}
            virtualizedItem={virtualizedItem ?? VirtualizedPolyWrapper}
            data-testId={dataTestId ?? "virtualized-list"}
        />
    );
});
