import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { AccessDenied } from "../common/AccessControl/components/AccessDenied";
import { MondayAccessControl } from "../common/AccessControl/MondayAccessControl";
import { REQUIRED_PERMISSIONS } from "../common/utils/constants";
import AllTime from "../features/ObjectViews/AllTime/AllTime";
import MyTime from "../features/ObjectViews/MyTime/MyTime";
import Settings from "../features/ObjectViews/Settings/Settings";
import RoutePath from "./RoutePath";
import withSuspense from "./withSuspense";


const Root = lazy(() => import("../features/Root/Root"));
const Monthly = lazy(() => import("../features/Monthly/Monthly"));
const TimesExplorer = lazy(() => import("../features/TimesExplorer/TimesExplorer"));
const Timesheet = lazy(() => import("../features/Timesheet/Timesheet"));
const Weekly = lazy(() => import("../features/Weekly/Weekly"));
const ItemView = lazy(() => import("../features/ItemView/components/ItemView"));
const TotalTimeWidget = lazy(() => import("../features/Widgets/components/TotalTimeWidget/TotalTimeWidget"));
const ActualVsPlannedWidget = lazy(() => import("../features/Widgets/components/ActualVsPlannedWidget/ActualVsPlannedWidget"));
const TimeInsightReportWidget = lazy(() => import("../features/Widgets/components/TimeInsightReportWidget/TimeInsightReportWidget"));
const FieldsSettings = lazy(() => import("../features/Settings/FieldsSettings/FieldsSettings"));
const ColumnsConnectorSettings = lazy(() => import("../features/Settings/ColumnsConnectorSettings/ColumnsConnectorSettings"));
const RolesAndPermissionsSettings = lazy(() => import("../features/Settings/RolesAndPermissionsSettings/RolesAndPermissionsSettings"));
const UsersSettings = lazy(() => import("../features/Settings/UsersSettings/UsersSettings"));


const routes: RouteObject[] = [
    {
        path: "/",
        element: withSuspense(<Root />),
        children: [
            {
                path: RoutePath.ItemView,
                element: (
                    <MondayAccessControl
                        fallbackComponent={<AccessDenied />}
                        permissionsRequirement={REQUIRED_PERMISSIONS.VIEW_PAGE.ITEM_VIEW}
                    >
                        <ItemView />
                    </MondayAccessControl>
                )
            },
            {
                path: RoutePath.Widget,
                children: [
                    {
                        path: RoutePath.TotalTime,
                        element: (
                            <MondayAccessControl
                                fallbackComponent={<AccessDenied />}
                                permissionsRequirement={REQUIRED_PERMISSIONS.VIEW_PAGE.MAIN}
                            >
                                {withSuspense(<TotalTimeWidget />)}
                            </MondayAccessControl>
                        )
                    },
                    {
                        path: RoutePath.ActualVsPlanned,
                        element: (
                            <MondayAccessControl
                                fallbackComponent={<AccessDenied />}
                                permissionsRequirement={REQUIRED_PERMISSIONS.VIEW_PAGE.MAIN}
                            >
                                {withSuspense(<ActualVsPlannedWidget />)}
                            </MondayAccessControl>
                        )
                    },
                    {
                        path: RoutePath.TimeInsightReportWidget,
                        element: (
                            <MondayAccessControl
                                fallbackComponent={<AccessDenied />}
                                permissionsRequirement={REQUIRED_PERMISSIONS.VIEW_PAGE.MAIN}
                            >
                                {withSuspense(<TimeInsightReportWidget />)}
                            </MondayAccessControl>
                        )
                    }
                ]
            },
            {
                path: RoutePath.ObjectView,
                children: [
                    {
                        path: RoutePath.MyTime,
                        element: <MyTime />,
                        children: [
                            {
                                path: RoutePath.Monthly,
                                element: withSuspense(<Monthly />)
                            },
                            {
                                path: RoutePath.Weekly,
                                element: withSuspense(<Weekly />)
                            },
                            {
                                path: RoutePath.Timesheet,
                                element: withSuspense(<Timesheet />)
                            }
                        ]
                    },
                    {
                        path: RoutePath.AllTime,
                        element: <AllTime />,
                        children: [
                            {
                                path: RoutePath.TimesExplorer,
                                element: withSuspense(<TimesExplorer />)
                            },
                            {
                                path: `${RoutePath.TimesExplorer}/:layoutId`,
                                element: withSuspense(<TimesExplorer />)
                            }
                        ]
                    }
                ]
            },
            {
                path: RoutePath.Settings,
                element: <Settings />,
                children: [
                    {
                        path: RoutePath.FieldsSettings,
                        element: withSuspense(<FieldsSettings />)
                    },
                    {
                        path: RoutePath.ColumnsConnectorSettings,
                        element: withSuspense(<ColumnsConnectorSettings />)
                    },
                    {
                        path: RoutePath.RolesAndPermissionsSettings,
                        element: withSuspense(<RolesAndPermissionsSettings />)
                    },
                    {
                        path: RoutePath.UsersSettings,
                        element: withSuspense(<UsersSettings />)
                    }
                ]
            }
        ]
    }
];

export default routes;
