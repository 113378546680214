import { SlotSize } from "@7pace/design";


export const mapIconSlotSize = (size: SlotSize) => {
    switch (size) {
        case SlotSize.Medium:
        case SlotSize.Large: return 20;
        case SlotSize.Small:
        default: return 16;
    }
};
