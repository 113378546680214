import { FC, FocusEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { SelectSlotProps, SlotSize } from "@7pace/design";
import { createUUIDPart } from "@7pace/utilities";
import styled from "styled-components";

import { MondaySelectSlotMultiValue, SELECTED_MULTI_VALUE_INLINE_CLASS } from "./components/MondaySelectSlotMultiValue";
import { AdvancedDropdown, AdvancedDropdownRef } from "../../components/AdvancedDropdown/AdvancedDropdown";
import { AdvancedDropdownMenu } from "../../components/AdvancedDropdown/components/AdvancedDropdownMenu";
import { AdvancedDropdownMenuAdapter } from "../../components/AdvancedDropdown/components/AdvancedDropdownMenuAdapter";
import { ADVANCED_DROPDOWN_CONTAINER_CLASS, ADVANCED_DROPDOWN_MENU_CLASS, ADVANCED_DROPDOWN_SINGLE_VALUE_CLASS } from "../../components/AdvancedDropdown/styles";
import { AdvancedDropdownMenuAdapterProps, AdvancedDropdownMenuProps } from "../../components/AdvancedDropdown/types";
import { SELECTED_VALUE_ROOT_CLASS } from "./constants";
import { SlotDropdownOption } from "./types";
import { mapDropdownOptionToSelectOption, mapSelectOptionsToDropdownOption } from "./utils";


export const MondaySelectSlot: FC<SelectSlotProps> = ({
    options,
    selected,
    multiselect,
    searchable = false,
    clearable = false,
    size = SlotSize.Medium,
    onChange,
    ...rest
}) => {
    const ref = useRef<AdvancedDropdownRef>();
    const [container, setContainer] = useState<HTMLDivElement>();

    const dropdownName = useMemo(() => `monday-select-slot-${createUUIDPart()}`, []);

    // AZ: value renderer will go here
    const valuePortalTarget = useMemo(() => {
        return container?.querySelector<HTMLElement>(`.${ADVANCED_DROPDOWN_CONTAINER_CLASS} > div > div > div`);
    }, [container]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<SlotDropdownOption[]>(() => selected ?
        selected.map((cur) => mapSelectOptionsToDropdownOption(cur, size)) :
        []
    );

    const getNewMappedOptions = useCallback((newSelectedOptions: SlotDropdownOption[]) => {
        let rawOptions = options;
        if (multiselect) {
            rawOptions = options.filter(o => !newSelectedOptions.some(so => so.value === o.value));
        }

        return rawOptions.map((cur) => mapSelectOptionsToDropdownOption(cur, size));
    }, [multiselect, options, size]);

    const [mappedOptions, setMappedOptions] = useState<SlotDropdownOption[]>(() =>
        getNewMappedOptions(selectedOptions)
    );

    useEffect(() => {
        const newMappedSelected = selected?.map(mapSelectOptionsToDropdownOption) ?? [];
        setSelectedOptions(newMappedSelected);
        setMappedOptions(getNewMappedOptions(newMappedSelected));
    }, [getNewMappedOptions, selected]);

    const onSelectedOptionsChanged = useCallback((newSelectedOptions: SlotDropdownOption[]) => {
        const mappedSelectedOptions = newSelectedOptions.map(mapDropdownOptionToSelectOption);
        setSelectedOptions(newSelectedOptions);
        onChange?.(mappedSelectedOptions);

        if (multiselect) {
            setMappedOptions(getNewMappedOptions(newSelectedOptions));
        }
    }, [getNewMappedOptions, multiselect, onChange]);

    const onOptionRemove = useCallback((removeOption: SlotDropdownOption) => {
        const newSelectedOptions = selectedOptions.filter(o => o.value !== removeOption.value);
        onSelectedOptionsChanged(newSelectedOptions);
    }, [onSelectedOptionsChanged, selectedOptions]);

    const menuRender = useCallback((props: AdvancedDropdownMenuAdapterProps<SlotDropdownOption>) => {
        return (
            <AdvancedDropdownMenuAdapter
                {...props}
                size={size}
                menuRenderer={(menuRenderProps: AdvancedDropdownMenuProps<SlotDropdownOption>) =>
                    <AdvancedDropdownMenu {...menuRenderProps} />
                }
            />
        );
    }, [size]);

    const onOptionSelect = useCallback((selectOption: SlotDropdownOption) => {
        const newSelectedOptions = multiselect && selectedOptions ? [...selectedOptions, selectOption] : [selectOption];
        onSelectedOptionsChanged(newSelectedOptions);
    }, [multiselect, onSelectedOptionsChanged, selectedOptions]);

    const onClear = useCallback(() => {
        setSelectedOptions([]);
        onChange?.([]);
    }, [onChange]);

    const onValueClick = useCallback(() => {
        setIsMenuOpen(!isMenuOpen);

        if (!isMenuOpen) {
            ref.current?.select.focus();
        }
    }, [isMenuOpen]);

    const allowCloseMenuOnKeyDown = useRef(false);

    const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" || event.key === "Escape") {
            allowCloseMenuOnKeyDown.current = true;
        }

        if (event.key === "Backspace" && clearable) {
            onClear();
        }

        if (event.key === "Enter" && !isMenuOpen) {
            setIsMenuOpen(true);
        }
    }, [clearable, isMenuOpen, onClear]);

    const onMenuClose = useCallback(() => {
        const hoveredElements = document.querySelectorAll(":hover");

        if (!allowCloseMenuOnKeyDown.current) {
            for (const elem of hoveredElements) {
                if (elem.closest(`#${dropdownName}`) && elem.classList.contains(SELECTED_VALUE_ROOT_CLASS)) {
                    return;
                }
            }
        } else {
            allowCloseMenuOnKeyDown.current = false;
        }

        setIsMenuOpen(false);
    }, [dropdownName]);

    const onMenuOpen = useCallback(() => {
        setIsMenuOpen(true);
    }, []);

    const onBlur = useCallback((event: FocusEvent) => {
        const target = (event.relatedTarget ?? event.target) as HTMLElement;
        if (target?.closest(`#${dropdownName}`)) {
            return;
        }

        setIsMenuOpen(false);
    }, [dropdownName]);

    return (
        <DropdownContainer
            ref={setContainer}
            id={dropdownName}
            isMenuOpen={isMenuOpen}
            onKeyDownCapture={onKeyDown}
        >
            <AdvancedDropdown
                {...rest}
                ref={ref}
                menuIsOpen={isMenuOpen}
                value={selectedOptions}
                options={mappedOptions}
                size={size}
                searchable={searchable}
                clearable={clearable}
                fixedDropdownContainer={true}
                closeMenuOnSelect={!multiselect}
                valueRenderer={() => null}
                menuRenderer={menuRender}
                onOptionSelect={onOptionSelect}
                onClear={onClear}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                onBlur={onBlur}
            />
            {
                // AZ: we do it in such weird way because vibe remounts selected content render on each selected value change
                // and we use a lot of states in multiselect mode
                valuePortalTarget && createPortal(multiselect ?
                    <MondaySelectSlotMultiValue
                        isMenuOpen={isMenuOpen}
                        selectedOptions={selectedOptions}
                        onValueClick={onValueClick}
                        onOptionRemove={onOptionRemove}
                    /> :
                    <MondaySelectSlotSingleValue
                        isMenuOpen={isMenuOpen}
                        className={SELECTED_VALUE_ROOT_CLASS}
                        onClick={onValueClick}
                    >
                        {selectedOptions[0]?.label}
                    </MondaySelectSlotSingleValue>,
                    valuePortalTarget
                )
            }
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div<{ isMenuOpen: boolean; }>`
    .${ADVANCED_DROPDOWN_MENU_CLASS} {
        z-index: 100;
    }

    .${ADVANCED_DROPDOWN_SINGLE_VALUE_CLASS} {
        width: 0 !important;
        max-width: 0 !important;
    }

    [class$="-placeholder"] {
        z-index: 10;
    }

    :not(:has(input[value=""])) {
        .${SELECTED_VALUE_ROOT_CLASS}:not(:has(.${SELECTED_MULTI_VALUE_INLINE_CLASS})) {
            visibility: hidden;
        }

        .${SELECTED_MULTI_VALUE_INLINE_CLASS} {
            visibility: hidden;
        }
    }

    div[class$="control"] > div:first-child > div:not([class$="placeholder"]) > div {
        z-index: 10;
    }
`;

const MondaySelectSlotSingleValue = styled.div<{ isMenuOpen: boolean; }>`
    position: absolute;
    padding-left: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${p => p.isMenuOpen ?
        p.theme.color.foreground.text.secondary :
        p.theme.color.foreground.text.primary
    };
    user-select: none;
`;
