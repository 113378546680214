import { PopupSlotAlign, PopupSlotSide } from "@7pace/design";
import { Dialog } from "monday-ui-react-core";
import { DialogPosition } from "monday-ui-react-core/dist/types/constants";

import { MULTISELECT_OVERFLOW_POPUP_CLASS } from "../MondaySelectSlot/components/MondaySelectSlotMultiValue";


type MondayPositionKey = {
    side: PopupSlotSide;
    align: PopupSlotAlign;
};

const mondayPositions = new Map<MondayPositionKey, DialogPosition>([
    [{ side: PopupSlotSide.Top, align: PopupSlotAlign.Start }, Dialog.positions.TOP_START],
    [{ side: PopupSlotSide.Top, align: PopupSlotAlign.Center }, Dialog.positions.TOP],
    [{ side: PopupSlotSide.Top, align: PopupSlotAlign.End }, Dialog.positions.TOP_END],
    [{ side: PopupSlotSide.Right, align: PopupSlotAlign.Start }, Dialog.positions.RIGHT_START],
    [{ side: PopupSlotSide.Right, align: PopupSlotAlign.Center }, Dialog.positions.RIGHT],
    [{ side: PopupSlotSide.Right, align: PopupSlotAlign.End }, Dialog.positions.RIGHT_END],
    [{ side: PopupSlotSide.Bottom, align: PopupSlotAlign.Start }, Dialog.positions.BOTTOM_START],
    [{ side: PopupSlotSide.Bottom, align: PopupSlotAlign.Center }, Dialog.positions.BOTTOM],
    [{ side: PopupSlotSide.Bottom, align: PopupSlotAlign.End }, Dialog.positions.BOTTOM_END],
    [{ side: PopupSlotSide.Left, align: PopupSlotAlign.Start }, Dialog.positions.LEFT_START],
    [{ side: PopupSlotSide.Left, align: PopupSlotAlign.Center }, Dialog.positions.LEFT],
    [{ side: PopupSlotSide.Left, align: PopupSlotAlign.End }, Dialog.positions.LEFT_END],
]);

export const mapPopupPosition = (side: PopupSlotSide, align: PopupSlotAlign) => {
    const fixedSide = side ?? PopupSlotSide.Bottom;
    const fixedAlign = align ?? PopupSlotAlign.Start;

    for (const [key, value] of mondayPositions.entries()) {
        if (key.side === fixedSide && key.align === fixedAlign) {
            return value;
        }
    }

    return Dialog.positions.BOTTOM_START;
};

export const dialogModifiers = [
    {
        name: "preventOverflow",
        options: {
            rootBoundary: "viewport"
        }
    },
    {
        name: "flip",
        options: {
            fallbackPlacements: ["bottom", "bottom-start", "bottom-end", "top"],
            rootBoundary: "viewport",
        }
    }
];

export const allowClickOutsidePopup = (target: HTMLElement, ownUniqueClassName: string) => {
    return target.closest(`.${ownUniqueClassName}`) ||
        target.closest(".action-toast") ||
        target.closest(".advancedFilter_datePicker") ||
        // PJ: below conditions are for select slot with multiselect enabled
        target.closest(".mdn-select-menu") ||
        target.closest(`.${MULTISELECT_OVERFLOW_POPUP_CLASS}`);
};
