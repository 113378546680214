import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import RoutePath from "../../routing/RoutePath";
import { useValidateAccess } from "../AccessControl/hooks/useValidateAccess";
import { useMondayContext } from "../MondayContext";
import { REQUIRED_PERMISSIONS } from "../utils/constants";
import { useFeatureFlags } from "./useFeatureFlags";


export const useUserPickerEnabled = () => {
    const { context } = useMondayContext();
    const location = useLocation();

    const { rolesAndPermissionsEnabled } = useFeatureFlags();

    const { hasAccess } = useValidateAccess(REQUIRED_PERMISSIONS.WORKLOGS.MODIFY.OTHERS);

    return useMemo(() => ({
        userPickerEnabled: (rolesAndPermissionsEnabled ? hasAccess : context.user.isAdmin) &&
            (
                location.pathname.includes(RoutePath.AllTime) ||
                location.pathname.includes(RoutePath.ItemView)
            )
    }), [context.user.isAdmin, hasAccess, location.pathname, rolesAndPermissionsEnabled]);
};
