import { useCallback, useMemo, useRef, useState } from "react";
import Delete from "monday-ui-react-core/dist/icons/Delete";
import Edit from "monday-ui-react-core/dist/icons/Edit";

import RoutePath from "../../../../routing/RoutePath";
import { DEFAULT_LAYOUT_ID } from "../../../TimesExplorer/hooks/useTimesExplorerLayout";
import { TimesExplorerLayoutInfo } from "../../../TimesExplorer/types/TimesExplorerLayout";
import { MenuOption, TabsWithMenuProps } from "../../types/menuOption";
import { PageTabProps } from "../PageTab";
import { PreparedPageTabWithMenuProps } from "../PageTabWithMenu";
import { pagesArePreparedPageTabWithMenuProps } from "../utils/pages";


export type Status = "renaming" | "creating";

export type TabEvents = {
    onTabCreate?: (tabName: string) => TimesExplorerLayoutInfo;
    onTabDelete?: (id: string) => void;
    onTabRename?: (id: string, tabName: string) => void;
};

export const useCustomTabsManagement = (
    pages: PageTabProps[] | PreparedPageTabWithMenuProps[],
    { onTabCreate, onTabRename, onTabDelete }: TabEvents,
    tabsWithMenuProps?: TabsWithMenuProps
) => {
    const defaultTabId = tabsWithMenuProps?.defaultTabId || DEFAULT_LAYOUT_ID;
    const checkIsUnsaved = tabsWithMenuProps?.checkIsUnsaved;
    const [status, setStatus] = useState<Status | null>(null);
    const [newTabName, setNewTabName] = useState("");
    const [activeRenameTab, setActiveRenameTab] = useState<PreparedPageTabWithMenuProps | null>(null);
    const tabsInputRef = useRef<HTMLInputElement | null>(null);

    const isActiveTabBeingRenamed = useMemo(() => activeRenameTab?.id === (tabsWithMenuProps?.activeTab as PreparedPageTabWithMenuProps)?.id, [activeRenameTab?.id, tabsWithMenuProps?.activeTab]);

    const getTabByTabId = useCallback((tabId: string) => {
        if (!pagesArePreparedPageTabWithMenuProps(pages)) {
            return null;
        }

        return pages.find(x => x.id === tabId);
    }, [pages]);

    const handleTabRename = useCallback(() => {
        if (!activeRenameTab || !pagesArePreparedPageTabWithMenuProps(pages)) {
            return;
        }

        setStatus(null);
        setNewTabName("");
        setActiveRenameTab(null);

        if (newTabName === activeRenameTab.name || newTabName === "") {
            return;
        }

        onTabRename(activeRenameTab.id, newTabName.trim());
    }, [activeRenameTab, newTabName, onTabRename, pages]);

    const handleBeginRename = useCallback((tabId: string) => {
        const tab = getTabByTabId(tabId);

        setActiveRenameTab(tab);
        setNewTabName(tab.name);
        setStatus("renaming");
    }, [getTabByTabId]);

    const handleStartNewTabCreation = useCallback((event?: React.FocusEvent<HTMLElement>) => {
        event?.stopPropagation();
        event?.preventDefault();

        tabsInputRef.current?.focus();

        const { name, id } = onTabCreate("");
        setNewTabName(name);

        setActiveRenameTab({ name, id, path: RoutePath.TimesExplorer + "/" + id });
        setStatus("creating");
    }, [onTabCreate]);

    const menuOptions = useMemo((): MenuOption[] => {
        const checkMenuOptionShouldBeDisabled =
            (id: string) => !id || id === defaultTabId;

        return [
            {
                title: "Delete",
                onClick: onTabDelete,
                icon: Delete,
                checkIfShouldBeDisabled: checkMenuOptionShouldBeDisabled,
                disabledNotice: "Times Explorer view cannot be deleted"
            },
            {
                title: "Rename",
                onClick: handleBeginRename,
                icon: Edit,
                checkIfShouldBeDisabled: checkMenuOptionShouldBeDisabled,
                disabledNotice: "Times Explorer view cannot be renamed"
            }
        ];
    }, [onTabDelete, handleBeginRename, defaultTabId]);

    const isUnsaved = useMemo(() => checkIsUnsaved?.(), [checkIsUnsaved]);

    return {
        status,
        newTabName,
        setNewTabName,
        handleTabSave: handleTabRename,
        handleStartNewTabCreation,
        menuOptions,
        isUnsaved,
        activeRenameTab,
        tabsInputRef,
        isActiveTabBeingRenamed
    };
};
