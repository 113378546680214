import { useMemo } from "react";
import { Avatar } from "monday-ui-react-core";
import { AvatarSize } from "monday-ui-react-core/dist/types/components/Avatar/AvatarConstants";

import MondayUser from "../../../store/models/MondayUser";
import { useMondayAvatars } from "../../MondayContext/hooks/useMondayAvatars";


export type UserForAvatar = Pick<MondayUser, "externalId" | "name">;

type MondayUserAvatarProps = {
    user: UserForAvatar;
    size?: AvatarSize;
    customSize?: number;
    className?: string;
};

export const MondayUserAvatar: React.FC<MondayUserAvatarProps> = ({ user, size, customSize, className }) => {
    const { get } = useMondayAvatars();
    const url = useMemo(() => get(user?.externalId), [get, user?.externalId]);
    const type = url ? Avatar.types.IMG : Avatar.types.TEXT;

    const fallbackName = useMemo(() => {
        if (url) {
            return undefined;
        }

        if (!user?.name) {
            return "NA";
        }

        const parts = user.name.split(" ");
        if (parts.length === 1) {
            return parts[0][0].toUpperCase();
        }

        return parts[0][0].toUpperCase() + parts[1][0]?.toUpperCase();
    }, [url, user?.name]);

    return (
        <Avatar
            className={className}
            ariaLabel={user?.name}
            size={size || Avatar.sizes.SMALL}
            customSize={customSize}
            src={url}
            text={fallbackName}
            type={type}
            backgroundColor={Avatar.colors.DARK_BLUE}
            tabIndex={-1}
        />
    );
}; 
