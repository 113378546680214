import { useDateTime } from "@7pace/datetime";

import { License } from "../../ClientConfig/types";
import { LicenseBanner } from "./LicenseBanner";


type SeatsExceededBannerProps = {
    license: License;
    onClose: () => void;
};

export const SeatsExceededBanner: React.FC<SeatsExceededBannerProps> = ({ license, onClose }) => {
    const { formatDate } = useDateTime();

    return <LicenseBanner
        type={"negative"}
        bannerText={`Available seats exceeded by ${license.accountSeats - license.planSeats}. Add more to continue using 7pace after ${formatDate(license.internalExpirationDate)}.`}
        buttonText={"Upgrade"}
        onClose={onClose}
        messageType={"Seats Exceeded"}
        source={"Seats Exceeded Banner"}
    />;
}

