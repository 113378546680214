import { useFlags } from "launchdarkly-react-client-sdk";


export const useFeatureFlags = () => useFlags<{
    amplitudeEnabled: boolean;
    pendoEnabled: boolean;
    datadogEnabled: boolean;
    datadogConfig: {
        sessionReplaySampleRate: number;
        sessionSampleRate: number;
        traceSampleRate: number;
    };
    logRocketEnabled: boolean;
    mondayWebhooksEnabled: boolean;
    importFromMondayEnabled: boolean;
    mondayColumnsConnectorEnabled: boolean;
    improvedAuthorizationScreenEnabled: boolean;
    rolesAndPermissionsEnabled: boolean;
    commentSuggestionsEnabled: boolean;
}>();
