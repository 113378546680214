import { ButtonKind, SlotSize } from "@7pace/design";
import { Button } from "monday-ui-react-core";


export const mapButtonSlotKind = (kind: ButtonKind) => {
    switch (kind) {
        case ButtonKind.Primary: return Button.kinds.PRIMARY;
        case ButtonKind.Secondary: return Button.kinds.SECONDARY;
        case ButtonKind.Tertiary: return Button.kinds.TERTIARY;
    }
};

export const mapButtonSlotSize = (size: SlotSize) => {
    if (size == null) {
        return Button.sizes.SMALL;
    }

    switch (size) {
        case SlotSize.Small: return Button.sizes.XS;
        case SlotSize.Medium: return Button.sizes.SMALL;
        case SlotSize.Large: return Button.sizes.MEDIUM;
    }
};
