
import { useAppSelector } from "../../../store/hooks";
import { DEFAULT_LAYOUT_ID } from "../../TimesExplorer/hooks/useTimesExplorerLayout";
import { getTimesExplorerSelectedLayoutId } from "../../TimesExplorer/state/timesExplorerLayoutsReducer";


export const useTimesExplorerSelectedLayoutId = () => {
    const selectedLayoutId = useAppSelector(getTimesExplorerSelectedLayoutId);

    return selectedLayoutId || DEFAULT_LAYOUT_ID;
};
