import { FC, useMemo } from "react";
import { TextWithOverflow } from "@7pace/components";
import { SlotSize } from "@7pace/design";
import styled from "styled-components";

import { UserApi } from "../../../components/AddTimeComponent/plugins/UserPlugin/hooks/useUsersQuery";
import { MondayUserAvatar } from "../../../components/MondayPrimitives/MondayUserAvatar";
import { mapSlotSizeToUserIconSize } from "../utils";


type UserOptionValueProps = UserApi & {
    size: SlotSize;
    className?: string;
};

export const UserOptionValue: FC<UserOptionValueProps> = ({ className, size, ...user }) => {
    const userIconSize = useMemo(() => mapSlotSizeToUserIconSize(size), [size]);

    return (
        <UserOptionValueWrapper className={className}>
            <MondayUserAvatar user={user} customSize={userIconSize} />
            <DropdownOptionValue size={size}>{user.name}</DropdownOptionValue>
        </UserOptionValueWrapper>
    );
};

const UserOptionValueWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing.s};
    width: 100%;
    height: 100%;
`;

const DropdownOptionValue = styled(TextWithOverflow) <{ size: SlotSize; }>`
    width: 100%;
    font: ${p => p.size === SlotSize.Large ?
        p.theme.typography.body.bolder.s :
        p.theme.typography.body.regular.m
    };
`;
