import { useMemo } from "react";
import { getThemeTokenValue } from "@7pace/design";
import { useTheme } from "styled-components";

import { VirtualizedOption } from "../types";
import { VirtualizedPolyOptionProps } from "./useVirtualizedPolyOptions";


export type UseVirtualizedListBaseProps = {
    polyOptions: VirtualizedPolyOptionProps[];
    selectedOption: VirtualizedOption | null;
    optionHeight: number;
    maxMenuHeight: number;
};

export const useVirtualizedListBase = ({ polyOptions, selectedOption, optionHeight, maxMenuHeight }: UseVirtualizedListBaseProps) => {
    const theme = useTheme();
    const listPadding = useMemo(() => parseFloat(getThemeTokenValue(theme.spacing.s)), [theme.spacing.s]);

    const selectedIndex = useMemo(() => {
        return polyOptions.findIndex((cur) => cur.option && cur.option.value === selectedOption?.value);
    }, [polyOptions, selectedOption?.value]);

    const menuHeight = useMemo(() => {
        if (polyOptions.length === 0) {
            return 0;
        }

        const itemsHeight = polyOptions.length * optionHeight + listPadding * 2;
        return Math.min(itemsHeight, maxMenuHeight);
    }, [listPadding, maxMenuHeight, optionHeight, polyOptions.length]);

    return {
        selectedIndex,
        menuHeight
    };
};
