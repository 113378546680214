import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { DesignProvider } from "@7pace/design";
import styled from "styled-components";

import { appSlots } from "./common/slots/slots";
import router from "./routing/router";
import store, { storeContext } from "./store/store";
import { FixedDropdownContainer, FixedPopoverContainer } from "./styles/FixedDropdownContainer";
import GlobalStyle from "./styles/GlobalStyle";

import "monday-ui-react-core/dist/main.css";
import "@7pace/design/dist/themes/7pace-monday.css";


const StyledApp = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    min-width: 560px;
`;

const App: React.FC = () =>
    <Provider store={store} context={storeContext}>
        <StyledApp id="tooltips-container">
            <DesignProvider slots={appSlots}>
                <GlobalStyle />
                <RouterProvider router={router} />
                <FixedPopoverContainer />
                <FixedDropdownContainer />
            </DesignProvider>
        </StyledApp>
    </Provider>;

export default App;
