import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../../store/store";
import { MondayWorkLog } from "../../../workLogs/models/worklogs";


type AddTimeState = {
    workLog: MondayWorkLog;
};

const initialState = {
    workLog: null as MondayWorkLog
} as AddTimeState;

const addTimeReducer = createSlice({
    name: "addTime",
    initialState: initialState,
    reducers: {
        setAddTimeWorkLog: (state, action: PayloadAction<MondayWorkLog>) => {
            state.workLog = action.payload;
        },
    }
});

export const {
    setAddTimeWorkLog
} = addTimeReducer.actions;

//selectors
export const getAddTimeWorkLog = (store: RootState): MondayWorkLog => store.addTime.workLog;

export default addTimeReducer.reducer;
