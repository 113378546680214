import { FC, useEffect, useRef } from "react";
import { ToggleSlotProps } from "@7pace/design";
import { Toggle } from "monday-ui-react-core";


export const MondayToggleSlot: FC<ToggleSlotProps> = ({
    value,
    label,
    onLabel,
    offLabel,
    onToggle,
    className,
    tabIndex,
    "data-testid": dataTestId,
    ...rest
}) => {
    const ref = useRef<HTMLDivElement>();

    // GM: Monday Toggle doesn't allow setting tabIndex
    // ref comes to help
    useEffect(() => {
        if (!tabIndex) {
            return;
        }

        const toggle = ref.current?.querySelector("input[type=checkbox]") as HTMLInputElement;
        if (!toggle) {
            return;
        }

        toggle.tabIndex = tabIndex;
    }, [tabIndex]);

    // AZ: for some reason data-testid is ignored for Toggle trough prop
    return <div ref={ref} className={className} data-testid={dataTestId}>
        <Toggle
            {...rest}
            isSelected={value}
            isDefaultSelected={value}
            offOverrideText={label || offLabel || ""}
            onOverrideText={onLabel || ""}
            onChange={onToggle}
        />
    </div>;
};
