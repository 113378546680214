import { AlertBanner, AlertBannerButton, AlertBannerLink, AlertBannerText } from "monday-ui-react-core";
import styled, { css } from "styled-components";

import { SubscriptionBannerViewedProperties, SubscriptionPurchaseClickedProperties } from "../../Amplitude/ampli-autogen";
import { useViewAmplitude } from "../../Amplitude/hooks/useViewAmplitude";
import { LICENSE_LEARN_MORE_LINK } from "../constants";
import { useLicenseClick } from "../hooks/useLicenseClick";


type LicenseBannerProps = {
    type: "warning" | "negative";
    bannerText: string;
    buttonText: string;
    messageType: SubscriptionBannerViewedProperties["message_type"];
    source: SubscriptionPurchaseClickedProperties["source"];
    onClose: () => void;
};

export const LicenseBanner: React.FC<LicenseBannerProps> = ({ type, bannerText, buttonText, messageType, source, onClose }) => {
    const onClick = useLicenseClick(true, source);
    useViewAmplitude("subscriptionBannerViewed", {
        message_type: messageType
    });

    return <StyledAlertBanner
        backgroundColor={type as typeof AlertBanner.backgroundColors[keyof typeof AlertBanner.backgroundColors]}
        onClose={onClose}
        isWarning={type === "warning"}
    >
        <AlertBannerText text={bannerText} />
        <AlertBannerLink text="Learn more" href={LICENSE_LEARN_MORE_LINK} />
        <AlertBannerButton
            id="license-banner-button"
            onClick={onClick}
        >
            {buttonText}
        </AlertBannerButton>
    </StyledAlertBanner>;
};


const StyledAlertBanner = styled(AlertBanner) <{ isWarning: boolean; }>`
    // GM: There is no other way to change the color of font for action button and close buttons
    // and it looks ugly in warning state with default color
   ${p => p.isWarning
        // pz: fix linting warnings
        // eslint-disable-next-line @7pace/rules/no-non-system-product-color-values
        ? css`
            #license-banner-button>button {
                color: var(--text-color-fixed-dark) !important;
            }

            [class*="closeButtonWrapper"]>button {
                color: var(--text-color-fixed-dark) !important;
            }
        `
        : ""
    }
`;
