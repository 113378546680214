import { FC, memo } from "react";

import { useUsersQuery } from "../../../components/AddTimeComponent/plugins/UserPlugin/hooks/useUsersQuery";
import { useAdvancedDropdownMenuBase } from "../../../components/AdvancedDropdown/hooks/useAdvancedDropdownMenuBase";
import { AdvancedDropdownMenuProps } from "../../../components/AdvancedDropdown/types";
import { useVirtualizedListNavigation } from "../../../components/VirtualizedList/hooks/useVirtualizedListNavigation";
import { useVirtualizedPolyOptions } from "../../../components/VirtualizedList/hooks/useVirtualizedPolyOptions";
import { VirtualizedList } from "../../../components/VirtualizedList/VirtualizedList";
import { UserDropdownOption } from "../types";
import { UserPolyOptionVirtualized } from "./UserPolyOptionVirtualized";


export const UserPickerMenu: FC<AdvancedDropdownMenuProps<UserDropdownOption>> = memo(({
    dropdownId, options, selectedOption, maxMenuHeight, optionHeight, menuPosition, selectOption
}) => {
    const { isLoading } = useUsersQuery();

    const isNoResults = !isLoading && options.length === 0;

    const polyOptions = useVirtualizedPolyOptions({
        options: options,
        selectedOption,
        loading: isLoading,
        noOptions: isNoResults,
        optionHeight,
        onChange: selectOption
    });

    const baseMenuProps = useAdvancedDropdownMenuBase({
        dropdownId,
        menuPosition,
        polyOptions,
        selectedOption,
        optionHeight,
        maxMenuHeight
    });

    const navigationMenuProps = useVirtualizedListNavigation({
        polyOptions,
        selectOption
    });

    return (
        <VirtualizedList
            {...baseMenuProps}
            {...navigationMenuProps}
            polyOptions={polyOptions}
            virtualizedItem={UserPolyOptionVirtualized}
            data-testId={"atd-user-dropdown"}
        />
    );
});
