import { WorkLogModifiedProperties } from "@7pace/amplitude";


const amplitudeWorkLogPages: Record<string, WorkLogModifiedProperties["page"]> = {
    "monthly": "Monthly",
    "weekly": "Weekly",
    "te": "Times Explorer",
    "timesheet": "Timesheet",
    "item-view": "Item View"
};

export const getPageForAmplitudeEvent = (pathname: string): WorkLogModifiedProperties["page"] => {
    for (const path of Object.keys(amplitudeWorkLogPages)) {
        const pathRegex = new RegExp(`/${path}(/|$)`);

        if (pathRegex.test(pathname)) {
            return amplitudeWorkLogPages[path];
        }
    }
};
