import { useContext } from "react";

import { GraphQLClientContext } from "./GraphQLClientProvider";


const useGraphQLClient = () => {
    const { client } = useContext(GraphQLClientContext);
    return client;
};

export default useGraphQLClient;
