import { FC, MouseEvent, useId } from "react";
import { PopupSlotProps } from "@7pace/design";
import { stringUtils } from "@7pace/utilities";
import { Dialog, DialogContentContainer } from "monday-ui-react-core";
import styled from "styled-components";

import { FIXED_POPOVER_CONTAINER_ID } from "../../../styles/FixedDropdownContainer";
import { MondayButtonSlotBase } from "../MondayButtonSlot/MondayButtonSlotBase";
import { allowClickOutsidePopup, dialogModifiers, mapPopupPosition } from "./utils";


export const MondayPopupSlot: FC<PopupSlotProps> = ({
    isOpen,
    side,
    sideOffset,
    align,
    alignOffset,
    buttonProps,
    zIndex = 100,
    beak,
    children,
    cssSelectorsToIgnoreOnClickOutside,
    onOpenChange,
    ...defaults
}) => {
    const { onClick, className: buttonClassName, active, ...restButtonProps } = buttonProps;

    const id = useId();
    const ownUniqueClassName = `monday-dialog-button-${id.replaceAll(":", "")}`;
    const combinedButtonClassName = stringUtils.constructClasses(buttonClassName, ownUniqueClassName);

    const onClickInternal = (event: MouseEvent<HTMLButtonElement>) => {
        onClick?.(event);
        onOpenChange?.(!isOpen);
    };

    const onClickOutsideInternal = (event: MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLElement;

        if (!allowClickOutsidePopup(target, ownUniqueClassName) && (!cssSelectorsToIgnoreOnClickOutside || !target.closest(cssSelectorsToIgnoreOnClickOutside))) {
            onOpenChange?.(false);
        }
    };

    return (
        <Dialog
            open={isOpen}
            showTrigger={[]}
            hideTrigger={[]}
            modifiers={dialogModifiers}
            position={mapPopupPosition(side, align)}
            containerSelector={`#${FIXED_POPOVER_CONTAINER_ID}`}
            zIndex={zIndex}
            moveBy={{
                main: sideOffset,
                secondary: alignOffset
            }}
            content={
                <StyledDialogContentContainer {...defaults}>
                    {children}
                </StyledDialogContentContainer>
            }
            tooltip={beak}
            onClickOutside={onClickOutsideInternal}
        >
            <MondayButtonSlotBase
                {...restButtonProps}
                className={combinedButtonClassName}
                active={active ?? isOpen}
                onClick={onClickInternal}
            />
        </Dialog>
    );
};

const StyledDialogContentContainer = styled(DialogContentContainer)`
    border-radius: ${p => p.theme.radii.l};
    padding: 0;
    
    > div {
        border-radius: ${p => p.theme.radii.l};
    }
`;
