import { useCallback, useEffect, useRef } from "react";
import { VariableSizeList as List } from "react-window";


export const DATA_LIST_OPTION = "data-list-option";
export const DATA_LIST_OPTION_HOVERED = "data-list-option-hovered";
export const DATA_LIST_OPTION_SELECTED = "data-list-option-selected";

export enum AllowedKeyCode {
    Enter = "Enter",
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp"
}

export type HighlightDirection = "up" | "down";
export type GetNextIdFunc = (curElemId: number, direction: HighlightDirection) => { nextId: number; isOverflow: boolean; };
export type OnEnterSubmitFunc = (curElemId: number) => void;

export const useVirtualizedOptionHighlight = (
    container: HTMLElement,
    listRef: List,
    getNextId: GetNextIdFunc,
    onEnterSubmit?: OnEnterSubmitFunc
) => {
    const curElem = useRef<HTMLElement>(null);

    const changeHighlightedElem = useCallback((newHighlightedElem: Element) => {
        if (!newHighlightedElem || newHighlightedElem === curElem.current) {
            return;
        }

        newHighlightedElem.setAttribute(DATA_LIST_OPTION_HOVERED, "true");
        curElem.current?.removeAttribute(DATA_LIST_OPTION_HOVERED);
        curElem.current = newHighlightedElem as HTMLElement;
    }, []);

    useEffect(() => {
        if (!container) {
            return;
        }

        const onMouseMove = (e: MouseEvent) => {
            const elem = document.elementFromPoint(e.clientX, e.clientY);
            const closestElem = elem.closest(`[${DATA_LIST_OPTION}]`);
            if (!closestElem) {
                return;
            }

            changeHighlightedElem(closestElem);
        };

        container.addEventListener("mousemove", onMouseMove);
        return () => container.removeEventListener("mousemove", onMouseMove);
    }, [changeHighlightedElem, container]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            // AZ: saved curElem might be disconnected from DOM due to search filter or other reasons
            // remove it from ref 
            if (curElem.current && !curElem.current.isConnected) {
                curElem.current = null;
                return;
            }

            if (!AllowedKeyCode[event.code] || !container) {
                return;
            }

            let curElemIdRaw = curElem.current?.getAttribute(DATA_LIST_OPTION);
            if (curElemIdRaw == null) {
                const selectedElem = container.querySelector(`[${DATA_LIST_OPTION_SELECTED}]`);
                curElemIdRaw = selectedElem?.getAttribute(DATA_LIST_OPTION);
            }

            const curElemId = curElemIdRaw ? +curElemIdRaw : -1;

            switch (event.key) {
                case "Enter": {
                    if (curElemId !== -1) {
                        onEnterSubmit(curElemId);
                    }
                    break;
                }
                case "ArrowUp": {
                    performArrowUp(listRef, container, curElemId, getNextId, changeHighlightedElem);
                    break;
                }
                case "ArrowDown": {
                    performArrowDown(listRef, container, curElemId, getNextId, changeHighlightedElem);
                    break;
                }
            }
        };

        document.addEventListener("keydown", onKeyDown);
        return () => document.removeEventListener("keydown", onKeyDown);
    }, [changeHighlightedElem, container, getNextId, listRef, onEnterSubmit]);
};

const performArrowUp = (
    listRef: List,
    container: HTMLElement,
    curElemId: number,
    getNextId: GetNextIdFunc,
    changeHighlightedElem: (newHighlightedElem: Element) => void
) => {
    const { nextId, isOverflow } = getNextId(curElemId, "up");

    if (!isOverflow) {
        listRef.scrollToItem(nextId, "smart");
        const prevElem = container.querySelector(`[${DATA_LIST_OPTION}="${nextId}"]`);
        changeHighlightedElem(prevElem);
    } else {
        listRef.scrollToItem(nextId, "end");

        // AZ: perform dom operation only when items exist there (before next paint)
        requestAnimationFrame(() => {
            const nextFromBottom = container.querySelector(`[${DATA_LIST_OPTION}="${nextId}"]`);
            changeHighlightedElem(nextFromBottom);
        });
    }
};

const performArrowDown = (
    listRef: List,
    container: HTMLElement,
    curElemId: number,
    getNextId: GetNextIdFunc,
    changeHighlightedElem: (newHighlightedElem: Element) => void
) => {
    const { nextId, isOverflow } = getNextId(curElemId, "down");

    if (!isOverflow) {
        listRef.scrollToItem(nextId, "smart");
        const nextElem = container.querySelector(`[${DATA_LIST_OPTION}="${nextId}"]`);
        changeHighlightedElem(nextElem);
    } else {
        listRef.scrollToItem(nextId, "start");

        // AZ: perform dom operation only when items exist there (before next paint)
        requestAnimationFrame(() => {
            const nextFromTop = container.querySelector(`[${DATA_LIST_OPTION}="${nextId}"]`);
            changeHighlightedElem(nextFromTop);
        });
    }
};
