import { TimesExplorerLayout } from "@7pace/times-explorer";


export type TimesExplorerLayoutInfo = {
    id: string;
    name?: string;
};

export type MondayTimesExplorerLayout = TimesExplorerLayout & {
    layoutId: string;
    selected: boolean;
    name: string;
    order: number;
};

export type MondayTimesExplorerLayouts = Record<string, MondayTimesExplorerLayout>;

export type TimesExplorerLayoutEventDetail = {
    changeMode: TimesExplorerLayoutChangeModes;
    layout: TimesExplorerLayoutInfo;

};

export enum TimesExplorerLayoutChangeModes {
    SWITCH = "SWITCH",
    CREATE = "CREATE",
    DELETE = "DELETE",
    RENAME = "RENAME",
    SAVE = "SAVE"
}
