import { useCallback, useEffect, useRef, useState } from "react";



export type WidthCalculations = {
    tabWidths: Record<string, number>;
    tabsInputWidth: number;
    moreTabsDropdownWidth: number;
    tabCreateWidth: number;
    fallbackTabWidth: number;
};

export const useCustomTabsWidthCalculations = () => {
    const tabsWrapperRef = useRef<HTMLDivElement>(null);
    const hiddenDivRef = useRef<HTMLDivElement>(null);
    const [hiddenTabsRenderCount, setHiddenTabsRenderCount] = useState<number>(-1);
    const [maxAllowedWidth, setMaxAllowedWidth] = useState<number>(0);
    const [widthCalculations, setWidthCalculations] = useState<WidthCalculations>({
        tabWidths: {},
        tabsInputWidth: 0,
        moreTabsDropdownWidth: 0,
        tabCreateWidth: 0,
        fallbackTabWidth: 0
    });

    useEffect(() => {
        const getTrueWidth = (element: HTMLElement | null): number => {
            if (!element) {
                return 0;
            }

            const style = window.getComputedStyle(element);
            const width = element.offsetWidth;
            const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);

            return width + margin;
        };

        const calculateWidths = (hiddenDiv: HTMLDivElement): WidthCalculations => {
            const tabElements = Array.from(hiddenDiv.children?.[0]?.children?.[0]?.children || []);

            const tabWidths = tabElements.reduce((acc, tab: HTMLElement) => {
                return {
                    ...acc,
                    [tab.dataset.tabPath]: getTrueWidth(tab)
                };
            }, {});

            const tabsInputElement = hiddenDiv.children?.[1] as HTMLElement;
            const tabsInputWidth = getTrueWidth(tabsInputElement);

            const moreTabsDropdownElement = hiddenDiv.children?.[2] as HTMLElement;
            const moreTabsDropdownWidth = getTrueWidth(moreTabsDropdownElement);

            const tabCreateElement = hiddenDiv.children?.[3] as HTMLElement;
            const tabCreateWidth = getTrueWidth(tabCreateElement);

            const fallbackTabElement = hiddenDiv.children?.[4] as HTMLElement;
            const fallbackTabWidth = getTrueWidth(fallbackTabElement);

            return {
                tabWidths,
                tabsInputWidth,
                moreTabsDropdownWidth,
                tabCreateWidth,
                fallbackTabWidth
            };
        };

        const hiddenDiv = hiddenDivRef.current;

        if (!hiddenDiv) {
            return;
        }

        setWidthCalculations(calculateWidths(hiddenDiv));
    }, [hiddenTabsRenderCount]);

    useEffect(() => {
        const calculateMaxAllowedWidth = () => {
            const tabsWrapperWidth = tabsWrapperRef.current?.offsetWidth || 0;

            setMaxAllowedWidth(tabsWrapperWidth);
        };

        window.addEventListener("resize", calculateMaxAllowedWidth);
        calculateMaxAllowedWidth();

        return () => {
            window.removeEventListener("resize", calculateMaxAllowedWidth);
        };
    }, [hiddenTabsRenderCount]);

    const incrementHiddenTabsRenderCount = useCallback(() => {
        setHiddenTabsRenderCount(currentHiddenTabsRender => currentHiddenTabsRender + 1);
    }, []);

    return {
        tabsWrapperRef,
        hiddenDivRef,
        widthCalculations,
        maxAllowedWidth,
        incrementHiddenTabsRenderCount
    };
};
