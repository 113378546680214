import { ClientConfig } from "./types";


export const clientConfigMock: ClientConfig = {
    account: {
        analytics: undefined,
        "uninstallation-state": undefined,
        license: undefined
    }
};
