import { useCallback, useLayoutEffect, useRef } from "react";
import { VariableSizeList as List } from "react-window";


export const useVirtualizedScrollToSelectedIndex = (selectedIndex: number) => {
    const listRef = useRef<List>();
    const isScrolledToSelected = useRef(false);

    const scrollToSelected = useCallback(() => {
        if (selectedIndex === -1 || !listRef.current || isScrolledToSelected.current) {
            return;
        }

        listRef.current?.scrollToItem(selectedIndex, "center");
        isScrolledToSelected.current = true;
    }, [selectedIndex]);

    useLayoutEffect(() => {
        scrollToSelected();
    }, [scrollToSelected]);

    const onSetListRef = useCallback((ref: List) => {
        listRef.current = ref;
        scrollToSelected();
    }, [scrollToSelected]);

    return { listRef, onSetListRef };
};
