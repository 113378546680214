import { FC, memo, useMemo } from "react";
import { SlotSize } from "@7pace/design";

import { VirtualizedOption } from "../../VirtualizedList/types";
import { useAdvancedDropdownMenuSelection } from "../hooks/useAdvancedDropdownMenuSelection";
import { AdvancedDropdownMenuAdapterProps } from "../types";
import { mapAdvancedDropdownOptionHeight } from "../utils/mapAdvancedDropdownOptionHeight";


export const AdvancedDropdownMenuAdapter: FC<AdvancedDropdownMenuAdapterProps<VirtualizedOption>> = memo(({
    size = SlotSize.Medium,
    selectProps,
    getValue,
    setValue,
    selectOption,
    menuRenderer,
    ...rest
}) => {
    const optionHeight = useMemo(() => mapAdvancedDropdownOptionHeight(size), [size]);

    const { selectedOption, onSelectOption } = useAdvancedDropdownMenuSelection({
        getValue,
        setValue,
        selectOption
    });

    return menuRenderer({
        ...rest,
        dropdownId: selectProps.id,
        selectedOption,
        optionHeight,
        selectOption: onSelectOption
    });
});
