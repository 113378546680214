import { useCallback, useLayoutEffect, useState } from "react";

import { useMondayStorage } from "../MondayContext";
import { convertValue } from "../utils/convertValue";


export const useCacheStorage = <T>(key: string, storedInMonday: boolean = false, defaultValue?: T) => {
    const { setUserItem, getUserItem } = useMondayStorage();
    const [storedValue, setStoredValue] = useState<T | undefined>(defaultValue);
    const [hasBeenLoaded, setHasBeenLoaded] = useState(false);

    useLayoutEffect(() => {
        const cachedValue = localStorage.getItem(key);

        if (cachedValue !== null) {
            setStoredValue(convertValue(cachedValue));
        }

        if (storedInMonday) {
            getUserItem<T>(key).then((value) => {
                setHasBeenLoaded(true);
                if (!value) {
                    return;
                }

                setStoredValue(value);

                if (cachedValue !== value) {
                    localStorage.setItem(key, JSON.stringify(value));
                }
            });
        }
    }, [key, getUserItem, storedInMonday]);

    const storeValue = useCallback(async (value: T) => {
        localStorage.setItem(key, JSON.stringify(value));

        if (storedInMonday) {
            await setUserItem(key, value);
        }
    }, [key, setUserItem, storedInMonday]);

    return [storedValue, storeValue, hasBeenLoaded] as const;
};
