import { SlotSize } from "@7pace/design";


export const ADVANCED_MEDIUM_OPTION_HEIGHT = 32;
export const ADVANCED_LARGE_OPTION_HEIGHT = 40;

export const mapAdvancedDropdownOptionHeight = (size: SlotSize) => {
    switch (size) {
        case SlotSize.Small:
        case SlotSize.Medium:
            return ADVANCED_MEDIUM_OPTION_HEIGHT;
        case SlotSize.Large:
        default:
            return ADVANCED_LARGE_OPTION_HEIGHT;
    }
};
