import { FC } from "react";
import { Label } from "monday-ui-react-core";
import styled from "styled-components";


export const BetaTag: FC = () => {
    return <StyledLabel text="Beta" color={Label.colors.PRIMARY} kind={Label.kinds.LINE} />;
};

const StyledLabel = styled(Label)`
    height: ${p => p.theme.size.s};
    border-color: ${p => p.theme.color.background.success.primary};
`;
