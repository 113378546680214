import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroupSlotProps } from "@7pace/design";
import { ButtonGroup } from "monday-ui-react-core";
import styled, { css } from "styled-components";

import { mapButtonGroupSlotSize } from "./utils";


export const MondayButtonGroupSlot: FC<ButtonGroupSlotProps> = ({
    items,
    selected,
    size,
    onClick,
    tabIndex,
    prefix,
    selectable = true,
    prefixVisible = true,
    ...commonProps
}) => {
    const ref = useRef<HTMLDivElement>();
    const [value, setValue] = useState(selected);

    const dataTestId = commonProps["data-testid"];

    useEffect(() => {
        setValue(selected);
    }, [selected]);

    const onValueChange = useCallback((changedValue: string) => {
        const input = selectable && changedValue === value ? null : changedValue;

        setValue(input);
        onClick?.(input);
    }, [onClick, value, selectable]);

    useEffect(() => {
        if (!tabIndex) {
            return;
        }

        // GM: Set correct tabIndexes for buttons if base tab index
        // is more than 0
        const buttons = ref.current?.querySelectorAll("button");

        for (let i = 0; i < buttons.length; i++) {
            const btn = buttons[i];
            btn.tabIndex = tabIndex + i;
            if (dataTestId) {
                btn.setAttribute("data-testid", `${dataTestId}-${i}`);
            }
        }
    }, [dataTestId, tabIndex]);

    return (
        <ButtonGroupContainer
            selectable={selectable}
            prefix={prefix}
            prefixVisible={prefixVisible}
        >
            <ButtonGroup
                {...commonProps}
                ref={ref}
                kind={ButtonGroup.kinds.SECONDARY}
                onSelect={onValueChange}
                options={items}
                size={mapButtonGroupSlotSize(size)}
                value={selectable && value}
            />
        </ButtonGroupContainer>
    );
};

type ButtonGroupContainerProps = {
    selectable: boolean;
    prefix: string;
    prefixVisible: boolean;
};

const HALF = 0.5;

const ButtonGroupContainer = styled.div<ButtonGroupContainerProps>`
    [class*=optionText_]:not([class*=disabled_])[class*=selected_],
    [class*=buttonsContainer] [class*=button_][class*=activeButton_] {
        z-index: unset;

        &:focus-visible {
            z-index: 1;
        }
    }

    button > span {
        display: none;
    }

    // CM: Paddings, to ensure the text will be adjusted properly, if a prefix is provided
    // when changing this css, please make to check all cases, where a prefix is used
    ${p => p.prefix && css<ButtonGroupContainerProps>`
        button[class*=sizeSmall_] {
            padding: ${p => p.theme.spacing.s} ${p => p.theme.spacing.m}; 
        }

        ${p => !p.prefixVisible && css<ButtonGroupContainerProps>`
            button[class*=optionText_] {
                padding-left: calc(${p => p.theme.spacing.m} + ${p => p.prefix.length * HALF}ch);
                padding-right: calc(${p => p.theme.spacing.m} + ${p => p.prefix.length * HALF}ch);
            }
        `}
        
        ${p => p.prefixVisible && css<ButtonGroupContainerProps>`
            button[class*=optionText_] {
                padding-left: calc(${p => p.theme.spacing.m} + ${p => p.prefix.length}ch);

                &::before {
                    content: "";
                    display: list-item;
                    list-style: "${p => p.prefix}";
                }
            }
        `}
    `}

    /* disable selected styles */
    ${p => !p.selectable && css`
        button[class*=optionText_][class*=selected_] {
            background-color: transparent;
            color: inherit;
            border-color: ${p => p.theme.color.border.input.rest};

            &:hover {
                background-color: ${p => p.theme.color.background.accent.tertiary.hover};
            }
        }`
    }

    .bulk-edit-quick-time button {
        flex: 1;
    }
`;
