import { SlotSize } from "@7pace/design";
import { Loader } from "monday-ui-react-core";
import type { LoaderSize } from "monday-ui-react-core/dist/types/components/Loader/LoaderConstants";


export const mapLoaderSlotSize = (size: SlotSize): LoaderSize => {
    switch (size) {
        case SlotSize.Large:
            return Loader.sizes.LARGE;
        case SlotSize.Medium:
            return Loader.sizes.MEDIUM;
        case SlotSize.Small:
        default:
            return Loader.sizes.SMALL;
    }
};
