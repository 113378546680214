import { SlotSize } from "@7pace/design";
import { css } from "styled-components";


// TODO: replace with more authentic values in the end
export const ADVANCED_DROPDOWN_SINGLE_VALUE_CLASS = "mdn-select-single-value";
export const ADVANCED_DROPDOWN_CONTAINER_CLASS = "monday-dropdown";
export const ADVANCED_DROPDOWN_MENU_CLASS = "mdn-select-menu";

const placeholder = css<{ size: SlotSize; }>`
    ${p => {
        if (p.size === SlotSize.Small || p.size === SlotSize.Medium) {
            return css`
                        margin-left: 8px !important;
                        font: ${p.theme.typography.body.regular.m};
                    `;
        } else if (p.size === SlotSize.Large) {
            return css`
                        margin-left: 14px !important;
                        font: ${p.theme.typography.body.bolder.s};
                    `;
        }
    }}
`;

const selectBackground = css<{ disabled: boolean; }>`
    background-color: ${p => p.disabled ?
        p.theme.color.background.surface.disabled :
        p.theme.color.background.surface.primary
    };
`;

// AZ in monday-ui-react-core v2.102.0 dropdown's background is changed for dark theme
// Alex said that we want to keep it how it was before
export const baseAdvancedDropdownStyles = css<{ disabled: boolean; }>`
    width: 100%;

    div[class$="control"] {
        > div:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            ${selectBackground}

            > div:not([class$="placeholder"]) {
                ${selectBackground}
            }
        }

        > div:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            ${selectBackground}
        }
    }


    div[class$="placeholder"] + div {
         ${selectBackground}
    }

    .${ADVANCED_DROPDOWN_SINGLE_VALUE_CLASS} {
        width: 100%;
        ${selectBackground}

        + div {
            ${selectBackground}
        }
    }

    [class*="placeholder"] {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
    }
`;

export const baseAdvancedDropdownContainerStyles = css<{ size: SlotSize; }>`
    [class^=dropdownMenuWrapper] {
        left: -1px;
        width: calc(100% + 2px);
        margin-top: 3px;
        border-radius: ${p => p.theme.radii.l};
        box-shadow: ${p => p.theme.shadow.m};
        background-color: ${p => p.theme.color.background.surface.secondary};
        // GM: Name of the original animation is autogenerated
        // So we need to set our own here
        animation-name: fadeIn;
        z-index: 3;
    }

    // GM: Redefine animation for monday dropdown
    // Originally, z-index is -10000 for 0%
    // which causes nasty flickering during animation
    @keyframes fadeIn {
        0% {
            opacity: 0;
            z-index: 999;
            transform: translateY(var(--dropdown-menu-fadinY, -75px));
        }
        100% {
            opacity: 1;
            z-index: 999;
            transform: translateY(0px);
        }
    }

    .${ADVANCED_DROPDOWN_CONTAINER_CLASS} {
        /* This style is used to remove free space in dropdown when input is disabled */
        input:disabled {
            margin-left: 0 !important;
        }

        /* This style is used for adding space to text when searching in the items input  */
        input:enabled {
            margin-left: ${p => p.size === SlotSize.Small || p.size === SlotSize.Medium ? "8px" : "14px"} !important;
        }

        /* These styles are used to remove padding and margins from a dropdown using a regular expression to search for className*/
        [class^=" css-"]:first-child {
            margin: 0 !important;
            padding: 0 !important;

            [class$="-placeholder"] {
                ${placeholder}
            }

            [class$="-singleValue"] {
                width: 100%;
                max-width: 100%;
                margin: 0;
                padding: 0;
            }

            [class^="components-Dropdown-components-singleValue-singleValue-module"] {
                width: 100% !important;
                padding-inline: 0 !important;
            }
        }
    }
`;
