import { FC, useEffect, useState } from "react";
import { TextWithOverflow } from "@7pace/components";
import { CheckboxSlotProps } from "@7pace/design";
import { Checkbox } from "monday-ui-react-core";
import styled from "styled-components";


export const MondayCheckboxSlot: FC<CheckboxSlotProps> = ({ checked, onChange, label, ...rest }) => {
    const [checkedInternal, setCheckedInternal] = useState(checked);

    useEffect(() => {
        setCheckedInternal(checked);
    }, [checked]);

    const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedInternal(event.target.checked);
        onChange?.(event.target.checked);
    };

    return (
        <CheckboxWrapper
            checked={checkedInternal}
            onChange={onChangeInternal}
            label={<TextWithOverflow tooltipContent={label}>{label}</TextWithOverflow>}
            {...rest}
        />
    );
};

const CheckboxWrapper = styled(Checkbox)`
    width: 100%;
    overflow: hidden;

    >span {
        width: 100%;
    }

    div[class^="checkbox"] {
        flex-shrink: 0;
    }
`;
