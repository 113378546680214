import { useContext } from "react";
import { AppFeatureTypes } from "monday-sdk-js/types/client-context.type";

import { MondayContextValue, MondayReactContext } from "../MondayContext";


export const useMondayContext = <AppFeatureType extends AppFeatureTypes = "Base">(): MondayContextValue<AppFeatureType> => {
    const context = useContext(MondayReactContext) as MondayContextValue<AppFeatureType>;
    return context;
};
