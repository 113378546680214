import { createContext } from "react";
import { AppFeatureContextMap, AppFeatureTypes } from "monday-sdk-js/types/client-context.type";

import MondayJwt from "./models/MondayJwt";


type MondayContext<AppFeatureType extends AppFeatureTypes = "Base"> =
    AppFeatureContextMap[AppFeatureType] & {
        // GM: Seems to exist in the context, but missing from types
        appFeature: {
            name: string;
            type: AppFeatureTypes;
        };
        // GM: It exists in all features we currently use, but may be missing from some features
        workspaceId?: number;
    };

export type MondayContextValue<AppFeatureType extends AppFeatureTypes = "Base"> = {
    context: MondayContext<AppFeatureType>;
    sessionToken: string;
    sessionData: MondayJwt["dat"];
    state: MondayContextState;
};

export type MondayContextState = "loading" | "ready" | "failed";

export const MondayReactContext = createContext<MondayContextValue>(null);
export default MondayContext;
