import { useCallback, useMemo } from "react";

import { VirtualizedListMenuProps } from "../types";
import { GetNextIdFunc, HighlightDirection } from "./useVirtualizedOptionHighlight";
import { POLY_OPTION_TYPES, VirtualizedPolyOptionProps } from "./useVirtualizedPolyOptions";


type VirtualizedListNavigationProps = Pick<VirtualizedListMenuProps, "selectOption"> & {
    polyOptions: VirtualizedPolyOptionProps[];
};

export const useVirtualizedListNavigation = ({
    polyOptions,
    selectOption
}: VirtualizedListNavigationProps) => {
    const getNextHighlightedId = useCallback((curId: number, direction: HighlightDirection): ReturnType<GetNextIdFunc> => {
        if (direction === "up") {
            const nextBackwardId = polyOptions.findLastIndex((cur, i) => i < curId && cur.type === POLY_OPTION_TYPES.option);
            if (nextBackwardId !== -1) {
                return { nextId: nextBackwardId, isOverflow: false };
            }

            const firstIdFromEnd = polyOptions.findLastIndex((cur) => cur.type === POLY_OPTION_TYPES.option);
            return { nextId: firstIdFromEnd, isOverflow: true };
        }

        if (direction === "down") {
            const nextForwardId = polyOptions.findIndex((cur, i) => i > curId && cur.type === POLY_OPTION_TYPES.option);
            if (nextForwardId !== -1) {
                return { nextId: nextForwardId, isOverflow: false };
            }

            const firstIdFromStart = polyOptions.findIndex((cur) => cur.type === POLY_OPTION_TYPES.option);
            return { nextId: firstIdFromStart, isOverflow: true };
        }

        return { nextId: 0, isOverflow: false };
    }, [polyOptions]);

    const onEnterSubmit = useCallback((curElemId: number) => {
        const polyOption = polyOptions[curElemId];
        if (!polyOption?.option || polyOption?.type !== POLY_OPTION_TYPES.option) {
            return;
        }

        selectOption(polyOption.option);
    }, [polyOptions, selectOption]);

    return useMemo(() => ({
        getNextHighlightedId,
        onEnterSubmit,
    }), [getNextHighlightedId, onEnterSubmit]);
};
