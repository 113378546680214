import { SlotSize } from "@7pace/design";
import { ButtonGroup } from "monday-ui-react-core";


export const mapButtonGroupSlotSize = (size: SlotSize) => {
    if (size == null) {
        return ButtonGroup.sizes.SMALL;
    }

    switch (size) {
        case SlotSize.Small: return ButtonGroup.sizes.XS;
        case SlotSize.Medium: return ButtonGroup.sizes.SMALL;
        case SlotSize.Large: return ButtonGroup.sizes.MEDIUM;
    }
};
