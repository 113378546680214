import { SlotSize } from "@7pace/design";


export const mapAdvancedDropdownSlotSize = (size: SlotSize) => {
    switch (size) {
        case SlotSize.Small:
        case SlotSize.Medium:
            return "small";
        case SlotSize.Large:
        default:
            return "medium";
    }
};
