import React from "react";
import { ImageSlotProps } from "@7pace/design";

import { mondayImages } from "./mondayImages";


export const MondayImageSlot: React.FC<ImageSlotProps> = ({ type, ...props }) => {
    return <img
        src={mondayImages[type]}
        alt={""}
        loading={"lazy"}
        decoding={"async"}
        {...props}
    />;
};
