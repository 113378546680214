import { useCallback, useEffect } from "react";

import { TimesExplorerLayoutEventDetail } from "../types/TimesExplorerLayout";


const eventName = "layoutsChange";


export const useTimesExplorerLayoutsCustomEventListener = (callback: (detail: TimesExplorerLayoutEventDetail) => void) => {
    useEffect(() => {
        const eventListener = (event: CustomEvent<TimesExplorerLayoutEventDetail>) => {
            callback(event.detail);
        };

        document.addEventListener(eventName, eventListener);
        return () => {
            document.removeEventListener(eventName, eventListener);
        };
    }, [callback]);
};

export const useTimesExplorerLayoutsCustomEventDispatcher = () => {
    const dispatchCustomEvent = useCallback((detail: TimesExplorerLayoutEventDetail) => {
        const event = new CustomEvent<TimesExplorerLayoutEventDetail>(eventName, { detail });
        document.dispatchEvent(event);
    }, []);

    return dispatchCustomEvent;
}

