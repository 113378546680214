import { useCallback, useMemo } from "react";

import { getAuthToken, setAuthToken } from "../../store/app/appReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useMondayContext, useMondayStorage } from "../MondayContext";


const TOKEN_STORAGE_KEY = "internal-auth-token";

const useAuthToken = () => {
    const dispatch = useAppDispatch();
    const { sessionData } = useMondayContext();
    const token = useAppSelector(getAuthToken);
    const { getUserItem, setUserItem, deleteUserItem } = useMondayStorage();

    const key = useMemo(() => `${TOKEN_STORAGE_KEY}${sessionData.install_id}`, [sessionData?.install_id]);
    const getTokenFromStorage = useCallback(() => getUserItem<string>(key), [getUserItem, key]);
    const setTokenToStorage = useCallback((token: string) => setUserItem<string>(key, token), [key, setUserItem]);

    const setToken = useCallback((token: string, updateStorage?: boolean) => {
        dispatch(setAuthToken(token));

        if (updateStorage) {
            setTokenToStorage(token);
        }
    }, [dispatch, setTokenToStorage]);

    const resetToken = useCallback(async () => {
        await deleteUserItem(key);
        dispatch(setAuthToken(null));
    }, [deleteUserItem, dispatch, key]);

    return {
        token,
        resetToken,
        setToken,
        getTokenFromStorage
    };
};

export default useAuthToken;
