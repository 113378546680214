import { FC } from "react";
import { Menu, MenuButton, MenuItem } from "monday-ui-react-core";

import { Z_INDEX_DIALOG_TOOLTIP } from "./utils/constants";
import { MenuOption } from "../types/menuOption";
import { MENU_ICON_ELEMENT_ID } from "./PageTabWithMenu";



type TabMenuButtonProps = {
    isMenuOpen: boolean;
    onMenuShow: () => void;
    onMenuHide: () => void;
    tabId: string;
    menuOptions: MenuOption[];
    className?: string;
};

export const TabMenuButton: FC<TabMenuButtonProps> = ({ isMenuOpen, onMenuHide, onMenuShow, menuOptions, tabId, className }) => {
    const renderMenuItem = ({
        title,
        icon,
        onClick,
        checkIfShouldBeDisabled,
        disabledNotice
    }: MenuOption) => {
        const disabled = checkIfShouldBeDisabled?.(tabId);

        return (
            <MenuItem
                key={`${tabId}-${title}`}
                icon={icon}
                onClick={() => {
                    onClick(tabId);
                }}
                title={title}
                disabled={disabled}
                disableReason={disabledNotice}
                tooltipProps={{ zIndex: Z_INDEX_DIALOG_TOOLTIP }}
            />
        );
    };

    return <MenuButton
        id={MENU_ICON_ELEMENT_ID}
        size={MenuButton.sizes.XS}
        closeMenuOnItemClick={true}
        onMenuHide={onMenuHide}
        onMenuShow={onMenuShow}
        open={isMenuOpen}
        className={className}
    >
        <Menu key={tabId} id="menu" size="medium">
            {menuOptions.map(renderMenuItem)}
        </Menu>
    </MenuButton>;
};
