import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store/store";
import { MondayAccountTier } from "../models/mondayAccount";
import { MondayUserAvatar } from "../models/MondayUserAvatar";


type ContextState = {
    firstDayOfWeek: number;
    userIds: number[];
    avatars: MondayUserAvatar[];
    accountTier: MondayAccountTier;
};

const initialState = {
    firstDayOfWeek: 0,
    userIds: [],
    avatars: []
} as ContextState;


export const contextSlice = createSlice({
    name: "context",
    initialState,
    reducers: {
        setFirstDayOfWeek: (state, action: PayloadAction<number>) => {
            state.firstDayOfWeek = action.payload;
        },
        setUserIds: (state, action: PayloadAction<number[]>) => {
            state.userIds = action.payload;
        },
        setUserAvatars: (state, action: PayloadAction<MondayUserAvatar[]>) => {
            state.avatars = [...state.avatars, ...action.payload];
        },
        setAccountTier: (state, action: PayloadAction<MondayAccountTier>) => {
            state.accountTier = action.payload;
        }
    }
});

export const {
    setFirstDayOfWeek,
    setUserIds,
    setUserAvatars,
    setAccountTier
} = contextSlice.actions;


// Selectors
export const getFirstDayOfWeek = (state: RootState) => state.context.firstDayOfWeek;
export const getUserAvatars = (state: RootState) => state.context.avatars;
export const getUserIds = (state: RootState) => state.context.userIds;
export const getAccountTier = (state: RootState) => state.context.accountTier;

export default contextSlice.reducer;
