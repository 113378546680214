import { PERMISSIONS } from "@7pace/permissions";


export const NO_RESULTS_LABEL = "No results";
export const TOAST_TOP_OFFSET = 0;

export const REQUIRED_PERMISSIONS = {
    VIEW_PAGE: {
        MAIN: [PERMISSIONS.HAS_ACCESS],
        MY_TIME: [PERMISSIONS.MANAGE_OWN_TIME],
        TIMES_EXPLORER: [
            [PERMISSIONS.MANAGE_OWN_TIME],
            [PERMISSIONS.SEE_TIME_OF_OTHERS]
        ],
        ITEM_VIEW: [
            [PERMISSIONS.MANAGE_OWN_TIME],
            [PERMISSIONS.SEE_TIME_OF_OTHERS]
        ],
        SETTINGS: [PERMISSIONS.CHANGE_SETTINGS]
    },

    WORKLOGS: {
        ASSIGN: PERMISSIONS.MANAGE_TIME_OF_OTHERS,
        ADD: [[PERMISSIONS.MANAGE_OWN_TIME], [PERMISSIONS.MANAGE_TIME_OF_OTHERS]],
        MODIFY: {
            OWN: PERMISSIONS.MANAGE_OWN_TIME,
            OTHERS: PERMISSIONS.MANAGE_TIME_OF_OTHERS
        }
    }
};
