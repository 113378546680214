import { FC } from "react";
import { TextWithOverflow } from "@7pace/components";
import styled from "styled-components";

import { VirtualizedPolyOptionProps } from "../../hooks/useVirtualizedPolyOptions";
import { VirtualizedOptionWrapper } from "../../styles";
import { VirtualizedOptionProps } from "../../types";


export const VirtualizedPolyOption: FC<VirtualizedPolyOptionProps & VirtualizedOptionProps> = ({ virtualizedStyle, index, ...props }) => {
    const { label, selected, onClick } = props.option;

    const onClickInternal = () => {
        onClick?.(props);
    };

    return (
        <AdvancedOptionWrapperStyled
            data-list-option={index}
            data-list-option-selected={selected || undefined}
            style={virtualizedStyle}
            onClick={onClickInternal}
        >
            <OptionLabel>{label}</OptionLabel>
        </AdvancedOptionWrapperStyled>
    );
};

const AdvancedOptionWrapperStyled = styled(VirtualizedOptionWrapper)`
    padding: 0;

    .mdy-tooltip {
        display: flex;
        align-items: center;
        justify-content: stretch;

        width: 100%;
        height: 100%;

        padding: ${p => p.theme.spacing.s};

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
    }
`;

const OptionLabel = styled(TextWithOverflow)`
    width: unset;
    height: 100%;
    padding-top: 1px; // AZ: optical illusion, seems like text is not centered vertically without this
`;
