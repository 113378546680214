import { gql } from "graphql-request";


export const USERS_PAGE_SIZE = 1000;

export const usersSchema = (cursor: string, limit = USERS_PAGE_SIZE) => {
    return gql`
        query GetUsers {
            users(${cursor ? "after: \"" + cursor + "\", " : ""}first: ${limit}) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                nodes {
                    ${userSchemaResponse}
                }
            }
        }
    `;
};

export const userSchemaResponse = gql`
    id
    externalId
    name
    email
    role {
        id
        name
        type
    }
`;
