import { SlotSize } from "@7pace/design";


export const mapSlotSizeToUserIconSize = (slotSize: SlotSize) => {
    switch (slotSize) {
        case SlotSize.Small: return 18;
        case SlotSize.Medium: return 22;
        case SlotSize.Large: return 26;
    }
};
