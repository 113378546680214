import { INTERACTION_TOOLTIP_CLASS } from "@7pace/components";
import { createGlobalStyle } from "styled-components";


// pz: fix linting warnings
// eslint-disable-next-line @7pace/rules/no-non-system-product-color-values
const GlobalStyle = createGlobalStyle`
    :root {
        --font-family: Figtree, Roboto, Rubik, Noto Kufi Arabic, Noto Sans JP, sans-serif;
        --title-font-family: Poppins, Roboto, Rubik, Noto Kufi Arabic, Noto Sans JP, sans-serif;
    }

    * {
        box-sizing: border-box;

        &:not(.fabric-icon):not(.las):not(i):not(em) {
            font-family: var(--font-family);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    html, body, #root {
        width: 100%;
        height: 100%;
    }

    body {
        margin: 0;

        &.dragging * {
            cursor: grabbing !important;
        }
    }

    // GM: Container for Monday tooltips. This makes them visible above Monday native Modal components,
    // otherwise it is hidden underneath. Thus, this is a bug in Monday ui library.
    .monday-style-dialog-content-wrapper {
        z-index: 100000001;

        // AZ: for some reason it can't position itself correctly for interactions buttons 
        :has(.${INTERACTION_TOOLTIP_CLASS}) {
           margin-bottom: 13px;
        }
    }

    // AZ: temporary fix for monday dropdowns focused style
    .monday-dropdown {
        :focus-within { 
            border-color: ${p => p.theme.color.border.input.active} !important;

            [class$="control"] {
                box-shadow: 0 0 0 1px ${p => p.theme.color.border.input.active} !important;
            }
        }
    }

    // AZ: save RolesMapDropdown shadows
    .edit-role-modal {
        padding-inline: 0;

        > div {
            padding-inline: ${p => p.theme.spacing.xl};
        }
    }
    
    // VV: ClassName to change height of AddComponent
    .add-time-dialog-content {
        width: 504px !important;
        min-height: min(544px, 90vh);
        padding: 0;
        overflow-x: auto;

        .add-time-dialog {
            min-width: 440px;
            height: auto;
            padding: ${p => p.theme.spacing.m} ${p => p.theme.spacing.xl} ${p => p.theme.spacing.l} ${p => p.theme.spacing.xl};
        }

        >div:first-child {
            [class^="closeButton"] {
                z-index: 10;
            }
        }

        // AZ: we did paddings in such weird way to save dropdowns shadows
        #add-time-dialog-title {
            padding: ${p => p.theme.spacing.l} 0 0 ${p => p.theme.spacing.xl};
        }

        >div:last-child {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    // PJ: Assure that ATD is always above other modals
    #add-time-dialog {
        z-index: 99999 !important;
    }

    [aria-modal="true"][role="dialog"] {
        [role="document"] {
            >div:first-child {
                margin: 0;

                h1 {
                    font: ${p => p.theme.typography.title.m};
                    color: ${p => p.theme.color.foreground.text.primary};
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    letter-spacing: -.2px;
                }
            }
        }
    }

    .weekly-card-tooltip-content-container {
        max-width: 360px;

        // ensures that the tooltip content is rendered above the beak,
        // and the beak does not cover the content
        position: relative;
        z-index: 1;

        background: ${p => p.theme.color.background.surface.contrastInverted};
    }
`;

export default GlobalStyle;
