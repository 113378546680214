import { FC } from "react";
import { DefaultIconSlot, IconSlotProps } from "@7pace/design";

import { mondayIcons } from "./mondayIcons";
import { mapIconSlotSize } from "./utils";


export const MondayIconSlot: FC<IconSlotProps> = ({ size, type, ...rest }) => {
    if (type == null) {
        return null;
    }

    const Icon = mondayIcons[type];
    const pxSize = mapIconSlotSize(size);

    return Icon ?
        <Icon width={pxSize} height={pxSize} {...rest} /> :
        <DefaultIconSlot size={size} type={type} {...rest} />;
};
