enum RoutePath {
    ItemView = "item-view",
    ObjectView = "object-view",
    MyTime = "my-time",
    AllTime = "all-time",
    Monthly = "monthly",
    Weekly = "weekly",
    Timesheet = "timesheet",
    TimesExplorer = "te",
    OAuthFinished = "oauth/finished",
    Widget = "widget",
    TotalTime = "total-time",
    ActualVsPlanned = "time-estimations",
    TimeInsightReportWidget = "time-column-report",
    Settings = "settings",
    FieldsSettings = "fields",
    ColumnsConnectorSettings = "columns-connector",
    RolesAndPermissionsSettings = "roles-and-permissions",
    UsersSettings = "users",
}

export default RoutePath;
