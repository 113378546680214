import { useCallback } from "react";

import { InternalRole } from "../../../../../../features/Settings/types";
import { useAppDispatch } from "../../../../../../store/hooks";
import MondayUser from "../../../../../../store/models/MondayUser";
import useGraphQLRequest from "../../../../../graphql/useGraphQLRequest";
import { PagedQueryFn, usePagedQuery } from "../../../../../hooks/usePagedQuery";
import { useUserPickerEnabled } from "../../../../../hooks/useUserPickerEnabled";
import { useMondayAvatars } from "../../../../../MondayContext/hooks/useMondayAvatars";
import { setOverlayError } from "../../../../ErrorOverlay/state/errorOverlayReducer";
import { USERS_PAGE_SIZE, usersSchema } from "../schemas/usersSchema";


export enum UserRoleType {
    Mapped = "mapped",
    Assigned = "assigned"
}

export type UserApi = Pick<MondayUser, "id" | "name" | "email" | "externalId"> & {
    role?: Pick<InternalRole, "id" | "name"> & {
        type?: UserRoleType;
    };
};

type RawPagedQueryUsersMeta = { cursor: string; };

type UsersQueryRequestResult = {
    users: {
        pageInfo: {
            hasNextPage: boolean;
            endCursor: string;
        };
        nodes: UserApi[];
    };
};

export const useUsersQuery = (forceEnable?: boolean) => {
    const dispatch = useAppDispatch();
    const { requestAsync } = useGraphQLRequest();

    const { seed } = useMondayAvatars();

    const { userPickerEnabled } = useUserPickerEnabled();

    const queryNextChunk: PagedQueryFn<UserApi> = useCallback(async (meta: RawPagedQueryUsersMeta) => {
        const cursor = meta?.cursor ?? "";
        const response = await requestAsync<UsersQueryRequestResult, UsersQueryRequestResult>(usersSchema(cursor, USERS_PAGE_SIZE), (data) => data);
        const data = response.users.nodes ?? [];

        seed(data.map(user => user.externalId));

        return {
            data,
            allFetched: !response.users.pageInfo.hasNextPage,
            meta: {
                cursor: response.users.pageInfo.endCursor
            }
        };
    }, [requestAsync, seed]);

    const onError = useCallback(() => {
        dispatch(setOverlayError({ message: "Something went wrong during loading users" }));
    }, [dispatch]);

    return usePagedQuery({
        queryKey: ["getUsers"],
        enabled: forceEnable || userPickerEnabled,
        getNextChunk: queryNextChunk,
        onError: onError,
    });
};
