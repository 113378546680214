import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ButtonValue } from "monday-ui-react-core/dist/types/components/ButtonGroup/ButtonGroupConstants";

import { BoardDropdownOption } from "../../../../common/components/MondaySearch/components/BoardDropdown/models/boardDropdownOption";
import { PreloadedData } from "../../../../common/types/PreloadedData";
import { RootState } from "../../../../store/store";
import { ConnectedColumn, ConnectedColumnsInputBase, ConnectItemTypes, DropdownOption } from "../types/types";


export enum ColumnConnectorPages {
    Summary = "summary",
    Start = "start",
    Connect = "connect"
}

type ColumnConnectorState = {
    columns: PreloadedData<DropdownOption[]>;
    subitemColumns: PreloadedData<DropdownOption[]>;
    selectedBoard: BoardDropdownOption;
    selectedColumn: DropdownOption;
    selectedSubitemColumn: DropdownOption;
    selectedItemType: ButtonValue;
    connectedColumns: ConnectedColumn[];
    page: ColumnConnectorPages;
    latestAddedColumn: ConnectedColumnsInputBase;
};

const initialState: ColumnConnectorState = {
    connectedColumns: [],
    columns: {
        data: [],
        isLoading: false
    },
    subitemColumns: {
        data: [],
        isLoading: false
    },
    selectedBoard: null,
    selectedColumn: null,
    selectedSubitemColumn: null,
    selectedItemType: ConnectItemTypes.Items,
    page: ColumnConnectorPages.Summary,
    latestAddedColumn: null
};

const columnConnectorReducer = createSlice({
    name: "columnConnector",
    initialState: initialState,
    reducers: {
        setMondayColumns: (state, action: PayloadAction<PreloadedData<DropdownOption[]>>) => {
            state.columns = action.payload;
        },
        setMondaySubitemColumns: (state, action: PayloadAction<PreloadedData<DropdownOption[]>>) => {
            state.subitemColumns = action.payload;
        },
        setSelectedBoard: (state, action: PayloadAction<BoardDropdownOption>) => {
            state.selectedBoard = action.payload;
        },
        setMondaySelectedColumn: (state, action: PayloadAction<DropdownOption>) => {
            state.selectedColumn = action.payload;
        },
        setMondaySelectedSubitemColumns: (state, action: PayloadAction<DropdownOption>) => {
            state.selectedSubitemColumn = action.payload;
        },
        setSelectedItemType: (state, action: PayloadAction<ButtonValue>) => {
            state.selectedItemType = action.payload;
        },
        setConnectedColumns: (state, action: PayloadAction<ConnectedColumn[]>) => {
            state.connectedColumns = action.payload;
        },
        setColumnConnectorPage: (state, action: PayloadAction<ColumnConnectorPages>) => {
            state.page = action.payload;
        },
        setLatestAddedColumn: (state, action: PayloadAction<ConnectedColumnsInputBase>) => {
            state.latestAddedColumn = action.payload;
        },
        resetMondayColumnSelectState: (state) => {
            state.selectedBoard = initialState.selectedBoard;
            state.selectedColumn = initialState.selectedColumn;
            state.selectedSubitemColumn = initialState.selectedSubitemColumn;
            state.selectedItemType = initialState.selectedItemType;
            state.columns = initialState.columns;
            state.subitemColumns = initialState.subitemColumns;
        }
    }
});

export const {
    setMondayColumns,
    setMondaySubitemColumns,
    setSelectedBoard,
    setMondaySelectedColumn,
    setMondaySelectedSubitemColumns,
    setSelectedItemType,
    setConnectedColumns,
    setColumnConnectorPage,
    setLatestAddedColumn,
    resetMondayColumnSelectState
} = columnConnectorReducer.actions;

//selectors
export const getColumnConnector = (state: RootState) => state.columnConnector;
export const getMondayColumns = (state: RootState) => state.columnConnector.columns;
export const getMondaySubitemColumns = (state: RootState) => state.columnConnector.subitemColumns;
export const getSelectedBoard = (state: RootState) => state.columnConnector.selectedBoard;
export const getMondaySelectedColumn = (state: RootState) => state.columnConnector.selectedColumn;
export const getMondaySelectedSubitemColumns = (state: RootState) => state.columnConnector.selectedSubitemColumn;
export const getSelectedItemType = (state: RootState) => state.columnConnector.selectedItemType;
export const getConnectedColumns = (state: RootState) => state.columnConnector.connectedColumns;
export const getColumnConnectorPage = (state: RootState) => state.columnConnector.page;
export const getLatestAddedColumn = (state: RootState) => state.columnConnector.latestAddedColumn;

export default columnConnectorReducer.reducer;
