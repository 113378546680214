import { FC, Fragment } from "react";


export const TextWithLineBreaks: FC<{ tooltip: string; }> = ({ tooltip }) => {
    if (!tooltip) {
        return null;
    }

    return <span>
        {
            tooltip.split("\n").map((part, index) => (
                <Fragment key={index}>
                    {part}
                    {index < tooltip.split("\n").length - 1 && <br />}
                </Fragment>
            ))
        }
    </span>;
};
