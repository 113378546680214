import { AccessControlProps } from "@7pace/access-control-client/dist/components/AccessControl/AccessControl";

import { useValidateAccess } from "./hooks/useValidateAccess";
import RootLoader from "../../features/Root/RootLoader";
import { getUser } from "../../store/app/appReducer";
import { useAppSelector } from "../../store/hooks";
import { useFeatureFlags } from "../hooks/useFeatureFlags";


export type MondayAccessControlProps = AccessControlProps & {
    children: React.ReactNode;
    allowMondayAdmin?: boolean;
};

export const MondayAccessControl = ({ children, permissionsRequirement, fallbackComponent, allowMondayAdmin = false }: MondayAccessControlProps) => {
    const { isAdmin } = useAppSelector(getUser);

    const { rolesAndPermissionsEnabled } = useFeatureFlags();
    const { hasAccess, permissionsPrepared } = useValidateAccess(permissionsRequirement);

    if (rolesAndPermissionsEnabled && !permissionsPrepared) {
        return <RootLoader />;
    }

    if (rolesAndPermissionsEnabled ? hasAccess || (allowMondayAdmin && isAdmin) : true) {
        return <>{children}</>;
    }

    return <>{fallbackComponent}</>;
};


