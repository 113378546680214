import { FC } from "react";
import { ListChildComponentProps } from "react-window";

import { VirtualizedPolyLoaderOption } from "../../../components/VirtualizedList/components/PolyOption/VirtualizedPolyLoaderOption";
import { VirtualizedPolyWrapper } from "../../../components/VirtualizedList/components/VirtualizedPolyWrapper";
import { getVirtualizedProps } from "../../../components/VirtualizedList/utils/getVirtualizedProps";
import { PolyUserOption, UserOption } from "./UserOption";


export const UserPolyOptionVirtualized: FC<ListChildComponentProps<PolyUserOption[]>> = (props) => {
    const virtualizedProps = getVirtualizedProps(props);

    return (
        <VirtualizedPolyWrapper
            {...props}
            ownTypeRenderers={(preparedProps) => {
                switch (virtualizedProps.type) {
                    case "Option": return <UserOption virtualizedStyle={preparedProps.style} index={props.index} {...virtualizedProps} />;
                    case "Loading": return <VirtualizedPolyLoaderOption virtualizedStyle={preparedProps.style} index={props.index} text="Loading users..." />;
                }
            }}
        />
    );
};
