import { useCallback, useRef } from "react";
import { AdvancedFilter } from "@7pace/times-explorer";

import { useAmpliLogRocket } from "../../../common/components/Amplitude/hooks/useAmpliLogRocket";
import { useMondayStorage } from "../../../common/MondayContext";
import { getIsAdvancedPickerChanged } from "../utils/getIsAdvancedPickerChanged";


export const ADVANCED_FILTER_KEY = "7p_te_advanced_filter";

export const useTimesExplorerAdvancedFilter = () => {
    const ampliLogRocket = useAmpliLogRocket();
    const { deleteUserItem } = useMondayStorage();

    // AZ: we want to omit implicit advanced filter changes - from local or monday storage
    // log only changes that are made by user
    const isAdvancedFilterClicked = useRef(false);
    const prevFilters = useRef<AdvancedFilter>();

    const logAdvancedFilterChange = useCallback((newAdvancedFilter: AdvancedFilter) => {
        if (!isAdvancedFilterClicked.current || !newAdvancedFilter) {
            return;
        }

        const isAdvancedPickerChanged = getIsAdvancedPickerChanged(prevFilters.current, newAdvancedFilter);
        if (isAdvancedPickerChanged) {
            ampliLogRocket("filtersModified", {
                page: "Times Explorer",
                number_of_filters: newAdvancedFilter.numberOfConditions
            });
        }

        prevFilters.current = newAdvancedFilter;
    }, [ampliLogRocket, isAdvancedFilterClicked]);

    const onAdvancedFilterClick = useCallback(() => {
        isAdvancedFilterClicked.current = true;
        ampliLogRocket("filtersClicked", {
            page: "Times Explorer"
        });
    }, [ampliLogRocket]);

    // GM: Used for one-time migration of advanced filters to layouts
    const cleanupAdvancedFilter = useCallback(async () => {
        deleteUserItem(ADVANCED_FILTER_KEY);
        localStorage.removeItem(ADVANCED_FILTER_KEY);
    }, [deleteUserItem]);

    return {
        logAdvancedFilterChange,
        onAdvancedFilterClick,
        cleanupAdvancedFilter
    };
};
