import { TabList } from "monday-ui-react-core";
import styled, { css } from "styled-components";


export const StyledTabs = styled(TabList) <{ showTabsLine: boolean; }>`
    padding-bottom: 0;

    > ul {
        padding-inline-start: 0;
    }

    ${p => p.showTabsLine && css`
        li {
            padding-bottom: 2px;
            border-bottom: none;
        }
    `}
`;
