import { useMemo, useState } from "react";
import styled from "styled-components";

import { InternalStatusReason, LicenseStatus } from "../../ClientConfig/types";
import { useAccountConfig } from "../../ClientConfig/useAccountConfig";
import { SeatsExceededBanner } from "./SeatsExceededBanner";


export const LicenseBannerHolder: React.FC<{ float?: boolean; }> = ({ float }) => {
    const { license } = useAccountConfig();
    const [dismissed, setDismissed] = useState(false);
    const dismiss = () => setDismissed(true);

    const banner = useMemo(() => {
        if (dismissed) {
            return null;
        }

        if (license?.internalStatus !== LicenseStatus.DUE_TO_EXPIRY) {
            return null;
        }

        if (license.internalStatusReason === InternalStatusReason.NO_SEATS) {
            return <SeatsExceededBanner license={license} onClose={dismiss} />;
        }

        return null;
    }, [license, dismissed]);

    return <LicenseBannerContainer float={float}>
        {banner}
    </LicenseBannerContainer>;
};

const LicenseBannerContainer = styled.div<{ float: boolean; }>`
    width: 100%;

    ${({ float }) => float && `
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    `}
`;
