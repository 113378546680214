import { FC, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { Chips, Counter, Dialog, DialogContentContainer } from "monday-ui-react-core";
import styled from "styled-components";

import { SELECTED_VALUE_ROOT_CLASS } from "../constants";
import { SlotDropdownOption } from "../types";


// AZ: sum of width of all offsets and elements of chips besides text
export const CLEAR_OPTION_BUTTON_ARIA_LABEL = "clear-option";
export const COUNTER_CLASS = "multiselect-counter";
export const MULTISELECT_OVERFLOW_POPUP_CLASS = "multiselect-overflow";
export const SELECTED_MULTI_VALUE_INLINE_CLASS = "selected-multi-value-inline";

const CHIPS_OFFSETS = 40;
const OVERFLOWED_COUNTER_WIDTH = 34;

type MondaySelectSlotMultiValueProps = {
    isMenuOpen: boolean;
    selectedOptions: SlotDropdownOption[];
    onValueClick: () => void;
    onOptionRemove: (removeOption: SlotDropdownOption) => void;
};

export const MondaySelectSlotMultiValue: FC<MondaySelectSlotMultiValueProps> = ({
    isMenuOpen,
    selectedOptions,
    onValueClick,
    onOptionRemove
}) => {
    const container = useRef<HTMLDivElement>(null);

    const [inlineOptions, setInlineOptions] = useState<SlotDropdownOption[]>(selectedOptions);
    const [overflowOptions, setOverflowOptions] = useState<SlotDropdownOption[]>([]);

    const isOverflowed = overflowOptions.length > 0;

    useLayoutEffect(() => {
        if (!container.current || selectedOptions.length <= 1) {
            setOverflowOptions([]);
            setInlineOptions(selectedOptions);
            return;
        }

        const containerWidth = container.current.clientWidth - OVERFLOWED_COUNTER_WIDTH;

        // AZ: Measure the width of each option
        const optionsWidths = selectedOptions.map(option => {
            const tempDiv = document.createElement("div");
            tempDiv.style.position = "absolute";
            tempDiv.style.visibility = "hidden";
            tempDiv.style.whiteSpace = "nowrap";
            tempDiv.innerText = option.label;
            document.body.appendChild(tempDiv);
            const width = tempDiv.clientWidth + CHIPS_OFFSETS;
            document.body.removeChild(tempDiv);
            return width;
        });

        // AZ: Accumulate widths and split the array
        let accumulatedWidth = 0;
        const newInlineOptions: SlotDropdownOption[] = [];
        const newOverflowOptions: SlotDropdownOption[] = [];

        for (let i = 0; i < selectedOptions.length; i++) {
            if (accumulatedWidth + optionsWidths[i] <= containerWidth) {
                newInlineOptions.push(selectedOptions[i]);
                accumulatedWidth += optionsWidths[i];
            } else {
                newOverflowOptions.push(selectedOptions[i]);
            }
        }

        setInlineOptions(newInlineOptions);
        setOverflowOptions(newOverflowOptions);
    }, [selectedOptions]);

    const onValueClickInternal = (event: MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;
        if (target.closest(`[aria-label="${CLEAR_OPTION_BUTTON_ARIA_LABEL}"]`) ||
            (!isMenuOpen && target.closest(`.${COUNTER_CLASS}`)) ||
            target.closest(`.${MULTISELECT_OVERFLOW_POPUP_CLASS}`)) {
            return;
        }

        onValueClick();
    };

    return (
        <SelectedValuesWrapper
            ref={container}
            className={SELECTED_VALUE_ROOT_CLASS}
            onClick={onValueClickInternal}
        >
            <SelectedInline className={SELECTED_MULTI_VALUE_INLINE_CLASS}>
                {inlineOptions.map((option) => (
                    <InlineChipsWrapper key={option.value}>
                        <Chips
                            label={option.label}
                            closeButtonAriaLabel={CLEAR_OPTION_BUTTON_ARIA_LABEL}
                            onDelete={() => onOptionRemove(option)}
                        />
                    </InlineChipsWrapper>
                ))}
            </SelectedInline>
            {isOverflowed &&
                <Dialog
                    showTrigger={[Dialog.hideShowTriggers.CLICK]}
                    hideTrigger={[Dialog.hideShowTriggers.CLICK_OUTSIDE]}
                    animationType={Dialog.animationTypes.OPACITY_AND_SLIDE}
                    position={Dialog.positions.BOTTOM}
                    shouldShowOnMount={false}
                    tooltip={true}
                    addKeyboardHideShowTriggersByDefault={true}
                    content={
                        <SelectedOverflow className={MULTISELECT_OVERFLOW_POPUP_CLASS}>
                            {overflowOptions.map((option) => (
                                <OverflowChipsWrapper
                                    key={option.value}
                                    label={option.label}
                                    className="some"
                                    closeButtonAriaLabel={CLEAR_OPTION_BUTTON_ARIA_LABEL}
                                    onDelete={() => onOptionRemove(option)}
                                />
                            ))}
                        </SelectedOverflow>
                    }
                >
                    <OverflowedCounter
                        count={overflowOptions.length}
                        prefix="+"
                        kind={Counter.kinds.LINE}
                        size={Counter.sizes.LARGE}
                        className={COUNTER_CLASS}
                    />
                </Dialog>
            }
        </SelectedValuesWrapper>
    );
};

const SelectedValuesWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing.s};
    width: 100%;
    height: 100%;
    padding: 0 8px; // AZ: + 4px from first chip so it will much single value padding-left value (12px)
    overflow: hidden;
`;

const OverflowedCounter = styled(Counter)`
    margin-left: auto;
    flex: 1;
    user-select: none;
`;

const SelectedInline = styled.div`
    flex: 1;
    display: flex;
    overflow: hidden;
`;

const InlineChipsWrapper = styled.div`
    border-radius: ${p => p.theme.radii.s};
    overflow: hidden;

    > div {
        width: 100%;
    }
`;

const SelectedOverflow = styled(DialogContentContainer)`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: ${p => p.theme.spacing.xxs};
    max-height: 300px;
    overflow-y: auto;
`;

const OverflowChipsWrapper = styled(Chips)`
    width: fit-content;
`;
