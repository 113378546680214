import { FC, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { useTheme } from "styled-components";

import { VirtualizedPolyLoaderOption } from "./PolyOption/VirtualizedPolyLoaderOption";
import { VirtualizedPolyNoResultsOption } from "./PolyOption/VirtualizedPolyNoResultsOption";
import { VirtualizedPolyOption } from "./PolyOption/VirtualizedPolyOption";
import { getVirtualizedProps } from "../../MondaySearch/utils/getVirtualizedProps";
import { VirtualizedPolyOptionProps } from "../hooks/useVirtualizedPolyOptions";


type VirtualizedPolyWrapperProps = ListChildComponentProps<VirtualizedPolyOptionProps[]> & {
    ownTypeRenderers?: (props: ListChildComponentProps<VirtualizedPolyOptionProps[]>) => JSX.Element;
};

export const VirtualizedPolyWrapper: FC<VirtualizedPolyWrapperProps> = (props) => {
    const theme = useTheme();
    const virtualizedProps = getVirtualizedProps(props);

    // AZ: adjust items top to apply vertical paddings in list
    const styleWithPadding = useMemo(() => {
        return {
            ...props.style,
            top: `calc(${props.style.top}px + ${theme.spacing.s})`
        };
    }, [props.style, theme.spacing.s]);

    // AZ: if option renderer is not provided (not overwritten or just new - render default option)
    return (
        <>
            {props.ownTypeRenderers?.({ ...props, style: styleWithPadding }) ??
                (() => {
                    switch (virtualizedProps.type) {
                        case "Option": return <VirtualizedPolyOption virtualizedStyle={styleWithPadding} index={props.index} {...virtualizedProps} />;
                        case "Loading": return <VirtualizedPolyLoaderOption virtualizedStyle={styleWithPadding} index={props.index} text="Loading data..." />;
                        case "NoOptions": return <VirtualizedPolyNoResultsOption virtualizedStyle={styleWithPadding} index={props.index} />;
                    }
                })()
            }
        </>
    );
};
