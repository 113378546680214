import { Suspense } from "react";
import styled from "styled-components";

import RootLoader from "../features/Root/RootLoader";


const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SuspenseFallback = () => <LoaderContainer><RootLoader /></LoaderContainer>;

const withSuspense = (children: React.ReactNode) =>
    <Suspense
        fallback={<SuspenseFallback />}
    >
        {children}
    </Suspense>;

export default withSuspense;
