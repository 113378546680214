import { FC, useCallback, useMemo } from "react";
import { SlotSize, UserPickerSlotProps } from "@7pace/design";
import styled, { css } from "styled-components";

import { UserOptionValue } from "./components/UserOptionValue";
import { UserPickerMenu } from "./components/UserPickerMenu";
import { UserApi } from "../../components/AddTimeComponent/plugins/UserPlugin/hooks/useUsersQuery";
import { AdvancedDropdown } from "../../components/AdvancedDropdown/AdvancedDropdown";
import { AdvancedDropdownMenuAdapter } from "../../components/AdvancedDropdown/components/AdvancedDropdownMenuAdapter";
import { AdvancedDropdownMenuAdapterProps, AdvancedDropdownMenuProps } from "../../components/AdvancedDropdown/types";
import { UserDropdownOption } from "./types";


export const MondayUserPickerSlot: FC<UserPickerSlotProps<UserApi>> = ({
    users,
    selectedUsers,
    loading,
    errorProps,
    placeholder,
    clearable,
    className,
    size = SlotSize.Large,
    onChange,
    ...rest
}) => {
    const mapUserToDropdownOption = useCallback((user: UserApi): UserDropdownOption => user && ({
        value: user.id,
        label: user.name,
        externalId: user.externalId,
        size
    }), [size]);

    const value: UserDropdownOption = useMemo(() => {
        return selectedUsers?.length ? mapUserToDropdownOption(selectedUsers[0]) : null;
    }, [mapUserToDropdownOption, selectedUsers]);

    const options: UserDropdownOption[] = useMemo(() => users.map(mapUserToDropdownOption), [users, mapUserToDropdownOption]);

    const showSpinner = !value && loading;

    const valueRender = useCallback((props: UserDropdownOption) => {
        return (
            <SelectedUserOptionValue
                id={`${props.value}`}
                name={props.label}
                externalId={props.externalId}
                size={size}
            />
        );
    }, [size]);

    const menuRender = useCallback((props: AdvancedDropdownMenuAdapterProps<UserDropdownOption>) => {
        return (
            <AdvancedDropdownMenuAdapter
                {...props}
                size={size}
                menuRenderer={(menuRenderProps: AdvancedDropdownMenuProps<UserDropdownOption>) =>
                    <UserPickerMenu {...menuRenderProps} />
                }
            />
        );
    }, [size]);

    const onOptionSelectInternal = useCallback((data: UserDropdownOption) => {
        const selectedUser = users.find(cur => cur.id === `${data.value}`);
        onChange?.([selectedUser]);
    }, [onChange, users]);

    const onClear = useCallback(() => {
        onChange?.([]);
    }, [onChange]);

    const isBulkEditUserPicker = className.includes("bulk-edit-user-plugin");

    return (
        <AdvancedDropdownStyled
            {...rest}
            value={value}
            showSpinner={showSpinner}
            options={options}
            className={className}
            size={size}
            clearable={clearable ?? false}
            placeholder={placeholder || "Select user"}
            error={errorProps?.message}
            isUserSelected={!!value}
            fixedDropdownContainer={isBulkEditUserPicker}
            valueRenderer={valueRender}
            menuRenderer={menuRender}
            onOptionSelect={onOptionSelectInternal}
            onClear={onClear}
        />
    );
};

const AdvancedDropdownStyled = styled(AdvancedDropdown) <{ isUserSelected: boolean; }>`
    ${p => p.isUserSelected && css`
        input {
            padding: 0 29px !important; 
        }
    `}
`;

const SelectedUserOptionValue = styled(UserOptionValue)`
    padding-left: ${p => p.theme.spacing.s};
`;
