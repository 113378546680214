import { ChangeEvent, FC } from "react";
import { RadioGroupOrientation, RadioGroupSlotProps } from "@7pace/design";
import { RadioButton } from "monday-ui-react-core";
import styled from "styled-components";


export const MondayRadioGroupSlot: FC<RadioGroupSlotProps> = ({
    items,
    selected,
    disabled,
    onSelect,
    ...commonProps
}) => {
    const onSelectInternal = (event: ChangeEvent<HTMLInputElement>) => {
        onSelect(event.target.value);
    };

    return (
        <RadioGroup {...commonProps}>
            {items?.map((item) => (
                <RadioButton
                    key={item.value}
                    text={item.text}
                    value={item.value}
                    checked={item.value === selected}
                    disabled={disabled}
                    onSelect={onSelectInternal}
                />
            ))}
        </RadioGroup>
    );
};

const RadioGroup = styled.div<{ orientation?: RadioGroupOrientation; }>`
    display: flex;
    flex-flow: ${p => p.orientation === RadioGroupOrientation.Vertical ? "column" : "row"};
    gap: ${p => p.theme.spacing.m};
`;
