import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../../store/store";
import { PreloadedData } from "../../../types/PreloadedData";
import { SearchTerm } from "../hooks/useSearch";
import { MondaySearchItemBoard } from "../models/searchBoard";
import { SearchData } from "../models/SearchData";


type MondaySearchState = {
    searchBoards: PreloadedData<MondaySearchItemBoard[]>;
    searchItems: PreloadedData<SearchData>;
    searchTerm: SearchTerm;
    error: boolean;
};

const initialState = {
    searchBoards: {
        data: [],
        isLoading: false
    },
    searchItems: {
        data: {},
        isLoading: false
    },
    searchTerm: null,
    error: false
} as MondaySearchState;

const mondaySearchReducer = createSlice({
    name: "mondaySearch",
    initialState: initialState,
    reducers: {
        setSearchBoards: (state, action: PayloadAction<PreloadedData<MondaySearchItemBoard[]>>) => {
            state.searchBoards = action.payload;
        },
        setSearchItems: (state, action: PayloadAction<PreloadedData<SearchData>>) => {
            state.searchItems = action.payload;
        },
        setSearchTerm: (state, action: PayloadAction<SearchTerm>) => {
            state.searchTerm = action.payload;
        },
        resetSearchItems: (state) => {
            state.searchItems = { ...initialState.searchItems };
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        }
    }
});

export const {
    setSearchBoards,
    setSearchItems,
    setSearchTerm,
    setError,
    resetSearchItems
} = mondaySearchReducer.actions;

//selectors
export const getSearchBoardsData = (store: RootState): PreloadedData<MondaySearchItemBoard[]> => store.mondaySearch.searchBoards;
export const getSearchItemsData = (store: RootState): PreloadedData<SearchData> => store.mondaySearch.searchItems;
export const getSearchTerm = (store: RootState): SearchTerm => store.mondaySearch.searchTerm;
export const getSearchError = (store: RootState): boolean => store.mondaySearch.error;

export default mondaySearchReducer.reducer;
