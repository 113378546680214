import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, IconButton, Tab, Tooltip } from "monday-ui-react-core";
import NewTab from "monday-ui-react-core/dist/icons/NewTab";
import { SubIcon } from "monday-ui-react-core/dist/types/types";
import styled, { createGlobalStyle } from "styled-components";

import { MenuOption, TabsWithMenuProps } from "../types/menuOption";
import { TabMenuButton } from "./TabMenuButton";


export type PageTabWithMenuProps = {
    name: string;
    path: string;
    id: string;
    "data-testid"?: string;
    tabsWithMenuProps: TabsWithMenuProps;
    menuOptions: MenuOption[];
    isUnsaved?: boolean;
    icon?: SubIcon;
};

export type PreparedPageTabWithMenuProps = Pick<PageTabWithMenuProps, "name" | "path" | "id" | "icon">;

const LI_PADDINGS = 2;
const BUTTON_PADDING_WIDTH = 4;
const BUTTON_WIDTH = 24;
const TOTAL_SPACE_TAKEN_FROM_TITLE = 36;

export const MENU_ICON_ELEMENT_ID = "tab-menu-icon";

const PageTabWithMenu: React.FC<PageTabWithMenuProps> = ({ name, path, id, icon, tabsWithMenuProps, menuOptions, isUnsaved, ...rest }) => {
    const { activeTab, onTabClick, onTabSave, defaultTabId } = tabsWithMenuProps;
    const [active, setActive] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const tabRef = useRef(null);
    const tabWidthRef = useRef<number>(0);
    const isSaveButtonVisible = !!(active && id !== defaultTabId && isUnsaved);

    const { ref, overflow } = useOverflowDetector({});

    useEffect(() => {
        const isActive = activeTab ? activeTab.path === path : location.pathname.endsWith(path);
        setActive(isActive);
    }, [activeTab, location.pathname, path]);

    useLayoutEffect(() => {
        tabWidthRef.current = tabRef.current?.getBoundingClientRect().width || 0;
    }, [name]);

    const handleMenuShow = () => {
        setIsMenuOpen(true);
    };

    const handleMenuHide = () => {
        setIsMenuOpen(false);
    };

    const handleClick = (e: React.MouseEvent) => {
        if (active) {
            return;
        }

        const target = e.target as HTMLElement;
        if (target.closest(`#${MENU_ICON_ELEMENT_ID}`) || target.closest(".monday-style-dialog-content-wrapper")) {
            return;
        }

        onTabClick?.(id);
        navigate(path);
    };

    const titleContainerWidth = useMemo(() => {
        if (!tabWidthRef.current) {
            return undefined;
        }

        const saveButtonVerticalSpace = isSaveButtonVisible ? BUTTON_WIDTH + BUTTON_PADDING_WIDTH : 0;
        return tabWidthRef.current - saveButtonVerticalSpace - LI_PADDINGS;
    }, [isSaveButtonVisible]);

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <TabWrapper
            data-tab-path={path}
            ref={tabRef}
            onClick={handleClick}
            isSaveButtonVisible={isSaveButtonVisible}
        >
            <NonInferableTooltipGlobalStyle />
            <Tooltip
                className="non-interferable-tooltip"
                content={overflow ? name : undefined}
                open={isTooltipOpen}
                hideTrigger={[]}
                showTrigger={[]}
            >
                <StyledTab data-testid={rest["data-testid"]} active={active}>
                    <TitleContainer isSaveButtonVisible={isSaveButtonVisible} widthStyle={titleContainerWidth}>
                        <TabTitle
                            onMouseEnter={() => setIsTooltipOpen(true)}
                            onMouseLeave={() => setIsTooltipOpen(false)}
                            ref={ref as React.Ref<HTMLDivElement>}
                            className="tab-title"
                            widthStyle={titleContainerWidth && isSaveButtonVisible ? titleContainerWidth : titleContainerWidth - TOTAL_SPACE_TAKEN_FROM_TITLE}
                            title={isSaveButtonVisible ? name : undefined}
                        >
                            {icon && <Icon icon={icon} />}
                            <span>{name}</span>
                        </TabTitle>
                        <StyledTabMenuButton
                            tabId={id}
                            isMenuOpen={isMenuOpen}
                            onMenuShow={handleMenuShow}
                            onMenuHide={handleMenuHide}
                            menuOptions={menuOptions}
                            additionalRightPadding={isSaveButtonVisible ? `${BUTTON_WIDTH + BUTTON_PADDING_WIDTH}px` : "0px"}
                        />
                    </TitleContainer>
                    {isSaveButtonVisible &&
                        <SaveButtonContainer>
                            <Tooltip content="Save changes to this view">
                                <IconButton
                                    onClick={() => onTabSave?.(id)}
                                    icon={NewTab}
                                    size={IconButton.sizes.XS}
                                    active
                                />
                            </Tooltip>
                        </SaveButtonContainer>
                    }
                </StyledTab>
            </Tooltip>
        </TabWrapper>

    );
};

const NonInferableTooltipGlobalStyle = createGlobalStyle`
    .monday-style-dialog-content-wrapper:has(.non-interferable-tooltip) {
        pointer-events: none;
    }
`;

const StyledTab = styled(Tab)`
    min-width: 80px;
        > a {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
`;

const TitleContainer = styled.div<{ widthStyle?: number; isSaveButtonVisible?: boolean; }>`
    display: flex;
    align-items: center;
    max-width: 400px;
    padding: 0 calc(${p => p.theme.spacing.m} + ${p => p.theme.spacing.xs});
    ${p => p.widthStyle !== undefined && `width: ${p.widthStyle}px;`};

    ${p => p.isSaveButtonVisible && `
        padding-left: ${p.theme.spacing.xs};
        padding-right: 10px
    `}
`;

const TabTitle = styled.div<{ widthStyle?: number; }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    min-width: 0;
    ${p => p.widthStyle !== undefined && `width: ${p.widthStyle}px;`};
    transition: transform 100ms cubic-bezier(0,0,.35,1);
    
    :has(svg) {
        display: flex;
        svg {
            margin: 2.5px 4px;
        }
    }
`;

const StyledTabMenuButton = styled(TabMenuButton) <{ additionalRightPadding: string; }>`
    opacity: 0;
    position: absolute;
    right: calc(${p => p.additionalRightPadding} + ${p => p.theme.spacing.xs});
    top: 5.5px;
    transition: opacity 100ms cubic-bezier(0,0,.35,1);
`;

const SaveButtonContainer = styled.div`
    padding-right: ${p => p.theme.spacing.xs};
`;

const TabWrapper = styled.div<{ isSaveButtonVisible?: boolean; }>`
    :hover button, button[class*="active"] {
        opacity: 1;
    }

    :hover .tab-title, &:has( button[class*="active"]) .tab-title {
        ${p => p.isSaveButtonVisible ? "width: calc(100% - 20px)" : "transform: translateX(-10px)"}
    }
`;

export default PageTabWithMenu;
