import { TooltipSlotDelay, TooltipSlotDirection } from "@7pace/design";
import { Tooltip } from "monday-ui-react-core";


export const mapTooltipSlotDirectionalHint = (directionalHint: TooltipSlotDirection) => {
    switch (directionalHint) {
        case TooltipSlotDirection.Bottom:
            return Tooltip.positions.BOTTOM;
        case TooltipSlotDirection.Left:
            return Tooltip.positions.LEFT;
        case TooltipSlotDirection.Right:
            return Tooltip.positions.RIGHT;
        case TooltipSlotDirection.Top:
        default:
            return Tooltip.positions.TOP;
    }
};

export const mapTooltipSlotDelay = (delay: TooltipSlotDelay) => {
    switch (delay) {
        case TooltipSlotDelay.Long: return 500;
        case TooltipSlotDelay.Medium: return 300;
        case TooltipSlotDelay.None:
        default:
            return 0;
    }
};
