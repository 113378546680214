import { useCallback, useMemo, useRef } from "react";

import { PageTabProps } from "../PageTab";
import { PreparedPageTabWithMenuProps } from "../PageTabWithMenu";
import { pagesArePreparedPageTabWithMenuProps } from "../utils/pages";
import { Status } from "./useCustomTabsManagement";
import { WidthCalculations } from "./useCustomTabsWidthCalculations";


type PagesToIncludeAcc = {
    pages: PageTabProps[];
    currentWidth: number;
};

type SharedPageTabProps = Pick<PageTabProps, "name" | "path">;

export const useCustomTabsOverflowManagement = (
    pages: SharedPageTabProps[],
    activeRenameTab: PreparedPageTabWithMenuProps | null,
    widthCalculations: WidthCalculations,
    maxAllowedWidth: number,
    status: Status,
    activeTab: PreparedPageTabWithMenuProps | PageTabProps | undefined
) => {
    const currentPossiblePages = useRef<SharedPageTabProps[]>([]);
    const previousPages = useRef<SharedPageTabProps[]>([]);

    const getTabWidth = useCallback((path: string) => {
        const tabWidth = widthCalculations.tabWidths[path] === undefined ? widthCalculations.fallbackTabWidth : widthCalculations.tabWidths[path];

        return tabWidth;
    }, [widthCalculations.fallbackTabWidth, widthCalculations.tabWidths]);

    const reducePagesToInclude = useCallback((acc: PagesToIncludeAcc, page: PageTabProps) => {
        const currentWidth = acc.currentWidth + getTabWidth(page.path);
        const shouldIncludePage = currentWidth <= maxAllowedWidth;

        return {
            pages: shouldIncludePage ? [...acc.pages, page] : acc.pages,
            currentWidth: shouldIncludePage ? currentWidth : acc.currentWidth
        };
    }, [getTabWidth, maxAllowedWidth]);

    const sumAllTabWidths = useCallback((acc: number, page: PageTabProps) => {
        return acc + getTabWidth(page.path);
    }, [getTabWidth]);

    const getPrioritizedPages = useCallback((includedPages: PageTabProps[]): PageTabProps[] => {
        const prioritizedRenameTab = includedPages.find(page => page.path === activeRenameTab?.path);
        const prioritizedActiveTab = includedPages.find(page => page.path === activeTab?.path);

        if (prioritizedActiveTab && prioritizedRenameTab?.path === prioritizedActiveTab?.path) {
            return [prioritizedRenameTab];
        }

        return [prioritizedRenameTab, prioritizedActiveTab].filter(Boolean);
    }, [activeRenameTab, activeTab]);

    const combinePages = useCallback((includedPages: PageTabProps[], originalPages: PageTabProps[]) => {
        return originalPages.filter(page => includedPages.some(p => p.path === page.path));
    }, []);

    const isOverflowing = useCallback((pagesToConsider: SharedPageTabProps[]) => {
        const totalWidth = pagesToConsider.reduce(sumAllTabWidths, widthCalculations.tabsInputWidth + widthCalculations.tabCreateWidth);

        const shouldAccountForMoreTabsDropdown = pagesToConsider.length < pages.length;

        return totalWidth + (shouldAccountForMoreTabsDropdown ? widthCalculations.moreTabsDropdownWidth : 0) > maxAllowedWidth;
    }, [maxAllowedWidth, pages.length, sumAllTabWidths, widthCalculations.moreTabsDropdownWidth, widthCalculations.tabCreateWidth, widthCalculations.tabsInputWidth]);

    const calculatePossiblePagesDuringCreation = useCallback(() => {
        const pagesDiff = pages.filter(page => !previousPages.current.some((previousPage) => previousPage.path === page.path));
        const pagesToConsider = Object.values([...currentPossiblePages.current, ...pagesDiff].reduce<Record<string, SharedPageTabProps>>((acc, page) => {
            return {
                ...acc,
                [page.path]: page
            };
        }, {}));

        if (isOverflowing(pagesToConsider)) {
            return null;
        } else {
            currentPossiblePages.current = pagesToConsider;
        }


        return currentPossiblePages.current;
    }, [isOverflowing, pages]);

    const retrievePagesToInclude = useCallback(() => {
        // fixed width is the width that we always have to account for, it will always be added
        const fixedWidth = widthCalculations.tabsInputWidth + widthCalculations.tabCreateWidth;

        // if there is an active tab being renamed, this tab will be included in the calculation
        const prioritizedPages = getPrioritizedPages(pages);
        const pagesWithoutPrioritizedPages = pages.filter(page => !prioritizedPages.some(p => p.path === page.path));

        const orderedPages = [...prioritizedPages, ...pagesWithoutPrioritizedPages].filter(Boolean);

        const shouldAccountForMoreTabsDropdown = orderedPages.reduce(sumAllTabWidths, fixedWidth) > maxAllowedWidth;

        // now let's calculate the pages that can be included in the tabs
        // we will always account for the widthCalculations.tabsInputWidth
        // and we will only account for the moreTabsDropdownWidth if the width of all pages + (the smallest page + the tabsInputWidth) is greater than the maxAllowedWidth
        const { pages: pagesToInclude } = orderedPages.reduce(reducePagesToInclude, {
            pages: [],
            currentWidth: fixedWidth + (shouldAccountForMoreTabsDropdown ? widthCalculations.moreTabsDropdownWidth : 0)
        });

        return pagesToInclude;
    }, [getPrioritizedPages, pages, reducePagesToInclude, sumAllTabWidths, widthCalculations.moreTabsDropdownWidth, widthCalculations.tabCreateWidth, widthCalculations.tabsInputWidth, maxAllowedWidth]);

    const getRequiredPages = useCallback((pagesToInclude: PageTabProps[]) => {
        if (pagesToInclude.includes(activeRenameTab) || pagesToInclude.includes(activeTab)) {
            return pagesToInclude;
        }

        return [activeRenameTab || activeTab || pagesToInclude[0]].filter(Boolean);
    }, [activeRenameTab, activeTab]);

    const possiblePages = useMemo((): PageTabProps[] | PreparedPageTabWithMenuProps[] => {
        if (status === "creating") {
            const possiblePagesDuringCreation = calculatePossiblePagesDuringCreation();

            if (possiblePagesDuringCreation) {
                return possiblePagesDuringCreation;
            }
        }

        previousPages.current = pages;

        if (
            pages.length === 0
            || Object.keys(widthCalculations.tabWidths).length === 0
            || maxAllowedWidth === 0
        ) {
            return pages;
        }

        const pagesToInclude = retrievePagesToInclude();
        const combinedPages = combinePages(pagesToInclude, pages);

        const requiredPages = getRequiredPages(combinedPages);

        currentPossiblePages.current = requiredPages;

        return requiredPages;
    }, [status, pages, widthCalculations.tabWidths, maxAllowedWidth, retrievePagesToInclude, combinePages, getRequiredPages, calculatePossiblePagesDuringCreation]);

    const leftPages = useMemo((): PageTabProps[] | PreparedPageTabWithMenuProps[] => {
        if (!activeRenameTab || !pagesArePreparedPageTabWithMenuProps(possiblePages)) {
            return possiblePages;
        }

        const activeRenameTabIndex = possiblePages.findIndex(x => x.id === activeRenameTab.id);

        if (activeRenameTabIndex === -1) {
            return possiblePages;
        }

        return possiblePages.slice(0, activeRenameTabIndex);
    }, [activeRenameTab, possiblePages]);

    const rightPages = useMemo((): PageTabProps[] | PreparedPageTabWithMenuProps[] => {
        if (!activeRenameTab || !pagesArePreparedPageTabWithMenuProps(possiblePages)) {
            return [];
        }

        const activeRenameTabIndex = possiblePages.findIndex(x => x.id === activeRenameTab.id);

        if (activeRenameTabIndex === -1) {
            return [];
        }

        return possiblePages.slice(activeRenameTabIndex + 1);
    }, [activeRenameTab, possiblePages]);

    const overflowingPages = useMemo((): PreparedPageTabWithMenuProps[] => {
        if (!pagesArePreparedPageTabWithMenuProps(possiblePages) || !pagesArePreparedPageTabWithMenuProps(pages)) {
            return [];
        }

        return pages
            .filter(page =>
                !possiblePages
                    .some(
                        (possiblePage: SharedPageTabProps) =>
                            possiblePage.path === page.path)
            );
    }, [pages, possiblePages]);

    return {
        leftPages,
        rightPages,
        overflowingPages
    };
};
