import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDateTime } from "@7pace/datetime";
import { DatePickerSlotProps } from "@7pace/design";
import { stringUtils } from "@7pace/utilities";
import moment from "moment";
import { Button, DatePicker, Dialog, DialogContentContainer } from "monday-ui-react-core";
import Calendar from "monday-ui-react-core/dist/icons/Calendar";
import styled, { css } from "styled-components";

import { MondayInputError } from "../../components/MondayPrimitives/MondayInputError";
import { mapButtonSlotSize } from "../MondayButtonSlot/utils";


const modifiers = [
    {
        name: "preventOverflow",
        options: {
            mainAxis: true,
            padding: 16
        }
    }
];

export const MondayDatePickerSlot: FC<DatePickerSlotProps> = ({
    selected,
    dateRange,
    dateFormat,
    maxDate,
    minDate,
    monthShown,
    buttonProps,
    errorProps,
    onChange,
    fdow,
    className,
    label,
    tabIndex,
    ...commonProps
}) => {
    const ref = useRef<Dialog>();

    const [range, setRange] = useState(selected);
    const [error, setError] = useState(errorProps);

    const { getMoment, formatDate } = useDateTime();

    useEffect(() => {
        setError(errorProps);
    }, [errorProps]);

    useEffect(() => {
        setRange(selected);
    }, [selected]);

    const buttonText = useMemo(() => {
        if (!range?.from) {
            return buttonProps.placeholder;
        }

        const from = formatDate(range.from, dateFormat);
        const to = range.to ? ` - ${formatDate(range.to, dateFormat)}` : "";

        return `${from}${to}`;
    }, [buttonProps?.placeholder, dateFormat, formatDate, range?.from, range?.to]);

    const datePickerClassName = stringUtils.constructClasses(className, "mdn-date-picker");

    const leftIcon = useMemo(() => {
        return !buttonProps?.hideIcon && Calendar;
    }, [buttonProps?.hideIcon]);

    return <Dialog
        ref={ref}
        modifiers={modifiers}
        shouldShowOnMount={false}
        showTrigger={[Dialog.hideShowTriggers.CLICK]}
        hideTrigger={[Dialog.hideShowTriggers.CLICK, Dialog.hideShowTriggers.CLICK_OUTSIDE, Dialog.hideShowTriggers.BLUR]}
        addKeyboardHideShowTriggersByDefault={true}
        animationType={Dialog.animationTypes.OPACITY_AND_SLIDE}
        position={Dialog.positions.BOTTOM}
        content={
            <DialogContentContainer>
                <DatePickerContainer>
                    <DatePicker
                        {...commonProps}
                        className={datePickerClassName}
                        date={range?.from ? getMoment(range?.from) : undefined}
                        endDate={range?.to ? getMoment(range?.to) : undefined}
                        range={dateRange}
                        numberOfMonths={monthShown}
                        onPickDate={d => {
                            let fromTo = null;
                            if (moment.isMoment(d)) {
                                fromTo = { from: d.toDate() };
                                // GM: Close when single date is selected
                                ref?.current?.hideDialog(null, null);
                            } else {
                                fromTo = { from: d.startDate?.toDate(), to: d.endDate?.toDate() };
                                // GM: Close only when date range selected
                                if (fromTo.from && fromTo.to) {
                                    ref?.current?.hideDialog(null, null);
                                }
                            }

                            onChange?.(fromTo);
                            setRange(fromTo);
                        }}
                        shouldBlockDay={
                            (date) =>
                                (minDate && date.isBefore(getMoment(minDate)))
                                || (maxDate && date.isAfter(getMoment(maxDate)))
                        }
                        hideNavigationKeys={commonProps.disabled}
                        daySize={32}
                        firstDayOfWeek={fdow}
                        enableOutsideDays={!dateRange}
                    />
                </DatePickerContainer>
            </DialogContentContainer>
        }
    >
        <DatePickerButtonContainer className={buttonProps?.className}>
            {label && <DatePickerButtonLabel>{label}</DatePickerButtonLabel>}
            <DatePickerButton
                key={buttonText}
                leftIcon={leftIcon}
                kind={Button.kinds.SECONDARY}
                size={mapButtonSlotSize(buttonProps?.size)}
                disabled={commonProps?.disabled}
                error={error?.error}
                dateRange={dateRange}
                tabIndex={tabIndex}
                data-testid={buttonProps?.["data-testid"]}
            >
                {buttonText}
            </DatePickerButton>
            {error?.error && error?.message && <MondayInputError>{error.message}</MondayInputError>}
        </DatePickerButtonContainer>
    </Dialog>;
};

const DatePickerButtonContainer = styled.div`
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    &.max-width {
        width: 100%;

        > button {
            width: 100%;
        }
    }

    &.bulk-edit-date {
        > button {
            justify-content: flex-start;
        }
    }
`;

const DatePickerContainer = styled.div`
    .mdn-date-picker {
        min-height: unset;
        &[class*="monthYearSelection"] {
            height: 310px;
        }

        .CalendarDay {
            vertical-align: middle;
        }
    }
`;

const DatePickerButton = styled(Button) <{ dateRange: boolean; error?: boolean; }>`
    background-color: ${p => p.theme.color.background.surface.primary};
    
    ${p => p.error && css`
        &&& {
            border: 1px solid ${p => p.theme.color.background.danger.primary};
        }
    `}

    // GM: To avoid calendar jumping on different date length
    ${p => p.dateRange
        ? css`min-width: 210px !important;`
        : css`min-width: 121px !important;`
    }
    
    &.max-width {
        width: 100% !important;
    }
`;

const DatePickerButtonLabel = styled.span`
    margin-bottom: ${p => p.theme.spacing.xs};
    padding-block: 2px;
    color: ${p => p.theme.color.foreground.text.primary};
    font: ${p => p.theme.typography.body.regular.m};
`;
