import { useCallback, useEffect, useState } from "react";
import { ExtendWithString } from "@7pace/utilities";

import { BaseVirtualizedPolyOption, VirtualizedOption } from "../types";


// AZ: make it as object instead of enum to be extendable
export const POLY_OPTION_TYPES = {
    option: "Option",
    loading: "Loading",
    noOptions: "NoOptions"
} as const;

export type VirtualizedPolyOptionProps<
    TOption extends VirtualizedOption = VirtualizedOption,
    TAdditionalTypes extends string = never
> = BaseVirtualizedPolyOption & {
    type: ExtendWithString<typeof POLY_OPTION_TYPES[keyof typeof POLY_OPTION_TYPES] | TAdditionalTypes>;
    option?: TOption & {
        selected?: boolean;
        onClick?: (option: VirtualizedPolyOptionProps<TOption, TAdditionalTypes>) => void;
    };
};

type UseVirtualizedPolyOptionsProps<TValue extends string | number> = {
    options: VirtualizedOption<TValue>[];
    selectedOption: VirtualizedOption<TValue> | null;
    loading: boolean;
    noOptions: boolean;
    optionHeight: number;
    onChange: (option: VirtualizedOption) => void;
};

// AZ: do not provide plain [] for options, it will result in infinite render loop, always memoize it
export const useVirtualizedPolyOptions = <TValue extends string | number>({
    options,
    selectedOption,
    loading,
    noOptions,
    optionHeight,
    onChange
}: UseVirtualizedPolyOptionsProps<TValue>) => {
    const [polyOptions, setPolyOptions] = useState<VirtualizedPolyOptionProps[]>([]);

    const onClick = useCallback((polyOption: VirtualizedPolyOptionProps) => {
        onChange(polyOption.option);
    }, [onChange]);

    useEffect(() => {
        if (noOptions) {
            const polyNoOptions: VirtualizedPolyOptionProps = {
                type: POLY_OPTION_TYPES.noOptions,
                height: optionHeight
            };

            setPolyOptions([polyNoOptions]);
            return;
        }

        const res: VirtualizedPolyOptionProps[] = [];

        for (const option of options) {
            res.push({
                type: POLY_OPTION_TYPES.option,
                height: optionHeight,
                option: {
                    ...option,
                    selected: selectedOption?.value === option.value,
                    onClick
                }
            });
        }

        if (loading) {
            const loadingPolyUser: VirtualizedPolyOptionProps = {
                type: POLY_OPTION_TYPES.loading,
                height: optionHeight
            };
            res.push(loadingPolyUser);
        }

        setPolyOptions(res);
    }, [loading, noOptions, onClick, optionHeight, options, selectedOption?.value]);

    return polyOptions;
};
