import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { sleep, useLatestRef } from "@7pace/utilities";

import { getPagedQueryCache, getPagedQueryCacheKeyExist, updatePagedQueryCache } from "../../store/app/appReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getProgressiveDelayValue } from "../utils/getProgressiveDelayValue";


export type PagedQueryFnResult<T> = {
    data: T[];
    allFetched: boolean;
    meta?: unknown;
};

export type PagedQueryFn<T> = (meta?: unknown) => Promise<PagedQueryFnResult<T>>;

export type PagedQueryProps<T> = {
    queryKey: unknown[];
    enabled?: boolean;
    getNextChunk: PagedQueryFn<T>;
    onError?: (error: unknown) => void;
    onAllFetched?: (data: T[]) => void;
};

export type PagedQueryCacheData<TData = unknown> = {
    data: TData[];
    pending: boolean;
    firstLoad: boolean;
    allFetched: boolean;
    error: boolean;
};

const createInitialData = <T>(): PagedQueryCacheData<T> => ({
    data: [],
    pending: false,
    firstLoad: true,
    allFetched: false,
    error: false
});

const MAX_RETRIES = 3;
const EMPTY_RESULT = [];

// AZ: this is experimental hook to replace useAsyncPagedQuery
// currently it's being used only for useUsersQuery
export const usePagedQuery = <T>({ queryKey, enabled, getNextChunk, onError, onAllFetched }: PagedQueryProps<T>) => {
    const dispatch = useAppDispatch();

    // AZ: serialize queryKey to rely on primitive to run effects only when parts of the key changed
    const serializedKey = useMemo(() => JSON.stringify(queryKey), [queryKey]);

    const queryKeyExist = useAppSelector((state) => getPagedQueryCacheKeyExist(state, serializedKey));
    const queryData = useAppSelector((state) => getPagedQueryCache<T>(state, serializedKey)) ?? createInitialData<T>();

    const queryDataRef = useLatestRef(queryData);
    const onAllFetchedRef = useLatestRef(onAllFetched);
    const onErrorRef = useLatestRef(onError);

    const abortControllerRef = useLatestRef(new AbortController());

    useLayoutEffect(function initQueryRecord() {
        if (!queryKeyExist) {
            dispatch(updatePagedQueryCache({
                queryKey: serializedKey,
                queryCache: createInitialData()
            }));
        }
    }, [dispatch, queryKeyExist, serializedKey]);

    useEffect(() => () => abortControllerRef.current.abort(), [abortControllerRef]);

    const getNextChunkRef = useLatestRef(getNextChunk);

    const updateQueryCache = useCallback((queryCache: PagedQueryCacheData<T>) => {
        dispatch(updatePagedQueryCache({
            queryKey: serializedKey,
            queryCache
        }));
    }, [dispatch, serializedKey]);

    const setQueryCache = useCallback((cacheDataResolver: T[] | ((curData: T[]) => T[])) => {
        updateQueryCache({
            ...queryDataRef.current,
            data: typeof cacheDataResolver === "function" ? cacheDataResolver(queryDataRef.current.data) : cacheDataResolver
        });
    }, [queryDataRef, updateQueryCache]);

    const refetchCache = useCallback(() => {
        if (queryDataRef.current.firstLoad) {
            return;
        }

        if (queryDataRef.current.pending) {
            abortControllerRef?.current?.abort();
        }

        updateQueryCache({
            data: queryDataRef.current?.data,
            firstLoad: false,
            pending: false,
            allFetched: false,
            error: false
        });
    }, [queryDataRef, updateQueryCache, abortControllerRef]);

    const performChunksFetching = useCallback(async () => {
        let shadowData: T[] = [];
        let nextMeta: unknown = null;
        let allFetched = false;
        let queryTry = 0;

        while (!allFetched) {
            try {
                if (queryTry > 0) {
                    await sleep(getProgressiveDelayValue(queryTry));
                }

                const chunk = await getNextChunkRef.current(nextMeta);
                nextMeta = chunk.meta;
                allFetched = chunk.allFetched;
                shadowData = [...shadowData, ...chunk.data];

                // AZ: On first load apply cache gradually
                if (queryData.firstLoad) {
                    updateQueryCache({
                        data: shadowData,
                        firstLoad: true,
                        pending: true,
                        allFetched: false,
                        error: false
                    });
                }

                if (abortControllerRef.current.signal.aborted) {
                    updateQueryCache({
                        data: queryData.firstLoad ? shadowData : queryData.data,
                        firstLoad: queryData.firstLoad,
                        pending: false,
                        allFetched: false,
                        error: false
                    });
                    return;
                }

                queryTry = 0;
            } catch (error) {
                if (queryTry === MAX_RETRIES) {
                    updateQueryCache({
                        data: queryData.firstLoad ? shadowData : queryData.data,
                        firstLoad: false,
                        pending: false,
                        allFetched: true,
                        error: true
                    });
                    onErrorRef.current?.(error);
                    return;
                }

                queryTry++;
            }
        }

        updateQueryCache({
            data: shadowData,
            firstLoad: false,
            pending: false,
            allFetched: true,
            error: false
        });

        onAllFetchedRef?.current?.(shadowData);
    }, [abortControllerRef, getNextChunkRef, onAllFetchedRef, onErrorRef, queryData.data, queryData.firstLoad, updateQueryCache]);

    useLayoutEffect(() => {
        if (!serializedKey || !enabled || queryData.pending || queryData.allFetched) {
            return;
        }

        updateQueryCache({
            data: queryData.data,
            firstLoad: queryData.firstLoad,
            pending: true,
            allFetched: false,
            error: false
        });

        performChunksFetching();
    }, [enabled, performChunksFetching, queryData.allFetched, queryData.data, queryData.firstLoad, queryData.pending, serializedKey, updateQueryCache]);

    return {
        data: queryData.data ?? EMPTY_RESULT,
        error: queryData.error,
        isLoading: enabled && queryData.pending,
        refetch: refetchCache,
        setQueryCache: setQueryCache
    };
};
