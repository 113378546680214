import { SlotSize } from "@7pace/design";
import { TextField } from "monday-ui-react-core";


export const mapTextInputSlotSize = (size: SlotSize) => {
    if (size == null) {
        return TextField.sizes.SMALL;
    }

    switch (size) {
        case SlotSize.Small:
        case SlotSize.Medium: return TextField.sizes.SMALL;
        case SlotSize.Large: return TextField.sizes.MEDIUM;
    }
};
