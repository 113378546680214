import { createContext } from "react";
import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./app/appReducer";
import keyPropsReducer from "./keyProps/keyPropsReducer";
import addTimeReducer from "../common/components/AddTimeComponent/state/addTimeReducer";
import errorOverlayReducer from "../common/components/ErrorOverlay/state/errorOverlayReducer";
import mondaySearchReducer from "../common/components/MondaySearch/state/mondaySearchReducer";
import deleteWorkLogsReducer from "../common/components/WorkLogDeleteConfirmation/state/deleteWorkLogsReducer";
import contextReducer from "../common/MondayContext/state/contextReducer";
import columnConnectorReducer from "../features/Settings/ColumnsConnectorSettings/state/columnConnectorReducer";
import timesExplorerLayoutsReducer from "../features/TimesExplorer/state/timesExplorerLayoutsReducer";


const reducer = {
    app: appReducer,
    context: contextReducer,
    deleteWorklogs: deleteWorkLogsReducer,
    addTime: addTimeReducer,
    errorOverlay: errorOverlayReducer,
    mondaySearch: mondaySearchReducer,
    keyProps: keyPropsReducer,
    columnConnector: columnConnectorReducer,
    timesExplorerLayouts: timesExplorerLayoutsReducer
};

const middleware = (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
    immutableCheck: true,
    serializableCheck: false
});

const store = configureStore({ reducer, devTools: false, middleware });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const storeContext = createContext(null);

export default store;
