import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../../store/store";


type ErrorOverlayState = {
    error: { message: string; };
};

const initialState = {
    error: null
} as ErrorOverlayState;

const errorOverlayReducer = createSlice({
    name: "errorOverlay",
    initialState: initialState,
    reducers: {
        setOverlayError: (state, action: PayloadAction<{ message: string; }>) => { state.error = action.payload; },
    }
});

export const {
    setOverlayError
} = errorOverlayReducer.actions;

//selectors
export const getOverlayError = (store: RootState): { message: string; } => store.errorOverlay.error;

export default errorOverlayReducer.reducer;
