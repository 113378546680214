import styled from "styled-components";


export const AccessDenied = () => {
    return (
        <DeniedContainer>
            <DeniedContent className="access-denied-content">
                <DeniedImage src="/img/access-denied.svg" />
                <DeniedTitle>Access denied</DeniedTitle>
                <DeniedText>Oops, your role is not sufficient to access 7pace Timetracker settings.<br /> Please contact your administrator.</DeniedText>
            </DeniedContent>
        </DeniedContainer>
    );
};

const DeniedImage = styled.img`
    height: 146px;
`;

const DeniedContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${p => p.theme.spacing.xxl} ${p => p.theme.spacing.l};
    gap: ${p => p.theme.spacing.l};
    text-align: center;

    @media (max-width: 511px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding: ${p => p.theme.spacing.s} ${p => p.theme.spacing.m};

        .access-denied-content {
            max-width: 300px;
        }
    }
`;

const DeniedContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const DeniedTitle = styled.div`
    font: ${p => p.theme.typography.title.l};
    color: ${p => p.theme.color.foreground.text.primary};
`;

const DeniedText = styled.div`
    font: ${p => p.theme.typography.body.regular.m};
    color: ${p => p.theme.color.foreground.text.primary};
`;
