import { FC } from "react";
import ReactDOM from "react-dom";

import { VirtualizedListLoader } from "../../VirtualizedList/components/VirtualizedListLoader";


type SpinnerInputDropdownProps = {
    parent: HTMLDivElement;
};

export const AdvancedDropdownInputLoader: FC<SpinnerInputDropdownProps> = ({ parent }) => {
    return ReactDOM.createPortal(<VirtualizedListLoader />, parent);
};
