import { FC } from "react";
import { DialogSlotProps } from "@7pace/design";
import { Modal, ModalContent } from "monday-ui-react-core";


export const MondayDialogSlot: FC<DialogSlotProps> = ({
    isOpen,
    title,
    width,
    closeOnClickOutside,
    children,
    onClose,
    ...rest
}) => {
    return (
        <Modal
            show={isOpen}
            title={title}
            width={`${width}px`}
            alertDialog={closeOnClickOutside === false}
            onClose={onClose}
            {...rest}
        >
            <ModalContent>
                <>{children}</>
            </ModalContent>
        </Modal>
    );
};
