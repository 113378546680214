import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getUserAvatars, setUserIds } from "../state/contextReducer";


export const useMondayAvatars = () => {
    const dispatch = useAppDispatch();
    const avatars = useAppSelector(getUserAvatars);

    const seed = useCallback((userIds: number[]) => {
        dispatch(setUserIds(userIds));
    }, [dispatch]);

    const get = useCallback((userId: number) => {
        return avatars.find(x => x.id === `${userId}`)?.url;
    }, [avatars]);

    return {
        avatars,
        get,
        seed
    };
};
