import { FC, Fragment, useCallback } from "react";
import { IconSlotType } from "@7pace/design";
import { ApiConfig, WhatsNew as WhatsNewComponent, WhatsNewButtonRenderProps } from "@7pace/whats-new";
import { Button } from "monday-ui-react-core";
import WhatsNewIcon from "monday-ui-react-core/dist/icons/WhatsNew";

import { getUser } from "../../../store/app/appReducer";
import { useAppSelector } from "../../../store/hooks";
import { getEnvValue } from "../../utils/getEnvValue";
import { useAmpliLogRocket } from "../Amplitude/hooks/useAmpliLogRocket";
import { useAmpliPage } from "../Amplitude/hooks/useAmpliPage";


export type WhatsNewProps = Pick<React.ComponentProps<typeof WhatsNewComponent>, "mode">;

const apiConfig: ApiConfig = {
    baseUrl: getEnvValue("WHATS_NEW_API_BASE_URL"),
    productId: getEnvValue("WHATS_NEW_API_PRODUCT_ID")
};

const appVersion = getEnvValue("APP_VERSION");

export const WhatsNew: FC<WhatsNewProps> = ({ mode }) => {
    const user = useAppSelector(getUser);
    const ampliLogRocket = useAmpliLogRocket();
    const page = useAmpliPage();

    const onButtonRender = useCallback((props: WhatsNewButtonRenderProps) => {
        return <Fragment>
            <Button
                leftIcon={WhatsNewIcon}
                size={Button.sizes.XS}
                kind={Button.kinds.SECONDARY}
                color={props.showIndicator ? Button.colors.POSITIVE : Button.colors.PRIMARY}
                onClick={props.onClick}
            >
                What&apos;s new
            </Button>
            {props.showIndicator && props.onIndicatorRender()}
        </Fragment>;
    }, []);

    return Object.values(apiConfig).every(Boolean) && (
        <WhatsNewComponent
            mode={mode}
            compactPopupRightMargin={25}
            apiConfig={apiConfig}
            userId={user.id}
            renderers={{
                onButtonRender: onButtonRender
            }}
            onOpenChange={(isOpen) => {
                if (!isOpen) {
                    return;
                }

                ampliLogRocket("whatsNewClicked", { page });
            }}
            newsPopupFooterLinkProps={[
                {
                    iconType: IconSlotType.School,
                    text: "Knowledge base",
                    description: "See how to use 7pace Timetracker to its full potential",
                    href: "https://7pace.com/redir/mdy/docs"
                },
                {
                    iconType: IconSlotType.Envelope,
                    text: "Contact us",
                    description: "Reach out to us with any questions, suggestions, or issues",
                    href: "https://7pace.com/redir/mdy/support"
                },
                {
                    iconType: IconSlotType.Rocket,
                    text: "Feature portal",
                    description: "See what's next and have your say",
                    href: "https://7pace.com/redir/mdy/feature-portal"
                },
                {
                    iconType: IconSlotType.Lightbulb,
                    text: "All release notes",
                    description: "Browse the full history of release notes",
                    href: "https://7pace.com/redir/mdy/release-notes"
                }
            ]}
            appVersion={appVersion}
        />
    );
};
