import { FC } from "react";

import { pageIsPreparedPageTabWithMenuProps } from "./utils/pages";
import { MenuOption, TabsWithMenuProps } from "../types/menuOption";
import { PageTab, PageTabProps } from "./PageTab";
import PageTabWithMenu, { PageTabWithMenuProps } from "./PageTabWithMenu";


export type CommonPageTabProps = {
    menuOptions: MenuOption[];
    pageTab: PageTabProps | PageTabWithMenuProps;
    showMenuForTabs: boolean;
    tabsWithMenuProps: TabsWithMenuProps;
    isUnsaved: boolean;
};

export const CommonPageTab: FC<CommonPageTabProps> = ({ menuOptions, pageTab, isUnsaved, showMenuForTabs, tabsWithMenuProps }) => {
    if (!showMenuForTabs || !pageIsPreparedPageTabWithMenuProps(pageTab)) {
        return <PageTab key={pageTab.path} {...pageTab} />;
    }

    return <PageTabWithMenu
        id={pageTab.id}
        key={pageTab.path}
        icon={pageTab.icon}
        tabsWithMenuProps={tabsWithMenuProps}
        menuOptions={menuOptions}
        isUnsaved={isUnsaved}
        {...pageTab}
    />;
};
