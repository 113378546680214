import { FC } from "react";
import Connect from "monday-ui-react-core/dist/icons/Connect";
import styled from "styled-components";

import { IconProps } from "./types";


// AZ: viewBox of this icon makes the icon visually bigger than it supposed to be
// no idea if this a bug or not
export const ConnectThemed: FC<IconProps> = (props) => {
    return <StyledConnect {...props} />;
};

const StyledConnect = styled(Connect)`
    transform: scale(0.75);
`;
