import { PageTabProps } from "../PageTab";
import { PreparedPageTabWithMenuProps } from "../PageTabWithMenu";


export const pageIsPreparedPageTabWithMenuProps = (page: PageTabProps | PreparedPageTabWithMenuProps): page is PreparedPageTabWithMenuProps => {
    return (page as PreparedPageTabWithMenuProps).id !== undefined;
};

export const pagesArePreparedPageTabWithMenuProps = (pages: PageTabProps[] | PreparedPageTabWithMenuProps[]): pages is PreparedPageTabWithMenuProps[] => {
    return pages.length > 0 && pageIsPreparedPageTabWithMenuProps(pages[0]);
};
