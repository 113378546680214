import { useCallback, useEffect, useRef } from "react";

import { getIsAmplitudeInited } from "../../../../store/app/appReducer";
import { useAppSelector } from "../../../../store/hooks";
import { EventOptions } from "../ampli-autogen";
import { mondayAmpli } from "../mondayAmpli";
import { useAmpliLogRocket } from "./useAmpliLogRocket";


type ExtractViewEvents<T> = {
    [K in keyof T as K extends `${infer _Start}Viewed` ? K : never]: T[K]
};

type ViewEventsWithArgs = {
    [K in keyof ExtractViewEvents<typeof mondayAmpli>]: typeof mondayAmpli[K] extends (arg: infer A) => unknown ? (A extends EventOptions ? never : A) : never;
};

export const useViewAmplitude = <E extends keyof ViewEventsWithArgs>(
    event: E,
    eventProps?: ViewEventsWithArgs[E],
    trackOnMount = true
) => {
    const isAmplitudeInited = useAppSelector(getIsAmplitudeInited);
    const ampliLogRocket = useAmpliLogRocket();
    const isViewEventOcurred = useRef(false);

    const track = useCallback(async (eventProps?: ViewEventsWithArgs[E]) => {
        if (!isAmplitudeInited || isViewEventOcurred.current) {
            return;
        }

        if (event === "authorizeInDesktopViewed" || event === "authorizeTimetrackerViewed") {
            mondayAmpli[event](eventProps);
        } else {
            ampliLogRocket(event, eventProps);
        }

        isViewEventOcurred.current = true;
    }, [ampliLogRocket, event, isAmplitudeInited]);

    useEffect(() => {
        if (!trackOnMount) {
            return;
        }

        track(eventProps);
    }, [eventProps, track, trackOnMount]);

    return track;
};
