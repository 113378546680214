import { FC, Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useOverflowDetector } from "react-detectable-overflow";
import { useNavigate } from "react-router-dom";
import { Combobox, Dialog, DialogContentContainer, Icon, Tab, Tooltip } from "monday-ui-react-core";
import NavigationChevronDown from "monday-ui-react-core/dist/icons/NavigationChevronDown";
import { IComboboxOption } from "monday-ui-react-core/dist/types/components/Combobox/components/ComboboxConstants";
import { TabProps } from "monday-ui-react-core/dist/types/components/Tabs/Tab/Tab";
import styled, { css } from "styled-components";

import { Z_INDEX_DIALOG_TOOLTIP } from "./utils/constants";
import { MenuOption } from "../types/menuOption";
import { PreparedPageTabWithMenuProps } from "./PageTabWithMenu";
import { StyledTabs } from "./StyledTabs";
import { TabMenuButton } from "./TabMenuButton";


export type MoreTabsDropdownProps = {
    showTabsLine?: boolean;
    overflowingPages: PreparedPageTabWithMenuProps[];
    menuOptions: MenuOption[];
    onTabClick?: (tabId: string) => void;
};

type StyledTabProps = TabProps & { isActive?: boolean; };

type CombinedOption = IComboboxOption & { path: string; };

const DROPDOWN_VERTICAL_OFFSET = 5;

export const MoreTabsDropdown: FC<MoreTabsDropdownProps> = ({
    showTabsLine,
    overflowingPages,
    menuOptions,
    onTabClick
}) => {
    const navigate = useNavigate();

    const dialogRef = useRef<Dialog>(null);

    const { ref, overflow } = useOverflowDetector({});

    const [openMenu, setOpenMenu] = useState<string | null>(null);
    const [isMenuButtonActive, setIsMenuButtonActive] = useState<boolean>(false);
    const [isHoveringOption, setIsHoveringOption] = useState<boolean>(false);

    const closeDialog = useCallback(() => {
        setOpenMenu(null);
        setIsHoveringOption(false);
        dialogRef.current.hideDialog(undefined, undefined);
    }, []);

    const handleOptionClick = useCallback((option: CombinedOption) => {
        navigate(option.path);
        onTabClick?.(option.id);
        closeDialog();
    }, [closeDialog, navigate, onTabClick]);

    const modifiedMenuOptions = useMemo(() => menuOptions.map((option) => ({
        ...option,
        onClick: (tabId: string) => {
            closeDialog();
            option.onClick(tabId);
        }
    })), [closeDialog, menuOptions]);

    const renderOption = (option: CombinedOption) => (
        <Tooltip key={option.id} content={overflow ? option.label : undefined} zIndex={Z_INDEX_DIALOG_TOOLTIP}>
            <HoverableWrapper
                isMenuOpen={openMenu === option.id}
            >
                <RestrictedItemLabel ref={ref}>
                    {option.label}
                </RestrictedItemLabel>

                <HoverableItem
                    onMouseEnter={() => setIsHoveringOption(true)}
                    onMouseLeave={() => setIsHoveringOption(false)}
                >
                    <TabMenuButton
                        tabId={option.id}
                        isMenuOpen={false}
                        onMenuShow={() => {
                            setOpenMenu(option.id);
                        }}
                        onMenuHide={() => {
                            setOpenMenu(openMenu => openMenu === option.id ? null : openMenu);
                        }}
                        menuOptions={modifiedMenuOptions}
                    />
                </HoverableItem>
            </HoverableWrapper></Tooltip>
    );

    const renderDialogContentContainer = () => (
        <DialogContentContainer>
            <StyledCombobox
                searchWrapperClassName="hidden-search"
                optionRenderer={renderOption}
                maxOptionsWithoutScroll={13}
                onClick={(option: CombinedOption) => {
                    if (isHoveringOption) {
                        return;
                    }

                    handleOptionClick(option);
                }}
                options={[
                    ...overflowingPages.map<CombinedOption>((page) => ({
                        id: page.id,
                        label: page.name,
                        path: page.path
                    }))
                ]}
            />
        </DialogContentContainer>
    );

    const onDialogHide = () => {
        setOpenMenu(null);
        setIsMenuButtonActive(false);
    };

    const onDialogShow = () => {
        setIsMenuButtonActive(true);
    };

    return (
        <StyledTabs
            tabType="secondary"
            showTabsLine={showTabsLine}
            size="md"
        >
            {[
                <Fragment key={"more-tabs-dialog"}>
                    <Dialog
                        showTrigger={[Dialog.hideShowTriggers.CLICK]}
                        hideTrigger={[Dialog.hideShowTriggers.CLICK, Dialog.hideShowTriggers.ESCAPE_KEY, Dialog.hideShowTriggers.BLUR, Dialog.hideShowTriggers.CLICK_OUTSIDE]}
                        onDialogDidHide={onDialogHide}
                        onDialogDidShow={onDialogShow}
                        position={Dialog.positions.BOTTOM_START}
                        content={renderDialogContentContainer()}
                        ref={dialogRef}
                        moveBy={{ main: DROPDOWN_VERTICAL_OFFSET }}
                    >
                        <StyledTab
                            isActive={isMenuButtonActive}
                        >
                            <span>More</span>
                            <Icon icon={NavigationChevronDown} iconSize={16} />
                        </StyledTab>
                    </Dialog>
                </Fragment>
            ]}
        </StyledTabs>
    );
};

const StyledCombobox = styled(Combobox)`
    /* matches mondays sizing */
    width: 220px;

    [class^=comboboxOption_] {
        background-color: unset;
    }

    .hidden-search {
        display: none;
    }
`;

const RestrictedItemLabel = styled.span`
    /* max width of 196px - 24px (width of icon) - p.theme.spacing.s (width of gap spacing) */
    max-width: calc(196px - 24px - ${p => p.theme.spacing.s});
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
`;

const HoverableItem = styled.div`
    opacity: 0;
    pointer-events: none;
`;

const hoverableItemShow = css`
    ${HoverableItem} {
        opacity: 1;
        pointer-events: all;
    }
`;

const HoverableWrapper = styled.div<{ isMenuOpen: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${p => p.theme.spacing.s};
    width: 100%;

    &:hover {
        ${hoverableItemShow}
    }

    ${p => p.isMenuOpen && hoverableItemShow}
`;

const StyledTab = styled(Tab) <StyledTabProps>`
    a {
        display: flex;
        gap: ${p => p.theme.spacing.s};
    }

    ${p => p.isActive && css`
        a, a:hover {
            background-color: ${p.theme.color.background.accent.secondary.active} !important;
            border-radius: ${p.theme.radii.s};
        }
    `}
`;
