import { FC } from "react";
import { ToggleButtonSlotProps } from "@7pace/design/dist/slots/toggleButton";

import { MondayButtonSlotBase } from "../MondayButtonSlot/MondayButtonSlotBase";


export const MondayToggleButtonSlot: FC<ToggleButtonSlotProps> = ({ checked, onChange, ...rest }) => {
    const OnClickInternal = () => {
        onChange?.(!checked);
    };

    return <MondayButtonSlotBase {...rest} active={checked} onClick={OnClickInternal} />;
};
