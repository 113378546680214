import { useCallback } from "react";

import useAuthToken from "../../../../common/hooks/useAuthToken";
import { setPromptOAuthReset } from "../../../../store/app/appReducer";
import { useAppDispatch } from "../../../../store/hooks";


export const useResetAuthorization = () => {
    const dispatch = useAppDispatch();
    const { resetToken } = useAuthToken();

    const promptReset = useCallback(async () => {
        await resetToken();
        dispatch(setPromptOAuthReset(true));
    }, [dispatch, resetToken]);

    return { promptReset };
};
