import { FC, useEffect, useState } from "react";
import { SearchSlotProps } from "@7pace/design";
import { useDebounce, useLatestRef } from "@7pace/utilities";
import { Search } from "monday-ui-react-core";

import { mapTextInputSlotSize } from "../MondayTextInputSlot/utils";


const DEFAULT_SEARCH_DEBOUNCE_MS = 100;

export const MondaySearchSlot: FC<SearchSlotProps> = ({
    value,
    errorProps,
    slotRef,
    size,
    debounceRate = DEFAULT_SEARCH_DEBOUNCE_MS,
    onChange,
    ...commonProps
}) => {
    const [internalValue, setInternalValue] = useState(value);
    const debouncedValue = useDebounce(internalValue, debounceRate);

    const onChangeRef = useLatestRef(onChange);
    const valueRef = useLatestRef(value);

    useEffect(() => {
        if (valueRef.current !== debouncedValue) {
            onChangeRef.current?.(debouncedValue);
        }
    }, [debouncedValue, onChangeRef, valueRef]);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    return (
        <Search
            ref={slotRef}
            value={internalValue}
            size={mapTextInputSlotSize(size)}
            onChange={setInternalValue}
            validation={errorProps?.error && {
                status: "error",
                text: errorProps.message
            }}
            {...commonProps}
        />
    );
};
