import { useCallback } from "react";

import { useResetAuthorization } from "../../features/Root/Authorization/hooks/useResetAuthorization";
import { useAppDispatch } from "../../store/hooks";
import { setOverlayError } from "../components/ErrorOverlay/state/errorOverlayReducer";
import useGraphQLClient from "./useGraphQLClient";


export type GraphQLError = {
    response: {
        errors: {
            extensions: {
                message?: string;
                code?: string;
            };
        }[];
    };
};

export const DEFAULT_ERROR = "Unexpected error";

export type GraphQLRequestOptions = {
    suppressErrorToast?: boolean;
    onError?: (e: GraphQLError) => void;
};

const useGraphQLRequest = ({ onError, suppressErrorToast }: GraphQLRequestOptions = {}) => {
    const dispatch = useAppDispatch();
    const graphQLClient = useGraphQLClient();
    const { promptReset } = useResetAuthorization();

    const extractCode = useCallback((error: GraphQLError) => {
        if (!error?.response?.errors?.length) {
            return null;
        }

        return error.response.errors[0]?.extensions?.code ?? null;
    }, []);

    const extractError = useCallback((error: GraphQLError) => {
        if (!error?.response?.errors?.length) {
            return DEFAULT_ERROR;
        }

        if (error.response.errors[0]?.extensions?.message) {
            return error.response.errors[0]?.extensions?.message;
        }

        const code = extractCode(error);
        const codeError = code ? ` (${code})` : "";
        return `${DEFAULT_ERROR}${codeError}`;
    }, [extractCode]);

    const requestAsync = useCallback(async <TResponse, TResult>(schema: string, mapResponse: (response: TResponse) => TResult) => {
        const response = await graphQLClient.request<TResponse>(schema);
        const result = mapResponse(response);
        dispatch(setOverlayError(null));
        return result;
    }, [graphQLClient, dispatch]);

    const defaultOnErrorCallback = useCallback((e: GraphQLError) => {
        console.error(e);
        !suppressErrorToast && dispatch(setOverlayError({ message: extractError(e) }));
    }, [dispatch, extractError, suppressErrorToast]);

    const onErrorCallback = useCallback((e: GraphQLError) => {
        const code = extractCode(e);

        if (code === "MONDAY_ITEM_UNAUTHORIZED") {
            promptReset();
            return;
        }

        onError ? onError(e) : defaultOnErrorCallback(e);
    }, [defaultOnErrorCallback, extractCode, onError, promptReset]);

    return {
        requestAsync,
        onError: onErrorCallback
    };
};

export default useGraphQLRequest;
