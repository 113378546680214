import { FC } from "react";
import { Outlet } from "react-router-dom";
import { IconButton } from "monday-ui-react-core";
import Settings from "monday-ui-react-core/dist/icons/Settings";
import styled, { css } from "styled-components";

import { TabEvents } from "./common/hooks/useCustomTabsManagement";
import { PageTabProps } from "./common/PageTab";
import { PreparedPageTabWithMenuProps } from "./common/PageTabWithMenu";
import { TabsWrapper } from "./common/TabsWrapper";
import { TabsWithMenuProps } from "./types/menuOption";
import { LicenseBannerHolder } from "../../common/components/Licensing/Banners/LicenseBannerHolder";
import { WhatsNew } from "../../common/components/WhatsNew/WhatsNew";
import { useMondayContext } from "../../common/MondayContext";
import { useSettingsLink } from "../TimesExplorer/hooks/useSettingsLink";



type ObjectViewProps = {
    pages: PageTabProps[] | PreparedPageTabWithMenuProps[];
    showWhatsNew?: boolean;
    showTabsLine?: boolean;
    showSettingsButton?: boolean;
    showMenuForTabs?: boolean;
    tabsWithMenuProps?: TabsWithMenuProps;
    tabEvents?: TabEvents;
};

export const ObjectView: FC<ObjectViewProps> =
    ({
        pages,
        showWhatsNew = true,
        showTabsLine = true,
        showSettingsButton = false,
        showMenuForTabs = false,
        tabsWithMenuProps,
        tabEvents = {},
    }) => {
        const { openSettingsInModal } = useSettingsLink();
        const { context } = useMondayContext();
        const shouldShowSettingsButton = showSettingsButton && context.appFeature.type === "AppFeatureWorkspaceView";

        return (
            <StyledObjectView>
                <TabBarContainer>
                    <TabBarWrapper showTabsLine={showTabsLine}>
                        <TabsWrapper
                            pages={pages}
                            showTabsLine={showTabsLine}
                            showMenuForTabs={showMenuForTabs}
                            tabsWithMenuProps={tabsWithMenuProps}
                            tabEvents={tabEvents}
                        />
                        <ActionButtons>
                            {shouldShowSettingsButton &&
                                <StyledIconButton
                                    size={IconButton.sizes.XS}
                                    icon={Settings}
                                    onClick={openSettingsInModal}
                                    data-testid="settings-icon"
                                />
                            }
                            {showWhatsNew &&
                                <WhatsNew mode="full" />
                            }
                        </ActionButtons>
                    </TabBarWrapper>
                </TabBarContainer>
                <LicenseBannerHolder />
                <Outlet />
            </StyledObjectView >
        );
    };

const StyledObjectView = styled.div`
    padding-top: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
`;

const TabBarContainer = styled.div`
    width: 100%;
    padding: 0 30px;
`;

const TabBarWrapper = styled.div<{ showTabsLine: boolean; }>`
    display: flex;  
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ${p => p.showTabsLine && css`
        border-bottom: 1px solid ${p => p.theme.color.border.layout.subtle};
    `}
`;

const ActionButtons = styled.div`
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing.s}
`;

const StyledIconButton = styled(IconButton)`
    margin-bottom: 2px;
`;
