import { createContext, useMemo } from "react";
import { useAccessControl } from "@7pace/access-control-client";
import { PERMISSIONS_HEADER } from "@7pace/permissions";
import { GraphQLClient } from "graphql-request";
import { Response } from "graphql-request/dist/types";

import useAuthToken from "../hooks/useAuthToken";


const API_URL = "/graphql";

export type GraphQLClientContextValue = {
    client: GraphQLClient;
};

export const GraphQLClientContext = createContext<GraphQLClientContextValue>({ client: null });

const GraphQLClientProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { token } = useAuthToken();
    const { readUserPermissions } = useAccessControl();

    const value = useMemo(() => {
        const client = new GraphQLClient(API_URL, {
            responseMiddleware: (response: Response<unknown>) => {
                if (response.errors || !response.headers) {
                    return;
                }

                const permissionsHeaders = response.headers.get(PERMISSIONS_HEADER);
                readUserPermissions(permissionsHeaders);
            }
        });

        client.setHeader("Authorization", token);

        return { client };
    }, [readUserPermissions, token]);

    return <GraphQLClientContext.Provider value={value}>{children}</GraphQLClientContext.Provider>;
};

export default GraphQLClientProvider;
