import { Slots } from "@7pace/design";

import { MondayButtonGroupSlot } from "./MondayButtonGroupSlot/MondayButtonGroupSlot";
import { MondayButtonSlot } from "./MondayButtonSlot/MondayButtonSlot";
import { MondayCheckboxSlot } from "./MondayCheckboxSlot/MondayCheckboxSlot";
import { MondayDatePickerSlot } from "./MondayDatePickerSlot/MondayDatePickerSlot";
import { MondayDialogSlot } from "./MondayDialogSlot/MondayDialogSlot";
import { MondayIconSlot } from "./MondayIconSlot/MondayIconSlot";
import { MondayImageSlot } from "./MondayImageSlot/MondayImageSlot";
import { MondayLoaderSlot } from "./MondayLoaderSlot/MondayLoaderSlot";
import { MondayMenuButtonSlot } from "./MondayMenuButtonSlot/MondayMenuButtonSlot";
import { MondayPopupSlot } from "./MondayPopupSlot/MondayPopupSlot";
import { MondayRadioGroupSlot } from "./MondayRadioGroupSlot/MondayRadioGroupSlot";
import { MondaySearchSlot } from "./MondaySearchSlot/MondaySearchSlot";
import { MondaySelectSlot } from "./MondaySelectSlot/MondaySelectSlot";
import { MondayTextInputSlot } from "./MondayTextInputSlot/MondayTextInputSlot";
import { MondayToggleButtonSlot } from "./MondayToggleButtonSlot/MondayToggleButtonSlot";
import { MondayToggleSlot } from "./MondayToggleSlot/MondayToggleSlot";
import { MondayTooltipSlot } from "./MondayTooltipSlot/MondayTooltipSlot";
import { MondayUserPickerSlot } from "./MondayUserPickerSlot/MondayUserPickerSlot";


export const appSlots: Partial<Slots> = {
    button: MondayButtonSlot,
    menuButton: MondayMenuButtonSlot,
    tooltip: MondayTooltipSlot,
    icon: MondayIconSlot,
    textInput: MondayTextInputSlot,
    datePicker: MondayDatePickerSlot,
    userPicker: MondayUserPickerSlot,
    toggle: MondayToggleSlot,
    toggleButton: MondayToggleButtonSlot,
    buttonGroup: MondayButtonGroupSlot,
    radioGroup: MondayRadioGroupSlot,
    search: MondaySearchSlot,
    select: MondaySelectSlot,
    checkbox: MondayCheckboxSlot,
    popup: MondayPopupSlot,
    loader: MondayLoaderSlot,
    dialog: MondayDialogSlot,
    image: MondayImageSlot,
};
