import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "monday-ui-react-core";
import { SubIcon } from "monday-ui-react-core/dist/types/types";
import styled from "styled-components";

import { BetaTag } from "../../../common/components/BetaTag/BetaTag";


export type PageTabProps = {
    name: string;
    path: string;
    beta?: boolean;
    "data-testid"?: string;
    icon?: SubIcon;
    id?: string;
};

export const PageTab: React.FC<PageTabProps> = ({ name, path, beta, ...rest }) => {
    const [active, setActive] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setActive(location.pathname.endsWith(path));
    }, [location.pathname, path]);

    return (
        <Tab
            data-tab-path={path}
            data-testid={rest["data-testid"]}
            active={active}
            onClick={() => navigate(path)}
        >
            <TabName>
                {name}
                {beta && <BetaTag />}
            </TabName>
        </Tab>
    );
};


const TabName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${p => p.theme.spacing.xs};
`;
