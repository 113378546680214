import { useCallback } from "react";

import { sdk } from "../../../MondayContext";
import { SubscriptionPurchaseClickedProperties } from "../../Amplitude/ampli-autogen";
import { useAmpliLogRocket } from "../../Amplitude/hooks/useAmpliLogRocket";


export const useLicenseClick = (openPlanSelection: boolean, source: SubscriptionPurchaseClickedProperties["source"]) => {
    const ampliLogRocket = useAmpliLogRocket();

    return useCallback(() => {
        ampliLogRocket("subscriptionPurchaseClicked", { source });
        sdk.execute("openPlanSelection", { isInPlanSelection: openPlanSelection });
    }, [ampliLogRocket, openPlanSelection, source]);
};
