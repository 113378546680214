import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../../store/store";
import { MondayWorkLog } from "../../../workLogs/models/worklogs";


type DeleteWorkLogsState = {
    workLogsToDelete: MondayWorkLog[];
};

const initialState = {
    workLogsToDelete: [] as MondayWorkLog[]
} as DeleteWorkLogsState;

const deleteWorkLogsReducer = createSlice({
    name: "deleteWorkLogs",
    initialState: initialState,
    reducers: {
        setWorkLogsToDelete: (state, action: PayloadAction<MondayWorkLog[]>) => { state.workLogsToDelete = action.payload; },
    }
});

export const {
    setWorkLogsToDelete
} = deleteWorkLogsReducer.actions;

//selectors
export const getWorkLogsToDelete = (store: RootState): MondayWorkLog[] => store.deleteWorklogs.workLogsToDelete;

export default deleteWorkLogsReducer.reducer;
