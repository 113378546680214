import { FC } from "react";
import { LoaderSlotProps } from "@7pace/design";
import { Loader } from "monday-ui-react-core";

import { mapLoaderSlotSize } from "./utils";


export const MondayLoaderSlot: FC<LoaderSlotProps> = ({
    size,
    ...commonProps
}) => {
    return (
        <Loader
            size={mapLoaderSlotSize(size)}
            {...commonProps}
        />
    );
};
