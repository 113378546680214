import { FC, useMemo } from "react";
import { ButtonKind, ButtonSlot, IconSlotType, SlotSize } from "@7pace/design";
import { Dialog, Tooltip } from "monday-ui-react-core";
import styled from "styled-components";

import { TabEvents, useCustomTabsManagement } from "./hooks/useCustomTabsManagement";
import { useCustomTabsOverflowManagement } from "./hooks/useCustomTabsOverflowManagement";
import { useCustomTabsWidthCalculations } from "./hooks/useCustomTabsWidthCalculations";
import { Z_INDEX_DIALOG_TOOLTIP } from "./utils/constants";
import { FALLBACK_TAB_NAME } from "../hooks/useCustomTabHandling";
import { TabsWithMenuProps } from "../types/menuOption";
import { CommonPageTab } from "./CommonPageTab";
import { MoreTabsDropdown } from "./MoreTabsDropdown";
import { PageTabProps } from "./PageTab";
import { PreparedPageTabWithMenuProps } from "./PageTabWithMenu";
import { StyledTabs } from "./StyledTabs";
import { TabsInput } from "./TabsInput";


const CREATE_NEW_TAB_ID = "create-new-tab";
const MAX_TAB_LIMIT = 99;

export type TabsWrapperProps = {
    pages: PageTabProps[] | PreparedPageTabWithMenuProps[];
    showTabsLine?: boolean;
    showMenuForTabs?: boolean;
    tabsWithMenuProps?: TabsWithMenuProps;
    tabEvents?: TabEvents;
};

export const TabsWrapper: FC<TabsWrapperProps> = ({
    pages,
    showTabsLine,
    showMenuForTabs,
    tabsWithMenuProps,
    tabEvents,
}) => {
    const {
        incrementHiddenTabsRenderCount,
        tabsWrapperRef,
        hiddenDivRef,
        maxAllowedWidth,
        widthCalculations
    } = useCustomTabsWidthCalculations();
    const {
        status,
        newTabName,
        handleTabSave,
        setNewTabName,
        handleStartNewTabCreation,
        menuOptions,
        isUnsaved,
        activeRenameTab,
        tabsInputRef,
        isActiveTabBeingRenamed
    } = useCustomTabsManagement(
        pages,
        tabEvents,
        tabsWithMenuProps
    );
    const {
        leftPages,
        rightPages,
        overflowingPages
    } = useCustomTabsOverflowManagement(
        pages,
        activeRenameTab,
        widthCalculations,
        maxAllowedWidth,
        status,
        tabsWithMenuProps?.activeTab
    );
    // render tabs in a visibility hidden div to calculate their width
    const hiddenTabs = useMemo(() => {
        incrementHiddenTabsRenderCount();

        return (
            <HiddenDiv ref={hiddenDivRef}>
                <StyledTabs
                    showTabsLine={showTabsLine}
                    size="md"
                >
                    {pages.map((pageTab: PageTabProps | PreparedPageTabWithMenuProps) => (
                        <CommonPageTab
                            key={pageTab.path}
                            isUnsaved={false}
                            showMenuForTabs={showMenuForTabs}
                            tabsWithMenuProps={tabsWithMenuProps}
                            menuOptions={[]}
                            pageTab={pageTab}
                        />
                    ))}
                </StyledTabs>

                <TabInputWrapper>
                    <TabsInput
                        handleTabSave={() => { }}
                        newTabName={""}
                        setNewTabName={() => { }}
                        createNewTabId={CREATE_NEW_TAB_ID}
                        status={null}
                        isActiveTabBeingRenamed={false}
                    />
                </TabInputWrapper>

                <MoreTabsDropdown
                    showTabsLine={showTabsLine}
                    overflowingPages={[]}
                    menuOptions={[]}
                    onTabClick={() => { }}
                />

                <TabCreateWrapper>
                    <ButtonSlot
                        kind={ButtonKind.Secondary}
                        size={SlotSize.Small}
                        icon={IconSlotType.Plus}
                        onClick={() => { }}
                    />
                </TabCreateWrapper>

                <CommonPageTab
                    isUnsaved={false}
                    showMenuForTabs={showMenuForTabs}
                    tabsWithMenuProps={tabsWithMenuProps}
                    menuOptions={[]}
                    pageTab={{
                        ...pages[0],
                        name: FALLBACK_TAB_NAME,
                        id: "__fallback-tab__",
                    }}
                />
            </HiddenDiv>
        );
    }, [hiddenDivRef, incrementHiddenTabsRenderCount, pages, showMenuForTabs, showTabsLine, tabsWithMenuProps]);

    const renderCreateWrapper = () => {
        const canCreateFirstTab = pages.length === 1 && isUnsaved;
        const tooltipContent = canCreateFirstTab ? "Save as new view" : "Add new view";

        return (
            <Tooltip
                content={tooltipContent}
                open={canCreateFirstTab}
                position={Dialog.positions.RIGHT}
                zIndex={Z_INDEX_DIALOG_TOOLTIP}
            >
                <TabCreateWrapper>
                    <ButtonSlot
                        kind={ButtonKind.Secondary}
                        size={SlotSize.Small}
                        icon={IconSlotType.Plus}
                        onClick={() => {
                            if (status === "renaming") {
                                handleTabSave();

                                requestAnimationFrame(() => handleStartNewTabCreation());
                            } else {
                                handleStartNewTabCreation();
                            }
                        }}
                        active={isUnsaved}
                        id={CREATE_NEW_TAB_ID}
                        disabled={pages.length >= MAX_TAB_LIMIT}
                    />
                </TabCreateWrapper>
            </Tooltip>
        );
    };

    return (
        <TabsWrapperStyle ref={tabsWrapperRef}>
            {showMenuForTabs && hiddenTabs}

            <StyledTabs showTabsLine={showTabsLine} size="md">
                {leftPages.map((pageTabProps: PageTabProps | PreparedPageTabWithMenuProps) => (
                    <CommonPageTab
                        key={pageTabProps.path}
                        isUnsaved={isUnsaved}
                        showMenuForTabs={showMenuForTabs}
                        tabsWithMenuProps={tabsWithMenuProps}
                        menuOptions={menuOptions}
                        pageTab={pageTabProps}
                    />
                ))}
            </StyledTabs>

            {status !== null && showMenuForTabs && <TabInputWrapper>
                <TabsInput
                    ref={tabsInputRef}
                    handleTabSave={handleTabSave}
                    newTabName={newTabName}
                    setNewTabName={setNewTabName}
                    createNewTabId={CREATE_NEW_TAB_ID}
                    status={status}
                    isActiveTabBeingRenamed={isActiveTabBeingRenamed}
                />
            </TabInputWrapper>}

            <StyledTabs showTabsLine={showTabsLine} size="md">
                {rightPages.map((pageTabProps: PageTabProps | PreparedPageTabWithMenuProps) => (
                    <CommonPageTab
                        key={pageTabProps.path}
                        isUnsaved={isUnsaved}
                        showMenuForTabs={showMenuForTabs}
                        tabsWithMenuProps={tabsWithMenuProps}
                        menuOptions={menuOptions}
                        pageTab={pageTabProps}
                    />
                ))}
            </StyledTabs>

            {showMenuForTabs && !!overflowingPages.length && <MoreTabsDropdown
                showTabsLine={showTabsLine}
                overflowingPages={overflowingPages}
                menuOptions={menuOptions}
                onTabClick={tabsWithMenuProps?.onTabClick}
            />}

            {showMenuForTabs && renderCreateWrapper()}
        </TabsWrapperStyle>
    );
};

const TabsWrapperStyle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    overflow: hidden;
`;

const TabInputWrapper = styled.div`
    display: flex;
    align-items: center;

    margin: 0 ${p => p.theme.spacing.xs};

    > div {
        width: max-content;
    }
`;

const TabCreateWrapper = styled.div`
    display: flex;
    align-items: center;
    
    margin-left: ${p => p.theme.spacing.xs};
    margin-right: ${p => p.theme.spacing.xs};
`;

const HiddenDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    position: absolute;
    visibility: hidden;
    top: -9999px;

    pointer-events: none;
`;
