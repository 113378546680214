import { useCallback, useMemo } from "react";

import { sdk, useMondayContext } from "../../../common/MondayContext";
import RoutePath from "../../../routing/RoutePath";


export const useSettingsLink = () => {
    const { sessionData } = useMondayContext();
    const { app_id, slug } = sessionData;

    const navigateToSettings = useCallback(() => {
        const url = `https://${slug}.monday.com/apps/installed_apps/${app_id}/settings`;

        sdk.execute("openLinkInTab", {
            url
        });
    }, [app_id, slug]);

    const openSettingsInModal = useCallback(() => {
        sdk.execute("openAppFeatureModal", { urlPath: RoutePath.Settings, width: "95vw", height: "95vh" });
    }, []);


    return useMemo(() => ({
        navigateToSettings,
        openSettingsInModal
    }), [navigateToSettings, openSettingsInModal]);

};
